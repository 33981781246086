
require('./files/libs/hinclude.js');
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

require('jquery-migrate');

require('jquery.nicescroll');
require('./files/libs/jquery.touchSwipe.min.js');
require('./files/libs/jquery-ui-1.10.2.custom.js');
require('imports-loader?this=>window!./files/libs/modernizr-2.7.1.js');
require('./files/libs/bootstrap/bootstrap-transition.js');
require('./files/libs/bootstrap/bootstrap-alert.js');
require('./files/libs/bootstrap/bootstrap-modal.js');
require('./files/libs/bootstrap/bootstrap-dropdown.js');
require('./files/libs/bootstrap/bootstrap-scrollspy.js');
require('./files/libs/bootstrap/bootstrap-tab.js');
require('./files/libs/bootstrap/bootstrap-tooltip.js');
require('./files/libs/bootstrap/bootstrap-popover.js');
require('./files/libs/bootstrap/bootstrap-button.js');
require('./files/libs/bootstrap/bootstrap-collapse.js');
require('./files/libs/bootstrap/bootstrap-carousel.js');
require('./files/libs/bootstrap/bootstrap-typeahead.js');
require('./files/libs/verimail.jquery.min.js');
require('./files/libs/verimail.min.js');
require('./files/libs/jquery.actual.min.js');
require('./files/libs/jquery.block.js');
require('./files/libs/jquery.popupWindow.js');
require('./files/libs/notify.js');
require('./files/libs/jquery_ui_touch_punch.js');

const Translator = require('./files/bazingajstranslation/translator.min');
global.Translator = Translator;
require('../../web/js/translations/config');
require('../../web/js/translations/messages/de');
require('../../web/js/translations/messages/en');
