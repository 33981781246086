(function (t) {
// de
t.add("l.layoutExampleHeadline", "Einfaches Beispiel f\u00fcr ein Kunden-spezifisches Layout", "messages", "de");
t.add("l.betaNote", ".#BETA", "messages", "de");
t.add("title", "ARGUS Portal", "messages", "de");
t.add("footerTitle", "ARGUS DATA INSIGHTS", "messages", "de");
t.add("footerImpressum", "Impressum", "messages", "de");
t.add("footerChangeDisplayMode", "Wechsel zum Desktopmodus", "messages", "de");
t.add("or", "oder", "messages", "de");
t.add("d.dateformat", "d.m.Y", "messages", "de");
t.add("d.timeformat", "H:i", "messages", "de");
t.add("d.timeformatLong", "H:i:s", "messages", "de");
t.add("d.dateIntervalFormat", "%D:%M:%Y", "messages", "de");
t.add("d.timeIntervalFormat", "%H:%I:%S", "messages", "de");
t.add("f.dateformat", "dd.mm.yyyy", "messages", "de");
t.add("ntf.dateformat", "dd.MM.yyyy", "messages", "de");
t.add("ntf.dateformat_js", "dd.mm.yyyy", "messages", "de");
t.add("d.dateformat_excel", "dd.mm.yyyy", "messages", "de");
t.add("d.datetimeformat_excel", "dd.mm.yyyy hh:mm", "messages", "de");
t.add("d.datetimeformat_weekday_locale", "l, d.m.Y", "messages", "de");
t.add("d.weekday_1", "Montag", "messages", "de");
t.add("d.weekday_2", "Dienstag", "messages", "de");
t.add("d.weekday_3", "Mittwoch", "messages", "de");
t.add("d.weekday_4", "Donnerstag", "messages", "de");
t.add("d.weekday_5", "Freitag", "messages", "de");
t.add("d.weekday_6", "Samstag", "messages", "de");
t.add("d.weekday_7", "Sonntag", "messages", "de");
t.add("d.month_1", "Januar", "messages", "de");
t.add("d.month_2", "Februar", "messages", "de");
t.add("d.month_3", "M\u00e4rz", "messages", "de");
t.add("d.month_4", "April", "messages", "de");
t.add("d.month_5", "Mai", "messages", "de");
t.add("d.month_6", "Juni", "messages", "de");
t.add("d.month_7", "Juli", "messages", "de");
t.add("d.month_8", "August", "messages", "de");
t.add("d.month_9", "September", "messages", "de");
t.add("d.month_10", "Oktober", "messages", "de");
t.add("d.month_11", "November", "messages", "de");
t.add("d.month_12", "Dezember", "messages", "de");
t.add("d.oclock", "h", "messages", "de");
t.add("ali.dateformat", "dd.MM.yyyy", "messages", "de");
t.add("n.datetime", "d.m.Y H:i", "messages", "de");
t.add("n.datetimeHeader", "l, j. F Y H:i", "messages", "de");
t.add("n.dateArticle", "d.m.Y", "messages", "de");
t.add("n.decimalpoint", ",", "messages", "de");
t.add("n.thousandsseperator", ".", "messages", "de");
t.add("n.EURO", "\u20ac", "messages", "de");
t.add("n.million", "Mio", "messages", "de");
t.add("btn.cancel", "Abbrechen", "messages", "de");
t.add("btn.transfer", "\u00dcbernehmen", "messages", "de");
t.add("btn.save", "Speichern", "messages", "de");
t.add("btn.save_email_group", "E-Mail Gruppe speichern", "messages", "de");
t.add("btn.add_email_group", "E-Mail Gruppe hinzuf\u00fcgen", "messages", "de");
t.add("btn.add_email", "E-Mail hinzuf\u00fcgen", "messages", "de");
t.add("btn.exportEmails", "E-Mails exportieren", "messages", "de");
t.add("btn.importEmails", "E-Mails importieren", "messages", "de");
t.add("btn.send_immediately", "Sofort versenden", "messages", "de");
t.add("btn.send_immediately_warn", "Newsletter wird nach Best\u00e4tigung ausgel\u00f6st!", "messages", "de");
t.add("btn.choose", "Ausw\u00e4hlen", "messages", "de");
t.add("btn.apply", "Anwenden", "messages", "de");
t.add("btn.reset", "Zur\u00fccksetzen", "messages", "de");
t.add("btn.delete", "L\u00f6schen", "messages", "de");
t.add("btn.remove", "Entfernen", "messages", "de");
t.add("btn.upload", "Hochladen", "messages", "de");
t.add("btn.keep", "Behalten", "messages", "de");
t.add("btn.bulkEditMra", "Analysedaten bearbeiten", "messages", "de");
t.add("btn.edit", "Bearbeiten", "messages", "de");
t.add("btn.ok", "OK", "messages", "de");
t.add("btn.close", "Schlie\u00dfen", "messages", "de");
t.add("btn.print", "Drucken", "messages", "de");
t.add("btn.send", "Senden", "messages", "de");
t.add("btn.yes", "Ja", "messages", "de");
t.add("btn.no", "Nein", "messages", "de");
t.add("btn.goOn", "Weiter", "messages", "de");
t.add("btn.back", "Zur\u00fcck", "messages", "de");
t.add("val.saved", "Gespeichert", "messages", "de");
t.add("val.error", "Es ist ein Fehler aufgetreten", "messages", "de");
t.add("val.invalidEmailRemovedHint", "Ung\u00fcltige E-Mail-Adresse wurde entfernt", "messages", "de");
t.add("val.invalidEmailsRemovedHint", "Ung\u00fcltige E-Mail-Adressen wurden entfernt", "messages", "de");
t.add("error.sqlstate", "Es ist ein Systemfehler aufgetreten. Bitte versuchen Sie es sp\u00e4ter erneut.", "messages", "de");
t.add("Tag", "Tag", "messages", "de");
t.add("Bewertung", "Bewertung", "messages", "de");
t.add("Header", "Header", "messages", "de");
t.add("Medium", "Medium", "messages", "de");
t.add("Datum", "Datum", "messages", "de");
t.add("logo", "Logo", "messages", "de");
t.add("psptitle", "Medienspiegel Titel", "messages", "de");
t.add("freetext", "Freitext", "messages", "de");
t.add("date", "Datum", "messages", "de");
t.add("pageNumber", "Seitennummer", "messages", "de");
t.add("sortSysEdatum", "Erstellungsdatum", "messages", "de");
t.add("sortSysMdatum", "Bearbeitungsdatum", "messages", "de");
t.add("sortPspName", "Titel", "messages", "de");
t.add("sortSysEuser", "Ersteller", "messages", "de");
t.add("deletedButNotGone", "Papierkorb", "messages", "de");
t.add("and", "und", "messages", "de");
t.add("everything", "Alles", "messages", "de");
t.add("pmgNote", "Die von uns an Sie \u00fcbermittelten Newsletter und Clippings sind f\u00fcr die Medienbeobachtung und Weitergabe an Sie ordnungsgem\u00e4\u00df lizenziert.<br\/> Bitte beachten Sie, dass Sie bei einer Weiterleitung von Clippings und\/oder Links selbst \u00fcberpr\u00fcfen m\u00fcssen, ob diese Weitergabe nicht gegen Ihnen obliegende vertragliche Pflichten oder gegen urheberrechtliche Vorschriften verst\u00f6\u00dft.\n", "messages", "de");
t.add("archivistOnlyNote", "Dieser Pressespiegel wird auf Basis des erweiterten Archivrechts erstellt und richtet sich nach dessen Vorgaben.", "messages", "de");
t.add("l.orders", "Auftr\u00e4ge", "messages", "de");
t.add("l.clippings", "Clippings", "messages", "de");
t.add("l.dashboard", "Dashboard", "messages", "de");
t.add("l.work", "Work", "messages", "de");
t.add("l.customlink1", "Benutzerlink", "messages", "de");
t.add("l.profile", "profil", "messages", "de");
t.add("l.help", "hilfe", "messages", "de");
t.add("l.options", "einstellungen", "messages", "de");
t.add("l.feedback", "feedback", "messages", "de");
t.add("l.logout", "logout", "messages", "de");
t.add("l.useradmin", "benutzerverwaltung", "messages", "de");
t.add("l.tagadmin", "Tags", "messages", "de");
t.add("l.folderadmin", "Ordner", "messages", "de");
t.add("l.newsletteradmin", "Newsletter", "messages", "de");
t.add("l.aliadmin", "Echtzeit-Monitoring", "messages", "de");
t.add("l.pspadmin", "Medienspiegel", "messages", "de");
t.add("l.sessionExpired", "Ihre Sitzung ist abgelaufen.", "messages", "de");
t.add("l.companyName", "ARGUS DATA INSIGHTS", "messages", "de");
t.add("l.portalManual", "Handbuch", "messages", "de");
t.add("l.portalManualFile", "201705_Portalhandbuch.pdf", "messages", "de");
t.add("l.videoTut", "Tutorials", "messages", "de");
t.add("l.videoTut1", "Tutorial 1 - Clippings bearbeiten", "messages", "de");
t.add("l.videoTut1File", "AUSSCHNITT_Portal_Tutorial_1_Clippings_bearbeiten.mp4", "messages", "de");
t.add("l.videoTut2", "Tutorial 2 - Suchen & Filtern", "messages", "de");
t.add("l.videoTut2File", "AUSSCHNITT_Portal_Tutorial_2_Suchen_Filtern.mp4", "messages", "de");
t.add("l.videoTut3", "Tutorial 3 - Strukturieren & Exportieren", "messages", "de");
t.add("l.videoTut3File", "AUSSCHNITT_Portal_Tutorial_3_Strukturiere_Exportieren.mp4", "messages", "de");
t.add("l.videoTut4", "Tutorial 4 - Newsletter einrichten", "messages", "de");
t.add("l.videoTut4File", "AUSSCHNITT_Portal_Tutorial_4_Newsletter_einrichten.mp4", "messages", "de");
t.add("l.videoTut5", "Tutorial 5 - Inhalte hochladen", "messages", "de");
t.add("l.videoTut5File", "AUSSCHNITT_Portal_Tutorial_5_Inhalte_hochladen.mp4", "messages", "de");
t.add("l.videoTut6", "Tutorial 6 - Medienspiegel erstellen", "messages", "de");
t.add("l.videoTut6File", "AUSSCHNITT_Portal_Tutorial_6_Pressespiegel_erstellen.mp4", "messages", "de");
t.add("l.videoTut7", "Tutorial 7 - Daten visuell analysieren", "messages", "de");
t.add("l.videoTut7File", "AUSSCHNITT_Portal_Tutorial_7_Daten_visuell_analysieren.mp4", "messages", "de");
t.add("l.videoTut8", "Tutorial 8 - Benutzer verwalten", "messages", "de");
t.add("l.videoTut8File", "AUSSCHNITT_Portal_Tutorial_8_Benutzer_verwalten.mp4", "messages", "de");
t.add("l.switchToMobileView", "Mobile Ansicht", "messages", "de");
t.add("l.switchToClassicView", "Klassische Ansicht", "messages", "de");
t.add("l.viewMode", "Ansicht-Modus", "messages", "de");
t.add("l.defaultViewMode", "Standard-Ansicht", "messages", "de");
t.add("l.displayAuto", "Automatisch", "messages", "de");
t.add("l.displayClassic", "Desktop-Modus", "messages", "de");
t.add("l.displayMobile", "Mobil-Modus", "messages", "de");
t.add("l.classic", "Desktop", "messages", "de");
t.add("l.mobile", "Mobil", "messages", "de");
t.add("l.lang", "De", "messages", "de");
t.add("l.menuEntry", "Men\u00fcpunkt", "messages", "de");
t.add("l.showMenuEntry", "Men\u00fcpunkt anzeigen", "messages", "de");
t.add("lp.pleaseNotice", "Bitte beachten Sie:", "messages", "de");
t.add("lp.ie", "Mit der veralteten Version Ihres Browsers", "messages", "de");
t.add("lp.ie8", "stehen nur stark eingeschr\u00e4nkte Funktionen zur Verf\u00fcgung.", "messages", "de");
t.add("lp.ie9", "steht Ihnen nur eine eingeschr\u00e4nkte Performance zur Verf\u00fcgung.", "messages", "de");
t.add("lp.noCookies", "Sie haben in Ihrem Browser Cookies deaktiviert und k\u00f6nnen diesen Service nicht nutzen.", "messages", "de");
t.add("lp.noJs", "Sie haben in Ihrem Browser JavaScript deaktiviert und k\u00f6nnen diesen Service nicht nutzen.", "messages", "de");
t.add("l.authenticationCode", "Authentifizierungscode", "messages", "de");
t.add("l.enterAuthenticationCode", "Bitte geben Sie den Authentifizierungscode ein, der an Ihre E-Mail gesendet wurde", "messages", "de");
t.add("l.authenticationCodeEmailText", "Ihr Authentifizierungscode lautet:", "messages", "de");
t.add("l.defaultlist", "Langansicht", "messages", "de");
t.add("l.shortlist", "Kurzansicht", "messages", "de");
t.add("l.selectAll", "Alle ausw\u00e4hlen", "messages", "de");
t.add("l.deselectAll", "Auswahl aufheben", "messages", "de");
t.add("l.selection", "Auswahl", "messages", "de");
t.add("l.sort", "Sortieren", "messages", "de");
t.add("l.of", "von", "messages", "de");
t.add("l.sortByDatePublication", "Publikationsdatum", "messages", "de");
t.add("l.sortByProdDate", "Erfassungsdatum", "messages", "de");
t.add("l.sortByDateProduced", "Produktionsdatum", "messages", "de");
t.add("l.sortByCreatedAt", "Erfassungsdatum", "messages", "de");
t.add("l.sortByDateUploaded", "Upload-Datum", "messages", "de");
t.add("l.sortByTitle", "Headline", "messages", "de");
t.add("l.sortByMdTitle", "Medientitel", "messages", "de");
t.add("l.sortByMdMart", "Medienart", "messages", "de");
t.add("l.sortByTonality", "Bewertung", "messages", "de");
t.add("l.sortByRelevance", "Relevanz", "messages", "de");
t.add("l.sortByRange", "Reichweite", "messages", "de");
t.add("l.clWOSubedition", "ohne Unterausgaben", "messages", "de");
t.add("l.checkAllUa", "Alle Unterausgaben ausw\u00e4hlen", "messages", "de");
t.add("l.selectArticleFromCurrentPagecheckAllUa", "Aktuelle Seite ausw\u00e4hlen", "messages", "de");
t.add("l.autoreload", "Automatisch aktualisieren", "messages", "de");
t.add("l.includeFilter", "Filter in den Export einbeziehen", "messages", "de");
t.add("l.useActiveFilter", "Aktiven Filter verwenden", "messages", "de");
t.add("l.reload", "Jetzt neu laden", "messages", "de");
t.add("l.upload", "Upload", "messages", "de");
t.add("l.relevanz", "Relevanz", "messages", "de");
t.add("l.shoppingCartIcon", "Zum Warenkorb hinzuf\u00fcgen", "messages", "de");
t.add("l.show", "Anzeigen", "messages", "de");
t.add("l.rename", "Umbenennenn", "messages", "de");
t.add("l.previous", "zur\u00fcck", "messages", "de");
t.add("l.next", "vor", "messages", "de");
t.add("l.shareFacebook", "\u00dcber Facebook teilen", "messages", "de");
t.add("l.shareTwitter", "\u00dcber X teilen", "messages", "de");
t.add("l.shareLinkedin", "\u00dcber Linkedin teilen", "messages", "de");
t.add("l.shareTeams", "\u00dcber Teams teilen", "messages", "de");
t.add("l.artikel", "Artikel", "messages", "de");
t.add("l.analyse", "Analyse", "messages", "de");
t.add("l.medientitel", "Medientitel", "messages", "de");
t.add("l.headline", "Headline", "messages", "de");
t.add("I.link", "Link", "messages", "de");
t.add("l.untertitel", "Untertitel", "messages", "de");
t.add("l.addData", "Zus\u00e4tzliche Daten", "messages", "de");
t.add("l.date", "Datum", "messages", "de");
t.add("l.addOnlineIcons", "Link teilen", "messages", "de");
t.add("l.tvthumbnail", "Vorschaubild", "messages", "de");
t.add("l.tvCutmarks", "Schnittmarken", "messages", "de");
t.add("l.tvCutmarksStart", "Start", "messages", "de");
t.add("l.tvCutmarksEnd", "Ende", "messages", "de");
t.add("l.tvCutmarksLength", "L\u00e4nge", "messages", "de");
t.add("l.suchbegriffe", "Suchbegriffe", "messages", "de");
t.add("l.aliSuchprofil", "Suchagent", "messages", "de");
t.add("l.suchwortumgebung", "Suchwortumgebung", "messages", "de");
t.add("l.teaser", "Teaser", "messages", "de");
t.add("l.presssummary", "Press Summary", "messages", "de");
t.add("l.rubrik", "Rubrik", "messages", "de");
t.add("l.kundenrubrik", "Kundenrubrik", "messages", "de");
t.add("l.seite", "Seite", "messages", "de");
t.add("l.erscheinungsdatum", "Erscheinungsdatum", "messages", "de");
t.add("l.produktionsdatum", "Produktionsdatum", "messages", "de");
t.add("l.tags", "Tags", "messages", "de");
t.add("l.ordner", "Ordner", "messages", "de");
t.add("l.verfuegbaretags", "verf\u00fcgbare Tags", "messages", "de");
t.add("l.kommentare", "Kommentare", "messages", "de");
t.add("l.auftrag", "Auftrag", "messages", "de");
t.add("l.abo", "Abo", "messages", "de");
t.add("l.abo_s", "Abo(s)", "messages", "de");
t.add("l.abo_converted", "Konvertiert in Abo(s)", "messages", "de");
t.add("l.converted_into", "Konvertiert in", "messages", "de");
t.add("l.treffernummer", "Treffernummer", "messages", "de");
t.add("l.medium", "Medium", "messages", "de");
t.add("l.mediumId", "Medium ID", "messages", "de");
t.add("l.mkz", "Kennzahlen", "messages", "de");
t.add("l.externalLink", "Extern link", "messages", "de");
t.add("l.internalLink", "Intern link", "messages", "de");
t.add("l.ausgabe", "Ausgabe", "messages", "de");
t.add("l.medienart", "Medienart", "messages", "de");
t.add("l.medientyp", "Medientyp", "messages", "de");
t.add("l.medienbranche", "Medienbranche", "messages", "de");
t.add("l.erscheinungsweise", "Erscheinungsweise", "messages", "de");
t.add("l.redaktion", "Redaktion", "messages", "de");
t.add("l.verlag", "Verlag", "messages", "de");
t.add("l.verlagfamilie", "Verlagfamilie", "messages", "de");
t.add("l.sender", "Sender", "messages", "de");
t.add("l.bundesland", "Bundesland", "messages", "de");
t.add("l.land", "Land", "messages", "de");
t.add("l.nielsen", "Nielsen", "messages", "de");
t.add("l.sprache", "Sprache", "messages", "de");
t.add("l.thema", "Thema", "messages", "de");
t.add("l.aaew", "A\u00c4W Gesamt", "messages", "de");
t.add("l.mraAufmerksamkeit", "Aufmerksamkeit", "messages", "de");
t.add("l.mraAufmerksamkeitArt", "Art der Aufmerksamkeit", "messages", "de");
t.add("l.mraDarstellungsform", "Darstellungsform", "messages", "de");
t.add("l.mraDarstellungsformArt", "Art der Darstellungsform", "messages", "de");
t.add("l.mraUrheber", "Urheber", "messages", "de");
t.add("l.mraUrheberKategorie", "Kategorie Urheber", "messages", "de");
t.add("I.mraIssues", "Zitierte Akteure", "messages", "de");
t.add("I.mraThema", "MRA-Thema", "messages", "de");
t.add("I.portalAlertValue", "Wahr", "messages", "de");
t.add("I.summaryValue", "Wahr", "messages", "de");
t.add("I.uploadsValue", "Wahr", "messages", "de");
t.add("I.abstractsValue", "Wahr", "messages", "de");
t.add("I.aaewValue", "Wahr", "messages", "de");
t.add("l.trefferPm", "Pressemitteilung", "messages", "de");
t.add("l.wiedergabeKorrekt", "korrekte Wiedergabe", "messages", "de");
t.add("l.wiedergabeVollstaendig", "vollst\u00e4ndige Wiedergabe", "messages", "de");
t.add("l.mraQuelle", "Quelle", "messages", "de");
t.add("l.mraQuelleKategorie", "Quelle Kategorie", "messages", "de");
t.add("l.bewertung", "Bewertung", "messages", "de");
t.add("l.kommentarAdd", "Kommentar hinzuf\u00fcgen", "messages", "de");
t.add("l.kommentarAddAll", "Sichtbar f\u00fcr alle Benutzer", "messages", "de");
t.add("l.rubrikAdd", "Rubrik hinzuf\u00fcgen", "messages", "de");
t.add("l.rubrikAddOwnImg", "Eigene Bilder", "messages", "de");
t.add("l.rubrikAddNoImg", "Kein Bild", "messages", "de");
t.add("l.rubrikAddRemoveImg", "Bild entfernen", "messages", "de");
t.add("l.tonalitaet", "Gesamtbewertung", "messages", "de");
t.add("l.artikelfreigabe", "Freigabe", "messages", "de");
t.add("l.setzen", "setzen", "messages", "de");
t.add("l.beitragslaenge", "Beitragsl\u00e4nge", "messages", "de");
t.add("l.abstract", "Abstract", "messages", "de");
t.add("l.volltext", "Volltext", "messages", "de");
t.add("l.kennzahlen", "Kennzahlen", "messages", "de");
t.add("l.name", "Name", "messages", "de");
t.add("l.strasse", "Strasse", "messages", "de");
t.add("l.plz", "PLZ Ort", "messages", "de");
t.add("l.ort", "Ort", "messages", "de");
t.add("l.ortzusatz", "Ort Zusatz", "messages", "de");
t.add("l.meldung", "Meldung", "messages", "de");
t.add("l.dokument", "Dokumente", "messages", "de");
t.add("l.openLink", "Beitrag \u00f6ffnen", "messages", "de");
t.add("l.new", "neu", "messages", "de");
t.add("l.summaryRelatedArticles", "Andere ber\u00fccksichtigte Artikel", "messages", "de");
t.add("l.newPressSummary", "Neue Press Summary", "messages", "de");
t.add("l.summaries", "Summaries", "messages", "de");
t.add("l.vorschaubildtv", "Vorschaubild (TV)", "messages", "de");
t.add("l.autor", "Autor", "messages", "de");
t.add("l.mainEdition", "Hauptausgabe", "messages", "de");
t.add("l.socialMedia", "Social Media", "messages", "de");
t.add("l.feedsource", "Feed Quelle", "messages", "de");
t.add("l.inweiterenausgaben", "Artikel in weiteren Ausgaben", "messages", "de");
t.add("l.erstausstrahlung", "Erstausstrahlung", "messages", "de");
t.add("l.wiederholungssendung", "Wiederholungssendung", "messages", "de");
t.add("l.articleHasAnalysesData", "Bitte beachten Sie, dass mindestens einem der gew\u00e4hlten Clippings bereits Analysedaten zugeordnet wurden, die unter Umst\u00e4nden \u00fcberschrieben werden.", "messages", "de");
t.add("l.mraportalfields", "MRA ARGUS Portal-Felder", "messages", "de");
t.add("l.bigLogoTitle", "zur Webseite von ARGUS DATA INSIGHTS", "messages", "de");
t.add("l.bigLogoAlternative", "ARGUS DATA INSIGHTS", "messages", "de");
t.add("l.toWebpage", "Zur Webseite", "messages", "de");
t.add("l.sentTime", "Versandzeit", "messages", "de");
t.add("l.sentBy", "versandt durch", "messages", "de");
t.add("l.additionalInformation", "weitere Informationen", "messages", "de");
t.add("l.recipients", "]0,Inf] Empf\u00e4nger", "messages", "de");
t.add("l.emailDistributor", "]0,Inf] Verteiler", "messages", "de");
t.add("l.recipientsNotVisible", "Empf\u00e4nger nicht sichtbar", "messages", "de");
t.add("l.includedInThisMediaReviews", "In folgenden Medienspiegeln enthalten", "messages", "de");
t.add("l.sentAsEmail", "Als E-Mail versandt", "messages", "de");
t.add("l.articleUsedIn", "Verwendung", "messages", "de");
t.add("l.clippingsChoice", "{0} Clippings |{1} Clipping |]1,Inf] Clippings", "messages", "de");
t.add("l.bookmark", "Lesezeichen", "messages", "de");
t.add("l.linkToBookmark", "Zum Lesezeichen", "messages", "de");
t.add("l.flagProcessed", "Markierung: Bearbeitet", "messages", "de");
t.add("Nachweis-pdf", "Nachweis-pdf", "messages", "de");
t.add("Nachweis-xml", "Nachweis-xml", "messages", "de");
t.add("Nachweis-txt", "Nachweis-txt", "messages", "de");
t.add("TV-Mitschnitt", "TV-Mitschnitt", "messages", "de");
t.add("TV-Mitschnitt MP4", "TV-Mitschnitt MP4", "messages", "de");
t.add("TV-Mitschnitt ComVision", "TV-Mitschnitt ComVision", "messages", "de");
t.add("TV-Thumbnail", "TV-Thumbnail", "messages", "de");
t.add("l.wiederholungssendungen", "Wiederholungssendungen", "messages", "de");
t.add("l.selectedArticleMenueTitle", "Aktionen f\u00fcr ausgew\u00e4hlte Clippings", "messages", "de");
t.add("l.selectedFeedContentMenueTitle", "Aktionen f\u00fcr ausgew\u00e4hlte Feed-Inhalte", "messages", "de");
t.add("l.selectedArticleList", "Ausgew\u00e4hlte Clippings", "messages", "de");
t.add("l.selectedFeedArticleList", "Ausgew\u00e4hlte Feed-Inhalte", "messages", "de");
t.add("l.selectedPspList", "Ausgew\u00e4hlte Medienspiegel", "messages", "de");
t.add("l.generatePSP", "Medienspiegel erstellen", "messages", "de");
t.add("l.export", "Exportieren", "messages", "de");
t.add("l.exportAction", "Exportieren", "messages", "de");
t.add("l.alert", "Alert", "messages", "de");
t.add("l.autoAlert", "Auto-Alert", "messages", "de");
t.add("l.sendPerEmail", "E-Mail", "messages", "de");
t.add("l.sendPerAlert", "Alert", "messages", "de");
t.add("l.newsletter-delivery", "Newsletter-Versand", "messages", "de");
t.add("l.sendFeedContentPerEmail", "E-Mail", "messages", "de");
t.add("l.sendFeedContentPerAlert", "Alert", "messages", "de");
t.add("l.sendPerEmailTitle", "Per E-Mail versenden", "messages", "de");
t.add("l.sendPerAlertTitle", "Per Alert versenden", "messages", "de");
t.add("l.sendFeedContentPerEmailTitle", "Per E-Mail versenden", "messages", "de");
t.add("l.sendFeedContentPerAlertTitle", "Per Alert versenden", "messages", "de");
t.add("l.sendClippingPerEmail", "Clipping versenden", "messages", "de");
t.add("l.wasSentAsAlert", "Wurde als Alert versendet", "messages", "de");
t.add("l.hide", "Ausblenden", "messages", "de");
t.add("l.order", "Bestellen", "messages", "de");
t.add("l.analysisChart", "Analyse Diagramm", "messages", "de");
t.add("l.taggen", "Tags", "messages", "de");
t.add("l.tagClipping", "Tags bearbeiten", "messages", "de");
t.add("l.newTag", "Neues Tag", "messages", "de");
t.add("l.publicTag", "Neues Tag f\u00fcr alle Benutzer sichtbar", "messages", "de");
t.add("l.removeFromTag", "Aus aktuellem Tag entfernen", "messages", "de");
t.add("l.copy2dir", "Ordner", "messages", "de");
t.add("l.newDir", "Neuer Ordner", "messages", "de");
t.add("l.publicDir", "Neuer Ordner f\u00fcr alle Benutzer sichtbar", "messages", "de");
t.add("l.removeFromDir", "Aus aktuellem Ordner entfernen", "messages", "de");
t.add("l.folder", "Ordner", "messages", "de");
t.add("l.tag", "Tag", "messages", "de");
t.add("l.deleteTagMsg", "Soll %bez% \"%tagName%\" tats\u00e4chlich gel\u00f6scht werden?", "messages", "de");
t.add("l.maxSelArticle", "Es k\u00f6nnen nur maximal %selArticleCnt% Clippings markiert werden.", "messages", "de");
t.add("l.coverLetter", "Anschreiben", "messages", "de");
t.add("l.freeEmail", "E-Mail (Eingabe)", "messages", "de");
t.add("l.content", "Inhalt", "messages", "de");
t.add("l.newEntry", "Neuer Eintrag", "messages", "de");
t.add("l.articleSent", "Das Clipping wurde versendet.", "messages", "de");
t.add("l.articlesSent", "Die Clippings wurden versendet.", "messages", "de");
t.add("l.keepFeedContentSuccess", "Die ausgew\u00e4hlten Feed-Inhalte bleiben erhalten.", "messages", "de");
t.add("l.actionSuccess", "Die gew\u00fcnschte Aktion wurde erfolgreich ausgef\u00fchrt.", "messages", "de");
t.add("l.actionError", "Die gew\u00fcnschte Aktion konnte nicht ausgef\u00fchrt werden.", "messages", "de");
t.add("l.someActionError", "Die gew\u00fcnschte Aktion konnte nur teilweise ausgef\u00fchrt werden.", "messages", "de");
t.add("l.provideErrorId", "Fehlernummer (bitte immer mit angeben): ", "messages", "de");
t.add("l.numberOfErrors", "Fehler bei %amount% von %selected% ausgew\u00e4hlten Treffern:", "messages", "de");
t.add("l.selectionError", "Bitte erst einen Feed-Inhalt ausw\u00e4hlen.", "messages", "de");
t.add("l.articlesSentError", "Der Versand der Clippings war nicht erfolgreich.", "messages", "de");
t.add("l.errorNothingSelected", "Es wurde kein Clipping ausgew\u00e4hlt.", "messages", "de");
t.add("l.articleSelectionFrom", "Clipping-Auswahl von", "messages", "de");
t.add("l.textWrongRecipents", "An folgende Adressen konnte keine E-Mail gesendet werden:", "messages", "de");
t.add("l.textNoRecipents", "Sie haben keinen Empf\u00e4nger eingetragen.", "messages", "de");
t.add("l.coverLetterHeader", "hat Ihnen folgende Nachricht geschrieben:", "messages", "de");
t.add("l.selArtMenuDeleteTreffer", "l\u00f6schen", "messages", "de");
t.add("l.deleteTreffer", "{0,1} Clipping l\u00f6schen |]1,Inf] Clippings l\u00f6schen?", "messages", "de");
t.add("l.deleteTrefferText", "{0} Kein Clipping selektiert |{1} Wollen Sie das ausgew\u00e4hlte Clipping l\u00f6schen? |]1,Inf] Wollen Sie die ausgew\u00e4hlten Clippings l\u00f6schen?", "messages", "de");
t.add("l.deleteTrefferDeletedText", "{0} Kein Clipping selektiert |{1} %count% Clipping wurde erfolgreich gel\u00f6scht. |]1,Inf] %count% Clippings wurden erfolgreich gel\u00f6scht.", "messages", "de");
t.add("l.deleteTrefferNotDeletedText", "{0} Kein Clipping selektiert |{1} %count% Clipping kann aufgrund fehlender Rechte nicht gel\u00f6scht werden. |]1,Inf] %count% Clippings k\u00f6nnen aufgrund fehlender Rechte nicht gel\u00f6scht werden.", "messages", "de");
t.add("l.keepTreffer", "{0} Kein Clipping selektiert |{1} Clipping behalten |]1,Inf] Clippings behalten", "messages", "de");
t.add("l.keepTrefferText", "{0} Kein Clipping selektiert |{1} Wollen Sie das ausgew\u00e4hlte Clipping behalten? |]1,Inf] Wollen Sie die ausgew\u00e4hlten Clippings behalten?", "messages", "de");
t.add("l.keepTrefferKeptText", "{0} Kein Clipping selektiert |{1} %count% Clipping wurde erfolgreich auf Behalten gesetzt. |]1,Inf] %count% Clippings wurden erfolgreich auf Behalten gesetzt.", "messages", "de");
t.add("l.keepTrefferNotKeptText", "{0} Kein Clipping selektiert |{1} %count% Clipping wurde nicht auf Behalten gesetzt (Quelle nicht Echtzeit-Monitoring). |]1,Inf] %count% Clippings wurden nicht auf Behalten gesetzt (Quellen nicht Echtzeit-Monitoring).", "messages", "de");
t.add("l.keepFeedContent", "Behalten", "messages", "de");
t.add("l.feedContentProcessed", "Bearbeitet", "messages", "de");
t.add("l.feedContentUnprocessed", "Unbearbeitet", "messages", "de");
t.add("l.sendFeedContent", "Per E-Mail versenden", "messages", "de");
t.add("l.addFeedToMediaReview", "Zum Medienspiegel-Entwurf hinzuf\u00fcgen", "messages", "de");
t.add("l.showDetails", "Details anzeigen", "messages", "de");
t.add("l.action", "Aktion", "messages", "de");
t.add("l.createTag", "Speichern", "messages", "de");
t.add("l.createFolder", "Speichern", "messages", "de");
t.add("l.copy2NewTag", "Anlegen und markieren", "messages", "de");
t.add("l.copy2Tag", "Markieren", "messages", "de");
t.add("l.delFromTag", "Entfernen", "messages", "de");
t.add("l.copy2NewFolder", "Erstellen und kopieren", "messages", "de");
t.add("l.copy2Folder", "In Ordner kopieren", "messages", "de");
t.add("l.delFromFolder", "Entfernen", "messages", "de");
t.add("l.errNameRequired", "Bitte Name eingeben", "messages", "de");
t.add("l.articlesTaggedMobile", "Das Clipping wurde getaggt.", "messages", "de");
t.add("l.articlesTagged", "Die ausgew\u00e4hlten Clippings wurden mit \"%name%\" getaggt.", "messages", "de");
t.add("l.articles2folder", "Die ausgew\u00e4hlten Clippings wurden in Ordner \"%name%\" kopiert.", "messages", "de");
t.add("l.articleTagDel", "Von den ausgew\u00e4hlten Clippings wurde Tag \"%name%\" entfernt.", "messages", "de");
t.add("l.articleFolderDel", "Die ausgew\u00e4hlten Clippings wurden aus Ordner \"%name%\" entfernt.", "messages", "de");
t.add("l.tagCreated", "Tag \"%name%\" angelegt.", "messages", "de");
t.add("l.folderCreated", "Ordner \"%name%\" angelegt.", "messages", "de");
t.add("l.pressreview", "Medienspiegel", "messages", "de");
t.add("l.showArticle", "Clippings anzeigen", "messages", "de");
t.add("l.showPsp", "Medienspiegel anzeigen", "messages", "de");
t.add("l.setSentiment", "Bewertung setzen", "messages", "de");
t.add("l.setCategory", "Rubrik setzen", "messages", "de");
t.add("l.createCategory", "Rubrik erstellen", "messages", "de");
t.add("l.applySectionToMsp", "Rubrik beim Speichern in den aktuellen Medienspiegel \u00fcbernehmen", "messages", "de");
t.add("l.categoryNotSelectedSetError", "Es muss eine Rubrik gew\u00e4hlt werden!", "messages", "de");
t.add("l.categoryNotCreatedSetError", "Es muss ein Name f\u00fcr die Rubrik angegeben werden!", "messages", "de");
t.add("l.reuseMediaReviewArticles", "Bisherige Nutzung der Artikel in anderen Medienspiegeln ber\u00fccksichtigen", "messages", "de");
t.add("l.deselectChoice", "M\u00f6chten Sie die ausgew\u00e4hlten Clippings jetzt alle abw\u00e4hlen?", "messages", "de");
t.add("l.rememberDeselectChoice", "Einstellung speichern", "messages", "de");
t.add("l.unsetDeselectChoice", "Entscheidung aufheben, ob Auswahl von Clippings nach der Batch-Verarbeitung automatisch gel\u00f6scht wird", "messages", "de");
t.add("l.fileNotFound", "Die gew\u00fcnschte Datei existiert leider nicht:", "messages", "de");
t.add("l.noTag", "ohne Tag", "messages", "de");
t.add("l.noSearchTerm", "ohne Suchbegriff", "messages", "de");
t.add("l.noSearchProfile", "ohne Suchagent", "messages", "de");
t.add("l.noMediaType", "ohne Medienart", "messages", "de");
t.add("l.noMediaType1", "ohne Medientyp", "messages", "de");
t.add("l.noTotalRating", "ohne Gesamtbewertung", "messages", "de");
t.add("l.emailGroupBy", "Clippings gruppieren", "messages", "de");
t.add("l.emailGroupedBy", "Gruppierung:", "messages", "de");
t.add("l.emailGroupTag", "nach Tag", "messages", "de");
t.add("l.emailGroupSearchTerm", "nach Suchbegriff", "messages", "de");
t.add("l.emailGroupSearchProfile", "nach Suchagent", "messages", "de");
t.add("l.emailGroupMediaType", "nach Medienart", "messages", "de");
t.add("l.emailGroupMediaType1", "nach Medientyp", "messages", "de");
t.add("l.emailGroupTotalRating", "nach Gesamtbewertung", "messages", "de");
t.add("l.emailGroupUngrouped", "ohne Gruppierung", "messages", "de");
t.add("l.emailGroupToc", "Inhaltsverzeichnis der Gruppen", "messages", "de");
t.add("l.example", "Beispiel", "messages", "de");
t.add("l.examples", "Beispiele", "messages", "de");
t.add("l.updateStatusNothingToChange", "Es sind keine Clippings in der Auswahl.", "messages", "de");
t.add("l.updateRubrikenStatusSuccess", "Die Rubrik(en) wurden erfolgreich gesetzt.", "messages", "de");
t.add("l.updateRubrikenStatusError", "Beim Setzen der Rubrik(en) ist ein Fehler aufgetreten.", "messages", "de");
t.add("l.removeRubrikenStatusSuccess", "Die Rubrik(en) wurden erfolgreich entfernt.", "messages", "de");
t.add("l.removeRubrikenStatusError", "Beim Entfernen der Rubrik(en) ist ein Fehler aufgetreten.", "messages", "de");
t.add("l.updateTonalitaetStatusSuccess", "Die Tonalit\u00e4t wurde erfolgreich gesetzt.", "messages", "de");
t.add("l.updateTonalitaetStatusError", "Beim Setzen der Tonalit\u00e4t ist ein Fehler aufgetreten.", "messages", "de");
t.add("l.removeTonalitaetStatusSuccess", "Die Tonalit\u00e4t wurde erfolgreich entfernt.", "messages", "de");
t.add("l.removeTonalitaetStatusError", "Beim Entfernen der Tonalit\u00e4t ist ein Fehler aufgetreten.", "messages", "de");
t.add("l.updateMraStatusSuccess", "Die Analysedaten der Clippings wurden erfolgreich ge\u00e4ndert.", "messages", "de");
t.add("l.updateMraStatusError", "Es ist ein Fehler aufgetreten. Die \u00c4nderungen der Analysedaten konnte nicht vorgenommen werden.", "messages", "de");
t.add("l.updateReleaseStatusSuccess", "Die Freigabe wurde erfolgreich gesetzt.", "messages", "de");
t.add("l.updateReleaseStatusError", "Beim Setzen der Freigabe ist ein Fehler aufgetreten.", "messages", "de");
t.add("l.disabledUserRightsTooltip", "Dieses Nutzerrecht ist nicht ausw\u00e4hlbar, da ein entsprechender Auftrag fehlt.", "messages", "de");
t.add("l.auftragRolesTooltip", "Auftrag enth\u00e4lt folgende Benutzerrechte", "messages", "de");
t.add("l.auftragNoRolesTooltip", "An diesem Auftrag h\u00e4ngen keine Benutzerrechte", "messages", "de");
t.add("l.notifications", "Benachrichtigungen", "messages", "de");
t.add("l.noNotificationsAvailable", "Keine Benachrichtigungen vorhanden", "messages", "de");
t.add("l.notificationTooltip", "{0} Keine neuen Benachrichtigungen |{1} Eine neue Benachrichtigung|]1,Inf[ %count% neue Benachrichtigungen", "messages", "de");
t.add("l.notificationManagement", "News Verwaltung", "messages", "de");
t.add("l.addNotification", "News hinzuf\u00fcgen", "messages", "de");
t.add("l.emptyHTMLError", "Dieses Feld darf nicht leer sein", "messages", "de");
t.add("l.newsboardConfig", "Newsboard-Konfiguration", "messages", "de");
t.add("l.enable2fa", "Zwei-Faktor-Authentifizierung aktivieren", "messages", "de");
t.add("l.enable2faIsEnabled", "Aktiviert", "messages", "de");
t.add("l.enable2faIsDisabled", "Deaktiviert", "messages", "de");
t.add("l.setDefaultNewsboardConfig", "Standard Newsboard-Konfiguration setzen", "messages", "de");
t.add("l.newsboardConfigName_mm", "Medienbeobachtung", "messages", "de");
t.add("l.newsboardConfigDescription_mm", "Standard Newsboard-Konfiguration f\u00fcr Medienbeobachtung.", "messages", "de");
t.add("l.newsboardConfigName_mr", "Medienspiegel", "messages", "de");
t.add("l.newsboardConfigDescription_mr", "Standard Newsboard-Konfiguration f\u00fcr Medienspiegel.", "messages", "de");
t.add("l.newsboardConfigName_mr_summaries", "Medienspiegel mit Summaries", "messages", "de");
t.add("l.newsboardConfigDescription_mr_summaries", "Standard Newsboard-Konfiguration f\u00fcr Medienspiegel mit Summaries.", "messages", "de");
t.add("l.newsboardConfigName_mr_analysis", "Medienspiegel & Analyse", "messages", "de");
t.add("l.newsboardConfigDescription_mr_analysis", "Standard Newsboard-Konfiguration f\u00fcr Medienspiegel und Analyse.", "messages", "de");
t.add("l.newsboardConfigName_agency", "Agenturen", "messages", "de");
t.add("l.newsboardConfigDescription_agency", "Standard Newsboard-Konfiguration f\u00fcr Agenturen.", "messages", "de");
t.add("l.defaultSentimentPositive", "positiv", "messages", "de");
t.add("l.defaultSentimentNeutral", "neutral", "messages", "de");
t.add("l.defaultSentimentNegative", "negativ", "messages", "de");
t.add("f.filter", "Filter", "messages", "de");
t.add("f.filterSearchMobile", "Volltextsuche in Clippings", "messages", "de");
t.add("f.admin", "Filterverwaltung", "messages", "de");
t.add("f.name", "Name", "messages", "de");
t.add("f.dashboard", "Dashboards", "messages", "de");
t.add("f.newsletter", "Newsletter", "messages", "de");
t.add("f.rss", "Feeds", "messages", "de");
t.add("f.filterReset", "Aktuellen Filter zur\u00fccksetzen", "messages", "de");
t.add("f.auswahl", "Auswahl", "messages", "de");
t.add("f.abo", "Abo", "messages", "de");
t.add("f.auftrag", "Auftrag", "messages", "de");
t.add("f.auftragAll", "alle Auftr\u00e4ge", "messages", "de");
t.add("f.autor", "Autor", "messages", "de");
t.add("f.branche", "Branche", "messages", "de");
t.add("f.bundesland", "Bundesland", "messages", "de");
t.add("f.dimension", "Dimension Online", "messages", "de");
t.add("f.dokument", "Mit Dateien", "messages", "de");
t.add("f.erscheinungsweise", "Erscheinungsweise", "messages", "de");
t.add("f.international", "International", "messages", "de");
t.add("f.kategorie", "Kategorie", "messages", "de");
t.add("f.land", "Land", "messages", "de");
t.add("f.medienart", "Medienart", "messages", "de");
t.add("f.medientyp", "Medientyp", "messages", "de");
t.add("f.medium", "Medium", "messages", "de");
t.add("f.nielsen", "Nielsen", "messages", "de");
t.add("f.gesamtTonalitaet", "Gesamtbewertung", "messages", "de");
t.add("f.tonalitaet", "Bewertung", "messages", "de");
t.add("f.volltext", "Volltext", "messages", "de");
t.add("f.mrathema", "MRA-Thema", "messages", "de");
t.add("f.mratop", "Top Thema", "messages", "de");
t.add("f.mratext1", "Text 1", "messages", "de");
t.add("f.mratext2", "Text 2", "messages", "de");
t.add("f.mratext3", "Text 3", "messages", "de");
t.add("f.ordner", "Ordner", "messages", "de");
t.add("f.expressrubrik", "Rubrik", "messages", "de");
t.add("f.summaryrubrik", "Summary Rubrik", "messages", "de");
t.add("f.sprache", "Sprache", "messages", "de");
t.add("f.suchbegriff", "Suchbegriff", "messages", "de");
t.add("f.tag", "Tag", "messages", "de");
t.add("f.upload", "Uploads", "messages", "de");
t.add("f.abstract", "Abstracts", "messages", "de");
t.add("f.unterausgabe", "Unterausgaben", "messages", "de");
t.add("f.lektoriert", "Un-\/lektoriert", "messages", "de");
t.add("f.freigabe", "Freigabe", "messages", "de");
t.add("f.zeitraum", "Zeitraum", "messages", "de");
t.add("f.treffernummer", "Treffernummer", "messages", "de");
t.add("f.prodId", "ProdId", "messages", "de");
t.add("f.prodInternId", "Treffernummer", "messages", "de");
t.add("f.aliSuchprofil", "Suchagent", "messages", "de");
t.add("f.dienstleistung", "Dienstleistung", "messages", "de");
t.add("f.pressespiegel", "Medienspiegel", "messages", "de");
t.add("f.pressespiegelLast", "Letzter Medienspiegel", "messages", "de");
t.add("f.sender", "Sender Radio & TV", "messages", "de");
t.add("f.keinThemenBaum", "kein Themenbaum vorhanden", "messages", "de");
t.add("f.zeitbereich", "Zeitbereich", "messages", "de");
t.add("f.anmeldung", "Seit letzter Anmeldung", "messages", "de");
t.add("f.archiv", "Archiv", "messages", "de");
t.add("f.heute", "Heute", "messages", "de");
t.add("f.24hours", "Letzte 24 Stunden", "messages", "de");
t.add("f.48hours", "Letzte 48 Stunden", "messages", "de");
t.add("f.3tage", "Letzte 3 Tage", "messages", "de");
t.add("f.7tage", "Diese Woche", "messages", "de");
t.add("f.l7tage", "Letzte Woche", "messages", "de");
t.add("f.1monat", "Dieser Monat", "messages", "de");
t.add("f.l1monat", "Letzter Monat", "messages", "de");
t.add("f.90tage", "Letzte 90 Tage", "messages", "de");
t.add("f.1jahr", "Dieses Jahr", "messages", "de");
t.add("f.l1jahr", "Letztes Jahr", "messages", "de");
t.add("f.alle", "\u00fcber alle Felder", "messages", "de");
t.add("f.meldung", "Meldung", "messages", "de");
t.add("f.headline", "Headline", "messages", "de");
t.add("f.untertitel", "Untertitel", "messages", "de");
t.add("f.teaser", "Teaser", "messages", "de");
t.add("f.swu", "Suchwortumgebung", "messages", "de");
t.add("f.kommentar", "Kommentar", "messages", "de");
t.add("f.summary", "Summary", "messages", "de");
t.add("f.feedsource", "Feed Quelle", "messages", "de");
t.add("f.portalAlert", "Alert", "messages", "de");
t.add("f.portalReviewed", "Bearbeitet", "messages", "de");
t.add("f.lektoriertChoice", "Lektoriert", "messages", "de");
t.add("f.unlektoriertChoice", "Unlektoriert", "messages", "de");
t.add("f.un-lektoriertChoice", "Keine Unterscheidung", "messages", "de");
t.add("f.adhocChoice", "Adhoc", "messages", "de");
t.add("f.freigabeAllChoice", "Keine Einschr\u00e4nkung", "messages", "de");
t.add("f.freigabeOpenChoice", "Offen", "messages", "de");
t.add("f.freigabeApprovedChoice", "Freigegeben", "messages", "de");
t.add("f.freigabeUnapprovedChoice", "Nicht freigegeben", "messages", "de");
t.add("f.landescodeD", "D", "messages", "de");
t.add("f.landescodeDACH", "D-A-CH", "messages", "de");
t.add("f.landescodeAll", "Alle L\u00e4nder", "messages", "de");
t.add("f.mediumMedienart", "Medienart eingrenzen...", "messages", "de");
t.add("f.mrathemaWithAaew", "Ausschlie\u00dflich mit A\u00c4W Relevant", "messages", "de");
t.add("I.mrathemaWithAaewValue", "Wahr", "messages", "de");
t.add("f.input", "Eingang", "messages", "de");
t.add("f.doc_all", "Alle", "messages", "de");
t.add("f.doc_pdf", "PDF", "messages", "de");
t.add("f.doc_video", "Video", "messages", "de");
t.add("f.doc_video_m", "TV-Mitschnitt", "messages", "de");
t.add("f.doc_video_p", "TV-Preview", "messages", "de");
t.add("f.doc_audio", "Audio", "messages", "de");
t.add("f.doc_audio_m", "Audio-Mitschnitt", "messages", "de");
t.add("f.doc_audio_p", "Audio-Preview", "messages", "de");
t.add("f.doc_txt", "Text", "messages", "de");
t.add("f.doc_table", "Tabelle", "messages", "de");
t.add("f.doc_pres", "Pr\u00e4sentation", "messages", "de");
t.add("f.doc_pic", "Bilder", "messages", "de");
t.add("f.doc_office", "Office", "messages", "de");
t.add("f.analyse", "Eigene Analyse", "messages", "de");
t.add("f.analyseEditTrue", "bearbeitet", "messages", "de");
t.add("f.analyseEditFalse", "noch nicht bearbeitet", "messages", "de");
t.add("f.choose", "Bitte ausw\u00e4hlen...", "messages", "de");
t.add("f.chooseShort", "Ausw\u00e4hlen...", "messages", "de");
t.add("f.searchChoice", "Suchen...", "messages", "de");
t.add("f.searchMedium", "Medium suchen...", "messages", "de");
t.add("f.searchMediumURL", "Suche nach URL...", "messages", "de");
t.add("f.searchAdress", "Adresse suchen...", "messages", "de");
t.add("f.searchAuftrag", "Auftrag suchen...", "messages", "de");
t.add("f.searchAutor", "Autor suchen...", "messages", "de");
t.add("f.newFilter", "Leerer Filter", "messages", "de");
t.add("f.setAsStartFilter", "Startfilter setzen", "messages", "de");
t.add("f.unsetAsStartFilter", "Startfilter entfernen", "messages", "de");
t.add("f.isGlobalFilter", "F\u00fcr alle sichtbar", "messages", "de");
t.add("f.isNoGlobalFilter", "Nur f\u00fcr mich sichtbar", "messages", "de");
t.add("f.overwrite", "\u00dcberschreiben", "messages", "de");
t.add("f.delete", "L\u00f6schen", "messages", "de");
t.add("f.filterDelete", "Filter l\u00f6schen", "messages", "de");
t.add("f.filterDeleteText", "Wollen Sie den Filter \"%filterName%\" l\u00f6schen?", "messages", "de");
t.add("f.filterAlreadyDeleted", "Dieser Filter wurde bereits gel\u00f6scht.", "messages", "de");
t.add("f.filterDeleteTextWarning", "Achtung:", "messages", "de");
t.add("f.filterDeleteDashboardText", "Der Filter ist mit einem Dashboard verkn\u00fcpft. Dieses wird mit entfernt.", "messages", "de");
t.add("f.filterDeleteFilterText", "Der Filter wird als eingeschr\u00e4nkter Filter benutzt. User mit diesem Filter besitzen nach dem L\u00f6schen die volle Sicht.", "messages", "de");
t.add("f.filterDeleteNewsletterText", "Der Filter ist mit einem Newsletter verkn\u00fcpft. Es erfolgt kein weiterer Versand.", "messages", "de");
t.add("f.aaewGesamt", "A\u00c4W Gesamt", "messages", "de");
t.add("f.themenbaum", "Themenbaum", "messages", "de");
t.add("f.medienarttyp", "Medienart und Medientyp", "messages", "de");
t.add("f.von", "von", "messages", "de");
t.add("f.bis", "bis", "messages", "de");
t.add("f.dl0", "Medienbeobachtung", "messages", "de");
t.add("f.dl1", "Medienspiegel", "messages", "de");
t.add("f.dl2", "Medienresonanzanalyse", "messages", "de");
t.add("f.dl7", "Press Summary", "messages", "de");
t.add("f.dlShort0", "MB", "messages", "de");
t.add("f.dlShort1", "MSP", "messages", "de");
t.add("f.dlShort2", "MRA", "messages", "de");
t.add("f.dlShort7", "PS", "messages", "de");
t.add("f.filterNameExists", "Filtername wurde wegen Namensgleichheit angepasst.", "messages", "de");
t.add("f.id", "Id", "messages", "de");
t.add("f.accesstoken", "Access Token", "messages", "de");
t.add("f.validUntil", "G\u00fcltig bis", "messages", "de");
t.add("f.updateInterval", "Update Intervall", "messages", "de");
t.add("f.creator", "Erstellt von", "messages", "de");
t.add("f.active", "Aktiv", "messages", "de");
t.add("f.yes", "Ja", "messages", "de");
t.add("f.no", "Nein", "messages", "de");
t.add("f.expiredLink", "Abgelaufen (\u00fcber Edit-Symbol reaktivieren)", "messages", "de");
t.add("l.nopermission", "Sie haben leider keine Berechtigung, diesen Service zu nutzen.", "messages", "de");
t.add("l.nopermissionMsp", "Sie haben nicht die Berechtigung, Details dieses Medienspiegels zu betrachten", "messages", "de");
t.add("l.nofilepermission", "Sie haben keine Berechtigung, die gew\u00fcnschte Datei zu \u00f6ffnen:", "messages", "de");
t.add("l.nofilepermissionRole", "Leider fehlt Ihnen die Berechtigung zur Ansicht lizensierter Inhalte:", "messages", "de");
t.add("l.nofilepermissionLicence", "Die Lizenzfrist der gew\u00fcnschten Datei ist leider ausgelaufen:", "messages", "de");
t.add("l.nofilepermissionOrder", "Leider fehlt Ihnen die Berechtigung f\u00fcr den Auftrag, dem die gew\u00fcnschte Datei zugeordent ist:", "messages", "de");
t.add("l.nofilepermissionStatus", "Die gew\u00fcnschte Datei ist leider nicht mehr g\u00fcltig:", "messages", "de");
t.add("l.dmaAdminPortalkunde", "Administration ARGUS Portal-Kunden", "messages", "de");
t.add("l.adminPortalkunde", "Administration ARGUS Portal-Kunde", "messages", "de");
t.add("l.createPortalkunde", "ARGUS Portal-Kunde anlegen", "messages", "de");
t.add("l.search", "Suche", "messages", "de");
t.add("l.auftragsnummer", "Auftrags-Nr", "messages", "de");
t.add("l.debitornummer", "Debitor-Nr", "messages", "de");
t.add("l.customerlist", "Kundenliste", "messages", "de");
t.add("l.customerId", "Kunden-ID", "messages", "de");
t.add("l.customerName", "Kundenname", "messages", "de");
t.add("l.userLoginName", "Benutzer-Login-Name", "messages", "de");
t.add("l.userLastName", "Benutzer-Nachname", "messages", "de");
t.add("l.aboNo", "ABO-Nr", "messages", "de");
t.add("l.user", "Benutzer", "messages", "de");
t.add("l.customer", "Kunde", "messages", "de");
t.add("l.loginName", "Login-Name", "messages", "de");
t.add("l.email", "E-Mail", "messages", "de");
t.add("l.firstname", "Vorname", "messages", "de");
t.add("l.title", "Titel", "messages", "de");
t.add("l.password", "Kennwort", "messages", "de");
t.add("l.passwordFromEmail", "Passwort aus der E-Mail:", "messages", "de");
t.add("l.newPasswordSet", "Neues Passwort wurde erfolgreich gesetzt", "messages", "de");
t.add("l.confirm", "Best\u00e4tigung", "messages", "de");
t.add("l.status", "Status", "messages", "de");
t.add("l.save", "Speichern", "messages", "de");
t.add("l.active", "Aktiv", "messages", "de");
t.add("l.inactive", "Inaktiv", "messages", "de");
t.add("l.deleted", "Gel\u00f6scht", "messages", "de");
t.add("l.userGroup", "Benutzergruppe", "messages", "de");
t.add("l.defaultPortalLayout", "Standard Layout", "messages", "de");
t.add("l.clUpdIntervalSec", "Clippingliste Auto-Aktualisierung", "messages", "de");
t.add("l.mediatypeIcons", "Medienart-Icons", "messages", "de");
t.add("l.hideMediatypeIconsHint", "F\u00fcr den Fall, dass bei den Clipping-Emails und Newslettern Darstellungsprobleme auftreten, kann man die Icons hier deaktivieren.", "messages", "de");
t.add("l.hideMediatypeIcons", "Icons ausblenden", "messages", "de");
t.add("l.showMediatypeIcons", "Icons anzeigen", "messages", "de");
t.add("l.emailSenderName", "E-Mail-Absendername", "messages", "de");
t.add("l.emailSenderAddress", "E-Mail-Absenderadresse", "messages", "de");
t.add("l.emailReturnPath", "E-Mail Return-Path", "messages", "de");
t.add("l.useMspGenerator", "Medienspiegel-Generator", "messages", "de");
t.add("l.useNewswebMspGenerator", "Newsweb-MSP-Generator (NEU)", "messages", "de");
t.add("l.usePortalMspGenerator", "Portal-MSP-Generator (ALT)", "messages", "de");
t.add("l.superUser", "(Super-Admin)", "messages", "de");
t.add("l.argusAdmin", "(ARGUS-Admin)", "messages", "de");
t.add("l.minutes", "{1} Minute |]1,Inf] Minuten", "messages", "de");
t.add("l.defaultPortalDesign", "Standard Design", "messages", "de");
t.add("l.defaultPortalMenu", "Standard Men\u00fc", "messages", "de");
t.add("l.defaultPspHtmlKonfig", "Standard Medienspiegel HTML-Konfiguration", "messages", "de");
t.add("l.defaultPspEmailKonfig", "Standard Medienspiegel E-Mail-Konfiguration", "messages", "de");
t.add("l.defaultEmailKonfig", "Standard E-Mail-Konfiguration", "messages", "de");
t.add("l.portalLayout", "Layout", "messages", "de");
t.add("l.portalDesign", "Design", "messages", "de");
t.add("l.portalMenu", "Men\u00fc", "messages", "de");
t.add("l.defaultDataFieldSet", "Standard Datenfeld-Set", "messages", "de");
t.add("l.dataFieldSet", "Datenfeld-Set", "messages", "de");
t.add("l.defaultPspConfig", "Standard MSP-Konfiguration", "messages", "de");
t.add("l.pspConfig", "Medienspiegel Konfiguration", "messages", "de");
t.add("l.emailManage", "E-Mail-Verwaltung", "messages", "de");
t.add("l.createNewGroup", "Neue E-Mail Gruppe", "messages", "de");
t.add("l.currentEmailGroup", "E-Mail Gruppe:", "messages", "de");
t.add("l.emailGroup", "E-Mail Gruppe", "messages", "de");
t.add("l.deactivateEmailGroup", "E-Mail-Gruppe deaktivieren", "messages", "de");
t.add("l.deleteEmailGroup", "E-Mail-Gruppe endg\u00fcltig l\u00f6schen", "messages", "de");
t.add("l.activateEmailGroup", "E-Mail-Gruppe aktivieren", "messages", "de");
t.add("l.intoEmailGroup", "in E-Mail-Gruppe ", "messages", "de");
t.add("l.deleteEmail", "E-Mail endg\u00fcltig l\u00f6schen", "messages", "de");
t.add("l.newEmail", "Neue E-Mail", "messages", "de");
t.add("l.newEmailOwner", "E-Mail Besitzer", "messages", "de");
t.add("l.sendRegMailAgain", "Registrierungs-E-Mail erneut senden", "messages", "de");
t.add("l.callRegForm", "Registrierungs-Formular", "messages", "de");
t.add("l.sent", "Gesendet", "messages", "de");
t.add("l.auftragStatistics", "Auftrags Statistik", "messages", "de");
t.add("l.timerange", "Zeitbereich auf Basis des Produktionsdatums", "messages", "de");
t.add("l.timerangeFrom", "Von", "messages", "de");
t.add("l.timerangeTill", "Bis", "messages", "de");
t.add("l.timerangeError", "Zeitbereich: \"Von\" muss kleiner als \"Bis\" sein.", "messages", "de");
t.add("l.orderemptyError", "Bitte einen Auftrag ausw\u00e4hlen.", "messages", "de");
t.add("l.trefferCountPSP", "Medienspiegel Treffer", "messages", "de");
t.add("l.trefferCountTv", "TV Treffer", "messages", "de");
t.add("l.trefferCountTvClip", "TV Mitschnitte im ARGUS Portal", "messages", "de");
t.add("l.trefferCountHf", "H\u00f6rfunk Treffer", "messages", "de");
t.add("l.trefferCountHfClip", "H\u00f6rfunk Mitschnitte im ARGUS Portal", "messages", "de");
t.add("l.trefferCount", "Gesamt Treffer", "messages", "de");
t.add("l.errorMsg", "Leider ist ein Fehler aufgetreten.", "messages", "de");
t.add("l.emailGroupUndefined", "E-Mail Gruppe", "messages", "de");
t.add("l.infoTrefferCountPSP", "Anzahl nicht gel\u00f6schter Treffer, die durch die ARGUS DATA INSIGHTS Medienspiegelabteilung produziert wurden.", "messages", "de");
t.add("l.infoTrefferCountTv", "Anzahl nicht gel\u00f6schter TV-Treffer, ohne Kunden-eigene.", "messages", "de");
t.add("l.infoTrefferCountTvClip", "Anzahl der TV-Treffer, f\u00fcr die es einen Mitschnitt im ARGUS Portal gibt. Previews sind ausgeschlossen.", "messages", "de");
t.add("l.infoTrefferCountHf", "Anzahl nicht gel\u00f6schter HF-Treffer, ohne Kunden-eigene.", "messages", "de");
t.add("l.infoTrefferCountHfClip", "Anzahl der HF-Treffer, f\u00fcr die es einen Mitschnitt im ARGUS Portal gibt. Previews sind ausgeschlossen.", "messages", "de");
t.add("l.infoTrefferCountAbsolute", "Gesamtzahl nicht gel\u00f6schter Treffer inkl. Kunden-eigener.", "messages", "de");
t.add("l.getResult", "Resultat ausgeben", "messages", "de");
t.add("l.standardDashboard", "Standard-Dashboard", "messages", "de");
t.add("l.ezmDashboard", "EZM-Dashboard", "messages", "de");
t.add("l.pspHtmlEmailName", "Name", "messages", "de");
t.add("l.pspHtmlEmailPath", "Pfad", "messages", "de");
t.add("l.pspHtmlEmailCssPath", "Pfad CSS-Datei", "messages", "de");
t.add("l.pspHtmlEmailLanguage", "Sprache", "messages", "de");
t.add("l.pspHtmlEmailFields", "Zus\u00e4tzliche Felder", "messages", "de");
t.add("l.pspHtmlEmailDate", "Datum im Subject", "messages", "de");
t.add("l.pspHtmlEmailLoadTrefferData", "Trefferdaten laden", "messages", "de");
t.add("l.pspHtmlEmailSubject", "E-Mail Subject", "messages", "de");
t.add("l.pspHtmlEmailContent", "Inhalt", "messages", "de");
t.add("l.pspHtmlEmailAsEmailContent", "HTML-Medienspiegel als E-Mail Content einbinden", "messages", "de");
t.add("l.pspHtmlEmailsetUrls", "Link zur Medienspiegel Datei einbinden", "messages", "de");
t.add("l.pspHtmlEmailAttachement", "Anhang automatisch selektieren", "messages", "de");
t.add("l.pspHtmlEmailUrls", "URLs einbinden", "messages", "de");
t.add("l.pspHtmlEmailUrlsOpen", "offene Links", "messages", "de");
t.add("l.pspHtmlEmailUrlsClosed", "gesch\u00fctzte Links", "messages", "de");
t.add("l.pspHtmlEmailFooter", "Footer", "messages", "de");
t.add("l.pspHtmlEmailSetFooter", "Footer einbinden?", "messages", "de");
t.add("l.allEmailsVisible", "Alle Empf\u00e4nger sichtbar", "messages", "de");
t.add("l.emailTemplate", "E-Mail-Template-Pfad (Bsp: customLayouts\/4089\/layout1, Standard: leer)", "messages", "de");
t.add("l.adEnabled", "AD", "messages", "de");
t.add("psp.ENUM_PSP_EMAIL_ATTACHEMENT_NONE", "Ohne", "messages", "de");
t.add("psp.ENUM_PSP_EMAIL_ATTACHEMENT_HTML", "HTML", "messages", "de");
t.add("psp.ENUM_PSP_EMAIL_ATTACHEMENT_PDF", "PDF", "messages", "de");
t.add("psp.ENUM_PSP_EMAIL_ATTACHEMENT_HTML_PDF", "HTML + PDF", "messages", "de");
t.add("l.pwdForgotten", "Passwort vergessen?", "messages", "de");
t.add("l.enterEmail", "Bitte geben Sie Ihre E-Mail-Adresse ein", "messages", "de");
t.add("l.pwdForgottenSendError", "Beim Versenden der E-Mail ist ein Fehler aufgetreten.", "messages", "de");
t.add("l.pwdForgottenSendSuccess", "Eine E-Mail mit einem Aktivierungs-Link wurde an Ihre Adresse gesendet.", "messages", "de");
t.add("l.errPwdForgotten_noEmail", "Ihre E-Mail-Adresse fehlt.", "messages", "de");
t.add("l.errPwdForgotten_wrongEmail", "Ihre E-Mail-Adresse enth\u00e4lt Fehler, haben Sie sich vertippt?", "messages", "de");
t.add("l.errPwdForgotten_noAccount", "Es ist kein Account f\u00fcr diese E-Mail-Adresse registriert.", "messages", "de");
t.add("l.errPwdForgotten_no_ad_password_reset", "Ihr Login wird zentral \u00fcber Azure AD verwaltet. Bitte wenden Sie sich an Ihren Administrator.", "messages", "de");
t.add("l.pwdForgotten_line1", "entspreched Ihrer Anfrage haben wir f\u00fcr den Account \"%username%\" ein neues Passwort generiert:", "messages", "de");
t.add("l.pwdForgotten_line2", "Bitte aktivieren Sie das neue Passwort innerhalb von 24 Stunden \u00fcber folgenden Link:", "messages", "de");
t.add("l.pwdForgotten_line3", "Wir empfehlen Ihnen aus Gr\u00fcnden der Sicherheit, dieses Passwort gleich nach Aktivierung zu \u00e4ndern. Wenn diese Anfrage nicht von Ihnen ausgel\u00f6st wurde, informieren Sie uns bitte per E-Mail \u00fcber kunden@argusdatainsights.de. Ohne Aktivierung ist das bisherige Passwort weiterhin g\u00fcltig.", "messages", "de");
t.add("l.pwdActivation", "Passwort-Aktivierung", "messages", "de");
t.add("l.errPwdForgotten_requestNotExists", "Entschuldigung, f\u00fcr diesen Link existiert keine Anfrage.", "messages", "de");
t.add("l.errPwdForgotten_requestNoMMoreValid", "Entschuldigung, diese Anfrage wurde bereits ausgef\u00fchrt.", "messages", "de");
t.add("l.errPwdForgotten_requestTooOld", "Entschuldigung, Ihre Anfrage ist nach 24 Stunden nicht mehr g\u00fcltig.", "messages", "de");
t.add("l.errPwdForgotten_userNotValid", "Entschuldigung, der Benutzer ist nicht mehr g\u00fcltig.", "messages", "de");
t.add("l.errPwdForgotten_exception", "Bei der Anfrage ist ein Fehler aufgetreten.", "messages", "de");
t.add("l.errPwdNumber", "Das Passwort muss mindestens eine Zahl enthalten.", "messages", "de");
t.add("l.errPwdChar", "Das Passwort muss mindestens einen Buchstaben enthalten.", "messages", "de");
t.add("l.errPwdSpace", "Das Passwort darf kein Leerzeichen enthalten.", "messages", "de");
t.add("l.errPwdEmpty", "Das Passwort darf nicht leer sein.", "messages", "de");
t.add("l.errPwdCharNum", "Das Passwort muss mindestens 6 Zeichen enthalten.", "messages", "de");
t.add("l.errPwdOldNew", "Das neue Passwort darf nicht dem alten entsprechen.", "messages", "de");
t.add("l.errPwdWrongFormat", "Das Passwort muss mindestens 6 Zeichen und 1 Ziffer enthalten.", "messages", "de");
t.add("I.errPwdUserLocked", "Leider ist Ihr Konto aufgrund zu vieler Anmeldeversuche gesperrt.", "messages", "de");
t.add("l.errReg", "Es ist ein Fehler aufgetreten.", "messages", "de");
t.add("l.errReg_invalidToken", "Der Registrierungs-Token ist ung\u00fcltig.", "messages", "de");
t.add("l.errReg_done", "Diese Registrierung wurde bereits ausgef\u00fchrt.", "messages", "de");
t.add("l.newcustomer", "Kunde anlegen", "messages", "de");
t.add("l.update", "aktualisieren", "messages", "de");
t.add("l.createUser", "Benutzer registrieren", "messages", "de");
t.add("l.createUserGroup", "Benutzergruppe anlegen", "messages", "de");
t.add("l.createPortalDesign", "Design anlegen", "messages", "de");
t.add("l.createPortalMenu", "Men\u00fc anlegen", "messages", "de");
t.add("l.createEmailKonfig", "E-Mail-Konfiguration anlegen", "messages", "de");
t.add("l.createPspEmailKonfig", "Medienspiegel E-Mail-Konfiguration anlegen", "messages", "de");
t.add("l.createPspHtmlKonfig", "Medienspiegel HTML-Konfiguration anlegen", "messages", "de");
t.add("l.createDataFieldSet", "Datenfeld-Set anlegen", "messages", "de");
t.add("l.edit", "editieren", "messages", "de");
t.add("l.emailAdmin", "E-Mail-Verwaltung", "messages", "de");
t.add("l.rubrikAdmin", "Rubrikenbilder verwalten", "messages", "de");
t.add("l.noData", "Keine Daten vorhanden", "messages", "de");
t.add("l.defaultConfig", "Standard-Einstellung", "messages", "de");
t.add("l.default", "Standard", "messages", "de");
t.add("l.adminPortaluser", "Benutzer-Einstellungen", "messages", "de");
t.add("l.adminUserGroup", "Benutzergruppe", "messages", "de");
t.add("l.display", "Darstellung", "messages", "de");
t.add("l.adminPortaColorDesign", "Design", "messages", "de");
t.add("l.adminPortaMenu", "Men\u00fc", "messages", "de");
t.add("l.adminEmailKonfig", "E-Mail-Konfiguration", "messages", "de");
t.add("l.adminPspEmailKonfig", "Medienspiegel E-Mail-Konfiguration", "messages", "de");
t.add("l.adminPspHtmlKonfig", "Medienspiegel HTML-Konfiguration", "messages", "de");
t.add("l.adminPortaLayout", "Layout", "messages", "de");
t.add("l.adminDatenfeldset", "Datenfeld-Set", "messages", "de");
t.add("l.adminPspConfig", "Medienspiegel-Konfig.", "messages", "de");
t.add("l.adminMspGenerator", "MSP-Generator", "messages", "de");
t.add("l.startPage", "Startseite", "messages", "de");
t.add("l.startAuftrag", "Startauftrag", "messages", "de");
t.add("l.switchUser", "Benutzerrolle annehmen", "messages", "de");
t.add("l.switchUserExit", "Benutzerrolle verlassen", "messages", "de");
t.add("l.changelogin", "Zugangsdaten \u00e4ndern", "messages", "de");
t.add("l.showData", "Daten zeigen", "messages", "de");
t.add("l.hideData", "Daten verbergen", "messages", "de");
t.add("l.gender", "Anrede", "messages", "de");
t.add("l.female", "Frau", "messages", "de");
t.add("l.male", "Herr", "messages", "de");
t.add("l.german", "deutsch", "messages", "de");
t.add("l.english", "englisch", "messages", "de");
t.add("l.pleaseSelect", "Bitte w\u00e4hlen", "messages", "de");
t.add("l.pleaseSelectAddress", "Bitte Anrede w\u00e4hlen", "messages", "de");
t.add("l.noSelection", "keine Auswahl", "messages", "de");
t.add("l.formError", "Formularfehler", "messages", "de");
t.add("l.internalServerError", "Ein interner Fehler ist aufgetreten. Bitte wenden sie sich an den Support, sollte der Fehler erneut auftreten.", "messages", "de");
t.add("l.formResult", "Resultat", "messages", "de");
t.add("l.updateSuccess", "Die \u00c4nderung wurde ausgef\u00fchrt", "messages", "de");
t.add("l.updateError", "Bei der \u00c4nderung ist ein Fehler aufgetreten", "messages", "de");
t.add("l.userGroups", "Benutzergruppen", "messages", "de");
t.add("l.roles", "Benutzerrechte", "messages", "de");
t.add("l.defaultRoles", "Standardrechte", "messages", "de");
t.add("l.extendedRoles", "Erweiterte Rechte", "messages", "de");
t.add("l.errTokenNomoreValid", "Der Token ist nicht mehr g\u00fcltig.", "messages", "de");
t.add("l.errTokenInvalid", "Der Token ist ung\u00fcltig.", "messages", "de");
t.add("l.errUsernameExists", "Dieser Benutzername existiert bereits.", "messages", "de");
t.add("l.errUsernameSpace", "Der Benutzername darf keine Leerzeichen enthalten.", "messages", "de");
t.add("l.errEmailExists", "Diese E-Mail-Adresse existiert bereits.", "messages", "de");
t.add("l.userNamePrefix", "Pr\u00e4fix-Benutzername", "messages", "de");
t.add("l.labelEmailGroups", "E-Mail Gruppen verwalten", "messages", "de");
t.add("l.labelEmailGroupsDisabled", "Inaktive Gruppen", "messages", "de");
t.add("l.formDeleteUserHint", "Hinweis: Der Nutzer besitzt RSS-Feeds und\/oder Newsletter die beim L\u00f6schen deaktiviert werden.", "messages", "de");
t.add("l.ssoIframeIntegration", "Iframe Integration", "messages", "de");
t.add("l.ssoTalkwalkerIntegration", "Talkwalker SSO", "messages", "de");
t.add("l.argusFlatRoles", "Argus predefined roles", "messages", "de");
t.add("l.argusPredefinedRole.S", "ARGUS Portal S", "messages", "de");
t.add("l.argusPredefinedRole.M", "ARGUS Portal M", "messages", "de");
t.add("l.argusPredefinedRole.L", "ARGUS Portal L", "messages", "de");
t.add("l.argusPredefinedRole.XL", "ARGUS Portal XL", "messages", "de");
t.add("r.azLogin", "Login with Azure AD", "messages", "de");
t.add("r.userName", "Benutzername oder E-Mail", "messages", "de");
t.add("r.password", "Passwort", "messages", "de");
t.add("r.passwordConfirm", "Passwort Wiederholung", "messages", "de");
t.add("r.emailAddress", "E-Mail-Adresse", "messages", "de");
t.add("r.newsletterRegistration", "Ja, ich m\u00f6chte Unternehmensnews und Informationen zu neuesten Produkten sowie Aktionen von ARGUS DATA INSIGHTS\u00ae per Newsletter erhalten (es wird nur die E-Mail-Adresse \u00fcbermittelt).", "messages", "de");
t.add("l.invitationSuccessMsg", "Der Benutzer \"%username%\" wurde erfolgreich angelegt. Eine Registrierungs-E-Mail wurde an \"%email%\" versendet.", "messages", "de");
t.add("l.roleUserGroupMsg", "Die Benutzerrechte erg\u00e4nzen die Rechte einer ggf. gew\u00e4hlten Benutzergruppe", "messages", "de");
t.add("l.note", "Hinweis", "messages", "de");
t.add("l.registrationInvMsg", "Eine E-Mail mit einem Best\u00e4tigungs-Token wird an die E-Mail-Adresse des Benutzers gesendet. Dieser kann dann Benutzername und Passwort eingeben und die Registrierung best\u00e4tigen.", "messages", "de");
t.add("l.sendRegEmail", "Registrieren", "messages", "de");
t.add("l.register", "Registrieren", "messages", "de");
t.add("l.userregistration4", "Benutzerregistrierung f\u00fcr", "messages", "de");
t.add("l.chpwd", "Passwort \u00e4ndern", "messages", "de");
t.add("l.openInvitations", "Nicht versendete Registrierungen", "messages", "de");
t.add("l.pendingInvitations", "Offene Registrierungen", "messages", "de");
t.add("l.allInvitations", "Alle Registrierungen", "messages", "de");
t.add("l.invitations", "Registrierungsliste", "messages", "de");
t.add("l.errAuftragRequired", "Bitte Auftrag ausw\u00e4hlen", "messages", "de");
t.add("l.errFilterRequired", "Bitte Filter ausw\u00e4hlen (Gespeicherter Filter muss existieren)", "messages", "de");
t.add("l.errMinTargetHitsRequired", "Bitte Mindestanzahl eingeben", "messages", "de");
t.add("l.errDailyTimeRequired", "Bitte Zeitpunkt eingeben", "messages", "de");
t.add("l.errIntervallRequired", "Bitte Intervall eingeben", "messages", "de");
t.add("l.errEmailRequired", "Bitte E-Mail-Adresse ausw\u00e4hlen (Einrichtung in Nutzerverwaltung)", "messages", "de");
t.add("l.errEmailGroupEmpty", "F\u00fcr die E-Mail-Gruppe sind keine E-Mails ausgew\u00e4hlt (Konfiguration in der Nutzerverwaltung)", "messages", "de");
t.add("l.autologin", "IP-Autologin", "messages", "de");
t.add("l.autologinKey", "Key-Autologin", "messages", "de");
t.add("l.autologinKeyGenerate", "Generieren", "messages", "de");
t.add("l.autologinKeyRemove", "L\u00f6schen", "messages", "de");
t.add("l.autologinKeyRemoveWarning", "Wollen Sie die URL wirklich l\u00f6schen? Sie k\u00f6nnen jederzeit eine neue URL generieren, jedoch nicht dieselbe nochmal.", "messages", "de");
t.add("l.autologinSubnet", "IP-Subnetz-Autologin", "messages", "de");
t.add("l.errIpAlreadyUsed", "Die IP wurde bereits einem anderen Benutzer zugeordnet", "messages", "de");
t.add("l.errWrongIpFormat", "Die IP ist nicht g\u00fcltig", "messages", "de");
t.add("l.errIpSubnetAlreadyUsed", "Das IP-Subnetz wurde bereits einem anderen Benutzer zugeordnet", "messages", "de");
t.add("l.errWrongIpSubnetFormat", "Das IP-Subnetz ist nicht g\u00fcltig", "messages", "de");
t.add("l.autologinMsg1", "Verschiedene Eintr\u00e4ge bitte mit Komma, Semikolon oder Leerzeichen trennen", "messages", "de");
t.add("l.autologinMsg2", "Das Autologin erfolgt \u00fcber die URL: \"%baseurl%\"", "messages", "de");
t.add("l.selectAdditionalFields", "Zus\u00e4tzliche Daten ausw\u00e4hlen", "messages", "de");
t.add("l.datenFeldSetDescr", "Anpassung der Darstellung eines Artikels in der erweiterten Darstellungsform der Artikelliste.", "messages", "de");
t.add("l.portalDesignDescr", "Anpassung von Farben und Logo in der Darstellung.", "messages", "de");
t.add("l.portalMenuDescr", "Anpassung der Punkte im Hauptmen\u00fc. Der Men\u00fcpunkt \"Clippings\" muss f\u00fcr die Desktop-Ansicht aktiviert sein, wenn Dashboard oder Medienspiegel vorhanden sind.<br \/>Bitte beachten Sie, dass das Abschalten des Men\u00fc-Punkts \"Work\" den Zugriff auf die Nutzerverwaltung verhindert.", "messages", "de");
t.add("l.reichweite", "Reichweite", "messages", "de");
t.add("l.reachZero", "Zu diesem Sender liegen keine Reichweiten vor.", "messages", "de");
t.add("l.reachNotAvailable", "Die Reichweiten wurden noch nicht bereitgestellt.", "messages", "de");
t.add("l.portalColors", "Allgemeine Farben", "messages", "de");
t.add("l.dashboardColors", "Dashboard-Farben", "messages", "de");
t.add("l.logo", "Logo", "messages", "de");
t.add("l.deactivatePspConfig", "Deaktivieren", "messages", "de");
t.add("l.activatePspConfig", "Aktivieren", "messages", "de");
t.add("l.cannotDisableMenuEntry", "Dieser Punkt kann nicht deaktiviert werden.", "messages", "de");
t.add("l.rtvformat", "Format", "messages", "de");
t.add("l.rtvformatexport", "Format (RTV)", "messages", "de");
t.add("l.errorEmailSave", "Die E-Mail kann nicht gespeichert werden. Falsches Format.", "messages", "de");
t.add("l.emailEmpty", "Bitte eine E-Mail eingeben.", "messages", "de");
t.add("l.emailOwnerEmpty", "Bitte einen E-Mail Besitzer eintragen.", "messages", "de");
t.add("l.emailGroupEmpty", "Bitte eine E-Mail Gruppe eintragen.", "messages", "de");
t.add("l.emailGroupName", "Name", "messages", "de");
t.add("l.url", "URL", "messages", "de");
t.add("l.disabledMraButtonHint", "Diese Funktion ist bei der Bearbeitung von Clippings mehrerer Auftr\u00e4ge nicht verf\u00fcgbar.", "messages", "de");
t.add("l.disabledMraButtonHint2", "F\u00fcr Clippings aus diesem Auftrag ist keine Analyse-Funktion m\u00f6glich.", "messages", "de");
t.add("l.disabledPspButtonHint", "Diese Funktion ist bei Pressespiegeln mit erweitertem Achivrecht nicht verf\u00fcgbar.", "messages", "de");
t.add("l.feedcontent", "Feed Liste", "messages", "de");
t.add("l.sortArticleFields", "Clippingdaten", "messages", "de");
t.add("l.sortArticleMkzFields", "Kennzahlen", "messages", "de");
t.add("l.export_kennzahlen", "Kennzahlen (Auswahl)", "messages", "de");
t.add("l.anz_zchn", "Zeichenanzahl (Clipping)", "messages", "de");
t.add("l.medienrubrik", "Medienrubrik", "messages", "de");
t.add("l.articlerubrik", "Rubrik (Clipping)", "messages", "de");
t.add("l.checkAll", "Alle ausw\u00e4hlen", "messages", "de");
t.add("l.selectFileFormat", "Dateiformat ausw\u00e4hlen", "messages", "de");
t.add("l.selectedArticlesAddedToMediaReview", "Ausgew\u00e4hlte Clippings wurden dem Medienspiegel hinzugef\u00fcgt.", "messages", "de");
t.add("l.selectedArticlesCouldntBeAddedToMediaReview", "Ausgew\u00e4hlte Clippings konnten nicht dem Medienspiegel hinzugef\u00fcgt werden.", "messages", "de");
t.add("l.selectedFeedContentAddedToMediaReview", "Ausgew\u00e4hlte Feed-Inhalte wurden dem Medienspiegel hinzugef\u00fcgt.", "messages", "de");
t.add("l.selectedFeedContentCouldntBeAddedToMediaReview", "Ausgew\u00e4hlte Feed-Inhalte konnten nicht dem Medienspiegel hinzugef\u00fcgt werden.", "messages", "de");
t.add("l.mediaGenreOnline", "Online-Medien", "messages", "de");
t.add("l.mediaGenreNewsAgencies", "Nachrichtenagenturen", "messages", "de");
t.add("w.overview", "\u00dcbersicht", "messages", "de");
t.add("w.tag", "Tags", "messages", "de");
t.add("w.folder", "Ordner", "messages", "de");
t.add("w.newsletter", "Newsletter", "messages", "de");
t.add("w.ali", "Echtzeit-Monitoring", "messages", "de");
t.add("w.aliKampagne", "Kampagnen", "messages", "de");
t.add("w.aliSuchprofi", "Suchagenten", "messages", "de");
t.add("w.psp", "Medienspiegel", "messages", "de");
t.add("w.clippingtiles", "Clippings-Magazinsicht", "messages", "de");
t.add("w.report", "Reports", "messages", "de");
t.add("w.presssummary", "Summaries", "messages", "de");
t.add("w.rss", "Feeds", "messages", "de");
t.add("w.filter", "Filter", "messages", "de");
t.add("w.useradmin", "Nutzerverwaltung", "messages", "de");
t.add("w.thisWeek", "Diese Woche", "messages", "de");
t.add("w.thisMonth", "Dieser Monat", "messages", "de");
t.add("w.total", "Gesamt", "messages", "de");
t.add("w.activ", "Aktive", "messages", "de");
t.add("w.inactiv", "Inaktive", "messages", "de");
t.add("w.taggedArticle", "Clippings", "messages", "de");
t.add("w.taggedPSP", "Medienspiegel", "messages", "de");
t.add("w.blackboardWidget", "Clippings nach Medienart", "messages", "de");
t.add("w.userNumber", "Benutzer", "messages", "de");
t.add("w.blackboardTitle", "\u00dcbersicht (alle Auftr\u00e4ge)", "messages", "de");
t.add("w.medienarten", "Medienarten", "messages", "de");
t.add("w.showMdMart1", "Print-Clippings anzeigen", "messages", "de");
t.add("w.showMdMart2", "Agentur-Clippings anzeigen", "messages", "de");
t.add("w.showMdMart3", "TV-Sendungen anzeigen", "messages", "de");
t.add("w.showMdMart4", "Online-Clippings anzeigen", "messages", "de");
t.add("w.showMdMart6", "Videotext-Seiten anzeigen", "messages", "de");
t.add("w.showMdMart7", "H\u00f6rfunk-Sendungen anzeigen", "messages", "de");
t.add("w.andMore", "... und weitere", "messages", "de");
t.add("w.showAll", "Alle anzeigen", "messages", "de");
t.add("w.embed", "Embedding widgets", "messages", "de");
t.add("ali.kampagnen", "Kampagnen", "messages", "de");
t.add("ali.kampagne", "Kampagne", "messages", "de");
t.add("ali.editKampagnen", "Kampagnen bearbeiten", "messages", "de");
t.add("ali.addKampagne", "Kampagne hinzuf\u00fcgen", "messages", "de");
t.add("ali.addSearchprofil", "Suchagent hinzuf\u00fcgen", "messages", "de");
t.add("ali.suchprofile", "Suchagenten", "messages", "de");
t.add("ali.suchprofil", "Suchagent", "messages", "de");
t.add("ali.auftrag", "Auftrag", "messages", "de");
t.add("ali.saved", "Gespeichert", "messages", "de");
t.add("ali.error", "Fehler", "messages", "de");
t.add("ali.errorMessageValidation", "Die Daten konnten nicht gespeichert werden, bitte pr\u00fcfen Sie die Eingabefelder.", "messages", "de");
t.add("ali.noKampagnenAvailable", "Keine Kampagne vorhanden", "messages", "de");
t.add("ali.noSuchprofileAvailable", "Keine Suchagenten vorhanden", "messages", "de");
t.add("ali.createNewKampagnen", "Neue Kampagne anlegen", "messages", "de");
t.add("ali.createNewSuchprofil", "Neuen Suchagent anlegen", "messages", "de");
t.add("ali.suchprofileSectionPreferences", "Grundeinstellungen", "messages", "de");
t.add("ali.suchprofileSectionQuery", "Abfrage", "messages", "de");
t.add("ali.suchprofileSectionTimerange", "Zeitraum", "messages", "de");
t.add("ali.suchprofileSectionLanguage", "Sprache", "messages", "de");
t.add("ali.suchprofileSectionMedia", "Medien", "messages", "de");
t.add("ali.name", "Name", "messages", "de");
t.add("ali.activ", "Aktiv", "messages", "de");
t.add("ali.adhoc", "Ad-Hoc Suche", "messages", "de");
t.add("ali.casesensitive", "Gro\u00df-\/Keinschreibung", "messages", "de");
t.add("ali.tonalitaet", "Sentiment", "messages", "de");
t.add("ali.suchbegriff", "Suchbegriff", "messages", "de");
t.add("ali.kontextbegriffe", "Kontextbegriffe", "messages", "de");
t.add("ali.ausschluesse", "Ausschl\u00fcsse", "messages", "de");
t.add("ali.expertsearch", "Expertensuche", "messages", "de");
t.add("ali.timerangeFrom", "Von", "messages", "de");
t.add("ali.timerangeTill", "Bis", "messages", "de");
t.add("ali.languageDe", "Deutsch", "messages", "de");
t.add("ali.languageEn", "Englisch", "messages", "de");
t.add("ali.mediaTwitter", "X", "messages", "de");
t.add("ali.mediaFacebook", "Facebook", "messages", "de");
t.add("ali.mediaYoutube", "Youtube", "messages", "de");
t.add("ali.mediaGoogleplus", "Google+", "messages", "de");
t.add("ali.mediaOnlineK", "News", "messages", "de");
t.add("ali.mediaBlogs", "Blogs", "messages", "de");
t.add("ali.mediaMessageboard", "Foren", "messages", "de");
t.add("ali.mediaVerbraucherportale", "Verbraucherportale", "messages", "de");
t.add("ali.mediaSonstigeSMFormate", "Sonstige Social-Media Formate", "messages", "de");
t.add("ali.mediaPresseportale", "Presseportale", "messages", "de");
t.add("ali.mediaIndiPanel", "Individuelles Panel", "messages", "de");
t.add("ali.confirmKampagneDeletion", "Wollen Sie die Kampagne und all deren Suchagenten wirklich l\u00f6schen?", "messages", "de");
t.add("ali.confirmSuchprofilDeletion", "Wollen Sie den Suchagenten wirklich l\u00f6schen?", "messages", "de");
t.add("ali.confirmKampagneDelete", "Kampagne l\u00f6schen", "messages", "de");
t.add("ali.confirmSuchprofilDelete", "Suchagent l\u00f6schen", "messages", "de");
t.add("ali.confirmKampagneDeleteText", "Wollen Sie die Kampagne \"%name%\" inklusive aller Suchagenten und zugeh\u00f6rigen Treffer wirklich l\u00f6schen?", "messages", "de");
t.add("ali.confirmSuchprofilDeleteText", "Wollen Sie den Suchagenten \"%name%\" und alle zugeh\u00f6rigen Treffer wirklich l\u00f6schen?", "messages", "de");
t.add("ali.expertmode", "Zum Expertenmodus", "messages", "de");
t.add("ali.normalmode", "Expertenmodus beenden", "messages", "de");
t.add("ali.adhocButton", "Ad-Hoc", "messages", "de");
t.add("ali.unknownError", "Ein unbekannter Fehler ist aufgetreten", "messages", "de");
t.add("ali.confirmEnterExpertmode", "Beim aktivieren des Expertenmodus werden alle Suchbegriffe, Kontextbegriffe und Ausschl\u00fcsse gel\u00f6scht. Wollen Sie den Expertenmodus aktivieren?", "messages", "de");
t.add("ali.confirmLeavExpertmode", "Beim deaktivieren des Expertenmodus wird die Expertensuche gel\u00f6scht. Wollen Sie den Expertenmodus deaktivieren?", "messages", "de");
t.add("ali.maxCountSuchprofileReached", "Die maximale Anzahl von %count% Suchagenten f\u00fcr diesen Auftrag wurde erreicht, es k\u00f6nnen keine weiteren Suchagenten angelegt werden.", "messages", "de");
t.add("ali.maxCountTermsReached", "Die maximale Anzahl an Termen wurde erreicht, bitte reduzieren Sie die Anzahl an Termen.", "messages", "de");
t.add("ali.errTimeRange", "Zeitraum: \"Von\" muss kleiner als \"Bis\" sein.", "messages", "de");
t.add("ali.errLanguage", "Bitte mindestens eine Sprache ausw\u00e4hlen.", "messages", "de");
t.add("ali.errSB", "Bitte Suchbegriffe eingeben.", "messages", "de");
t.add("ali.errMedia", "Bitte mindestens ein Medium ausw\u00e4hlen.", "messages", "de");
t.add("ali.errSign", "Bitte ung\u00fcltiges Zeichen \"!\" entfernen.", "messages", "de");
t.add("n.newsletter", "Newsletter", "messages", "de");
t.add("n.name", "Name", "messages", "de");
t.add("n.filter", "Filter", "messages", "de");
t.add("n.order", "Auftr\u00e4ge", "messages", "de");
t.add("n.FilterOrOrders", "Filter oder Auftr\u00e4ge", "messages", "de");
t.add("n.medienarten", "Medienarten", "messages", "de");
t.add("n.suchbegriffe", "Suchbegriff", "messages", "de");
t.add("n.tags", "Tags", "messages", "de");
t.add("n.tonalitaet", "Gesamtbewertung", "messages", "de");
t.add("n.sendingFlavor", "Wann soll der Newsletter versendet werden", "messages", "de");
t.add("n.sendingFlavorImmediate", "Sofort bei Eingang einer Meldung", "messages", "de");
t.add("n.sendingFlavorMinimalCount", "Bei einer Mindestanzahl", "messages", "de");
t.add("n.sendingFlavorFixedTime", "Zu festen Zeiten", "messages", "de");
t.add("n.daily", "T\u00e4glich", "messages", "de");
t.add("n.weekly", "Einmal W\u00f6chentlich", "messages", "de");
t.add("n.monthly", "Monatlich", "messages", "de");
t.add("n.interval", "Intervall", "messages", "de");
t.add("n.monday", "Montag", "messages", "de");
t.add("n.tuesday", "Dienstag", "messages", "de");
t.add("n.wednesday", "Mittwoch", "messages", "de");
t.add("n.thursday", "Donnerstag", "messages", "de");
t.add("n.friday", "Freitag", "messages", "de");
t.add("n.saturday", "Samstag", "messages", "de");
t.add("n.sunday", "Sonntag", "messages", "de");
t.add("n.weekday", "Wochentag", "messages", "de");
t.add("n.monthlyDay", "Tagesnummer des Monats", "messages", "de");
t.add("n.intervalHourEveryHour", "Jede Stunde", "messages", "de");
t.add("n.intervalHourEvery2Hours", "Alle 2 Stunden", "messages", "de");
t.add("n.intervalHourEvery4Hours", "Alle 4 Stunden", "messages", "de");
t.add("n.intervalHourEvery6Hours", "Alle 6 Stunden", "messages", "de");
t.add("n.intervalHourEvery8Hours", "Alle 8 Stunden", "messages", "de");
t.add("n.intervalHourEvery12Hours", "Alle 12 Stunden", "messages", "de");
t.add("n.html", "HTML", "messages", "de");
t.add("n.htmlSimple", "HTML einfach", "messages", "de");
t.add("n.pdf", "PDF", "messages", "de");
t.add("n.newsletterFormat", "Newsletter Format", "messages", "de");
t.add("n.recipientGroup", "Empf\u00e4nger Gruppen", "messages", "de");
t.add("n.recipient", "Empf\u00e4nger", "messages", "de");
t.add("n.sender", "Absender", "messages", "de");
t.add("n.contentFlavorStandart", "Standard", "messages", "de");
t.add("n.contentFlavorIndividually", "Individuell", "messages", "de");
t.add("n.newsletterContent", "Newsletter Inhalt", "messages", "de");
t.add("n.contentPropertiesTrefferliste", "Trefferliste", "messages", "de");
t.add("n.contentPropertiesTrefferanzahl", "Trefferanzahl", "messages", "de");
t.add("n.contentPropertiesTreffer", "Treffer", "messages", "de");
t.add("n.contentPropertiesMedientitel", "Medientitel", "messages", "de");
t.add("n.contentPropertiesAusgabedatum", "Ausgabedatum", "messages", "de");
t.add("n.contentPropertiesAusgabeuhrzeit", "Ausgabeuhrzeit", "messages", "de");
t.add("n.contentPropertiesHeadline", "Headline", "messages", "de");
t.add("n.contentPropertiesURL", "URL", "messages", "de");
t.add("n.contentPropertiesSeite", "Seite", "messages", "de");
t.add("n.contentPropertiesSeiteShort", "S.", "messages", "de");
t.add("n.contentPropertiesSuchwortumgebung", "Suchwortumgebung", "messages", "de");
t.add("n.contentPropertiesTvHinweis", "TV-Hinweis", "messages", "de");
t.add("n.contentPropertiesTeaser", "Teaser", "messages", "de");
t.add("n.contentPropertiesTeaserSuchwortumgebung", "Suchwortumgebung \/ Teaser", "messages", "de");
t.add("n.contentPropertiesSuchbegriffe", "Suchbegriffe", "messages", "de");
t.add("n.contentPropertiesKommentar", "Kommentar", "messages", "de");
t.add("n.contentPropertiesTags", "Tags", "messages", "de");
t.add("n.contentPropertiesGesamtBewertung", "Gesamtbewertung", "messages", "de");
t.add("n.contentPropertiesAuthor", "Autor", "messages", "de");
t.add("n.contentPropertiesLand", "Land", "messages", "de");
t.add("n.contentPropertiesReichweite", "Reichweite", "messages", "de");
t.add("n.contentPropertiesVerbreiteteAuflage", "Verbreitete Auflage", "messages", "de");
t.add("n.contentPropertiesUniqueUser", "Unique User", "messages", "de");
t.add("n.contentPropertiesClippingLength", "Beitragsl\u00e4nge", "messages", "de");
t.add("n.contentPropertiesAve", "A\u00c4W Gesamt", "messages", "de");
t.add("n.contentPropertiesClippingSender", "Sender", "messages", "de");
t.add("n.contentPropertiesMedientitelSender", "Medientitel \/ Sendung", "messages", "de");
t.add("n.contentPropertiesDokument", "Dokumente", "messages", "de");
t.add("n.contentPropertiesMeldung", "Meldung \/ RTV-Abstract", "messages", "de");
t.add("n.saved", "Gespeichert", "messages", "de");
t.add("n.successSendImmediately", "Versand aktiviert", "messages", "de");
t.add("n.sendImmediatelyConfirm", "Wollen Sie den Newsletter sofort ausl\u00f6sen? <br \/>Nicht gespeicherte \u00c4nderungen werden verworfen.", "messages", "de");
t.add("n.error", "Fehler", "messages", "de");
t.add("n.errorMessageValidation", "Die Daten konnten nicht gespeichert werden, bitte pr\u00fcfen Sie die Eingabefelder.", "messages", "de");
t.add("n.content", "Inhalt", "messages", "de");
t.add("n.delivery", "Lieferung", "messages", "de");
t.add("n.representation", "Darstellung", "messages", "de");
t.add("n.saveNewsletter", "Newsletter speichern", "messages", "de");
t.add("n.noNewsletterAvailable", "Es sind keine Newsletter vorhanden", "messages", "de");
t.add("n.add", "Hinzuf\u00fcgen", "messages", "de");
t.add("n.newsletterHtmlSimpleTitle", "Newsletter", "messages", "de");
t.add("n.newsletterHtmlSimpleFooterProfile", "Medienbeobachtung", "messages", "de");
t.add("n.newsletterHtmlSimpleFooterAddress", "<b>ARGUS DATA INSIGHTS\u00ae Deutschland GmbH<\/b><br \/>Gneisenaustra\u00dfe 66 | 10961 Berlin - Germany | +49 30 203987 0", "messages", "de");
t.add("n.newsletterHtmlExtendedFooterProfile", "Medienbeobachtung", "messages", "de");
t.add("n.newsletterHtmlExtendedFooterAddress", "ARGUS DATA INSIGHTS\u00ae Deutschland GmbH, Gneisenaustra\u00dfe 66, 10961 Berlin - Germany,  +49 30 203987 0", "messages", "de");
t.add("n.newsletterPdfClippings", "Clippings", "messages", "de");
t.add("n.newsletterPdfFilename", "ARGUS DATA INSIGHTS Newsletter.pdf", "messages", "de");
t.add("n.mailSubject", "ARGUS DATA INSIGHTS Newsletter", "messages", "de");
t.add("n.mailPersonalAddress", "Sehr geehrte Damen und Herren,", "messages", "de");
t.add("n.mailCoverLetter", "Sie erhalten den von Ihnen abonnierten Newsletter:", "messages", "de");
t.add("n.salutation_1", "Mit freundlichen Gr\u00fc\u00dfen,", "messages", "de");
t.add("n.salutation_2", "Ihr ARGUS DATA INSIGHTS Team", "messages", "de");
t.add("n.from", "von", "messages", "de");
t.add("n.newsletterDelete", "Newsletter l\u00f6schen", "messages", "de");
t.add("n.newsletterDeleteText", "M\u00f6chten sie den Newsletter wirklich l\u00f6schen?", "messages", "de");
t.add("n.requiredField", "Ben\u00f6tigtes Feld", "messages", "de");
t.add("n.requiredChoiceTooltip", "Dieses Feld wird f\u00fcr die korrekte Darstellung des Newsletters ben\u00f6tigt", "messages", "de");
t.add("n.emailKonfigForFooter", "Signatur verwenden aus", "messages", "de");
t.add("n.quelle", "Quelle", "messages", "de");
t.add("n.errNameAlreadyUsed", "Name bereits in Benutzung", "messages", "de");
t.add("n.copy", "Kopieren", "messages", "de");
t.add("n.delete", "L\u00f6schen", "messages", "de");
t.add("n.firstDayOfMonth", "1. Tag des Monats", "messages", "de");
t.add("n.middleOfMonth", "15. Tag des Monats", "messages", "de");
t.add("n.errorMailTitle", "Fehler im NewsletterService", "messages", "de");
t.add("n.errorMailContent", "Folgende Newsletter konnten aufgrund eines Fehlers nicht generiert werden. Dies ist unbedingt zu pr\u00fcfen! F\u00fcr diese Newsletter erfolgt keine weitere Benachrichtigung.", "messages", "de");
t.add("n.last_attempt", "letzter Versuch (nicht verschickt)", "messages", "de");
t.add("n.last_sent", "zuletzt verschickt", "messages", "de");
t.add("n.not_sent_yet", "Newsletter wurde noch nicht verschickt", "messages", "de");
t.add("n.article", "Artikel", "messages", "de");
t.add("n.emptyShippingMessage", "Seit dem letzten Versand wurden keine neuen f\u00fcr Sie relevanten Artikel oder Beitr\u00e4ge identifiziert.", "messages", "de");
t.add("n.emptyShippingLabel", "Info-Mail f\u00fcr Leerversand", "messages", "de");
t.add("n.sendAsAlert", "Alert", "messages", "de");
t.add("exp.headerartikel", "Artikel", "messages", "de");
t.add("exp.headermkz", "Kennzahlen", "messages", "de");
t.add("exp.headermedium", "Medium", "messages", "de");
t.add("exp.headerthema", "Analyse", "messages", "de");
t.add("exp.headersonstige", "Sonstige", "messages", "de");
t.add("exp.artikel", "Artikeldaten", "messages", "de");
t.add("exp.mkz", "Kennzahlen", "messages", "de");
t.add("exp.medium", "Mediendaten", "messages", "de");
t.add("exp.thema", "Analysedaten", "messages", "de");
t.add("exp.sonstige", "Sonstige Daten", "messages", "de");
t.add("exp.openFieldDetails", "Klicken um Felder anzuzeigen", "messages", "de");
t.add("exp.hlChooseExportType", "Exporttyp w\u00e4hlen", "messages", "de");
t.add("exp.hlChooseExportFormat", "Dateiformat w\u00e4hlen", "messages", "de");
t.add("exp.hlExportConfig", "Export-Konfiguration", "messages", "de");
t.add("exp.hlChooseExportConfig", "Export-Konfiguration w\u00e4hlen", "messages", "de");
t.add("exp.hlNameExportConfig", "Unter neuem Namen speichern", "messages", "de");
t.add("exp.groupList", "Datengruppen", "messages", "de");
t.add("exp.configForAll", "Soll die Konfiguration f\u00fcr alle nutzbar sein?", "messages", "de");
t.add("exp.errorNoGroupSelected", "Bitte mindestens eine Datengruppe w\u00e4hlen.", "messages", "de");
t.add("exp.pleaseChooseConfig", "Bitte w\u00e4hlen Sie eine Export-Konfiguration oder geben Sie einen Namen f\u00fcr eine Neue ein.", "messages", "de");
t.add("exp.exportTypeArtikel", "artikelbezogen", "messages", "de");
t.add("exp.exportTypeThema", "themenbezogen", "messages", "de");
t.add("exp.defaultExportConfig", "Standard Export", "messages", "de");
t.add("exp.lastExportConfig", "Letzter Export", "messages", "de");
t.add("exp.noPremiumHint1", "Kein erweiterter Daten-Export m\u00f6glich, da kein Auftrag der ausgew\u00e4hlten Treffer das Recht enth\u00e4lt.", "messages", "de");
t.add("exp.noPremiumHint2", "Kein erweiterter Daten-Export m\u00f6glich, da ein oder mehrere Auftr\u00e4ge der ausgew\u00e4hlten Treffer das Recht nicht enthalten.", "messages", "de");
t.add("exp.enterExportTemplateTitle", "Aktuelle Auswahl als Vorlage abspeichern", "messages", "de");
t.add("exp.saveExport", "Speichern", "messages", "de");
t.add("exp.errorEmptyTemplateName", "Vorlagename kann nicht leer sein", "messages", "de");
t.add("ntf.formBlockDe", "Deutsch", "messages", "de");
t.add("ntf.formBlockEn", "Englisch", "messages", "de");
t.add("ntf.headline", "\u00dcberschrift", "messages", "de");
t.add("ntf.content", "Inhalt", "messages", "de");
t.add("ntf.notificationType", "Benachrichtigungstyp", "messages", "de");
t.add("ntf.portalKundeGroups", "Kundengruppe(n)", "messages", "de");
t.add("ntf.additionalData", "Zus\u00e4tzliche Angaben (optional)", "messages", "de");
t.add("ntf.validFrom", "G\u00fcltig ab", "messages", "de");
t.add("ntf.validUntil", "G\u00fcltig bis", "messages", "de");
t.add("ntf.created", "Erstellt", "messages", "de");
t.add("ntf.readCount", "%counter% mal gelesen", "messages", "de");
t.add("ntf.isCrucial", "Nach Login anzeigen", "messages", "de");
t.add("ntf.useGermanContentForEnglish", "\u00dcberschrift und Inhalt f\u00fcr Englisch \u00fcbernehmen", "messages", "de");
t.add("ntf.preview", "Vorschau", "messages", "de");
t.add("ntf.active", "Aktiv", "messages", "de");
t.add("ntf.availability", "G\u00fcltigkeit", "messages", "de");
t.add("ntf.showBigPicture", "Klicken f\u00fcr gro\u00dfes Bild", "messages", "de");
t.add("ntf.mobileClickToShow", "Klicken um Bild zu \u00f6ffnen", "messages", "de");
t.add("registration.confirmed", "Gl\u00fcckwunsch \"%username%\", das Benutzerkonto ist jetzt best\u00e4tigt.", "messages", "de");
t.add("newsletter2go.registration.success", "Die Newsletter-Registrierung war erfolgreich.", "messages", "de");
t.add("newsletter2go.registration.error.part1", "Leider war ihre Newsletter-Registrierung nicht erfolgreich. Bitte abonnieren Sie den Newsletter", "messages", "de");
t.add("newsletter2go.registration.error.part2", "hier", "messages", "de");
t.add("newsletter2go.registration.error.emailDuplicate", "Sie haben bereit f\u00fcr Newsletter registriert", "messages", "de");
t.add("newsletter2go.registration.error.emailInvalid", "Ihre Email ist nicht valid", "messages", "de");
t.add("newsletter2go.registration.error.placeholder", "Registrierung nicht erfolgreich", "messages", "de");
t.add("ROLE_ADMIN", "Symfony-Admin", "messages", "de");
t.add("ROLE_SUPER_ADMIN", "Super-Admin", "messages", "de");
t.add("ROLE_MRA_ADMIN", "MRA-Konfigurations-Admin", "messages", "de");
t.add("ROLE_NEWS_ADMIN", "ARGUS Portal News Admin", "messages", "de");
t.add("ROLE_COMMENT", "Clippings kommentieren", "messages", "de");
t.add("ROLE_A_COMMENT_GLOBAL", "globale Kommentare setzen", "messages", "de");
t.add("ROLE_ADD_FOLDER", "Ordner benutzen", "messages", "de");
t.add("ROLE_ADD_GLOBAL_FOLDER", "globale Tags und Ordner anlegen", "messages", "de");
t.add("ROLE_TAGS", "Taggen von Artikeln und Medienspiegeln", "messages", "de");
t.add("ROLE_A_TONALITY", "Tonalit\u00e4t \u00e4ndern", "messages", "de");
t.add("ROLE_A_AAEW", "A\u00c4W \u00e4ndern", "messages", "de");
t.add("ROLE_A_NO_KACHELDESIGN", "Medienspiegel Magazin-Sicht aktiv", "messages", "de");
t.add("ROLE_A_RUBRIC", "Eigene Rubriken", "messages", "de");
t.add("ROLE_A_RECYCLE_REVIEWS", "Medienspiegel wiederverwenden", "messages", "de");
t.add("ROLE_A_EDIT_CONTENT", "Headline und Content \u00e4ndern", "messages", "de");
t.add("ROLE_A_ORDER_RECORDING", "Bestellen (Mitschnitte)", "messages", "de");
t.add("ROLE_A_ORDER_DIG", "Bestellen (Dig)", "messages", "de");
t.add("ROLE_A_FEED", "Feed anlegen", "messages", "de");
t.add("ROLE_FEED_SORT", "Feed sortieren", "messages", "de");
t.add("ROLE_A_ALERT", "Newsletter anlegen", "messages", "de");
t.add("ROLE_EXPORT", "Premium-Export (Datenauswahl)", "messages", "de");
t.add("ROLE_EXPORT_MAIL", "E-Mail-Versand ausgew\u00e4hlter Clippings", "messages", "de");
t.add("ROLE_A_PSP_CREATE", "Medienspiegel erstellen (Standard)", "messages", "de");
t.add("ROLE_A_PSP_ACTIVE", "Medienspiegel Abo aktiv", "messages", "de");
t.add("ROLE_A_PSP_CMS", "Medienspiegel-Redaktionssystem", "messages", "de");
t.add("ROLE_A_DOCUMENT_PMG", "Ansicht lizensierter Daten", "messages", "de");
t.add("ROLE_A_DOCUMENT_NON_PMG", "Ansicht lizensierter Daten (au\u00dfer PMG)", "messages", "de");
t.add("ROLE_A_DOCUMENT_OPEN", "Dokumentlinks ohne Login-Pr\u00fcfung", "messages", "de");
t.add("ROLE_DOCUMENT_ARCHIVE_VIEW", "Digitales Belegexemplar", "messages", "de");
t.add("ROLE_UPLOAD", "Clippings bearbeiten\/hochladen", "messages", "de");
t.add("ROLE_HIDE_TREFFER", "Treffer ausblenden", "messages", "de");
t.add("ROLE_A_APPROVAL_ARTICLE", "Freigabe-Redakteur f\u00fcr Clippings", "messages", "de");
t.add("ROLE_A_APPROVAL_PSP", "Freigabe-Redakteur f\u00fcr Medienspiegel", "messages", "de");
t.add("ROLE_A_APPROVAL_VIEW", "Ansicht nicht-freigegebener Clippings und Medienspiegel", "messages", "de");
t.add("ROLE_ADMINISTRATOR", "Administrator", "messages", "de");
t.add("ROLE_LAYOUT_LOGO", "Kunden-Logo einbinden", "messages", "de");
t.add("ROLE_LAYOUT", "Layout, Farbgestaltung", "messages", "de");
t.add("ROLE_EMAIL_MANAGE", "E-Mail-Liste verwalten", "messages", "de");
t.add("ROLE_A_EDIT_FIELDS", "Felder ein-\/ausblenden", "messages", "de");
t.add("ROLE_FILTER_CONTRACT", "Auftragsfilter", "messages", "de");
t.add("ROLE_EMAIL_ACCESS", "Zugriff auf E-Mail\/-Listen", "messages", "de");
t.add("ROLE_A_DASHBOARD_VIEW", "Dashboard Zugriff", "messages", "de");
t.add("ROLE_A_DASHBOARD_ADMIN", "Dashboard-Admin", "messages", "de");
t.add("ROLE_A_DB", "Dashboard Premium Ansicht", "messages", "de");
t.add("ROLE_A_DB_VIEWER", "Dashboard Premium Ansicht (schreibgesch\u00fctzt)", "messages", "de");
t.add("ROLE_A_DB_IFRAME", "Dashboard iFrame Widget", "messages", "de");
t.add("ROLE_A_DB_TEMPLATE", "Dashboard Templates", "messages", "de");
t.add("ROLE_A_TEASER", "Teaser-Admin", "messages", "de");
t.add("ROLE_DMA_ADMIN", "Administrator (Sales)", "messages", "de");
t.add("ROLE_A_REPOSITORY_PERIOD", "individueller Speicherzeitraum", "messages", "de");
t.add("ROLE_MASK", "Daten ausblenden", "messages", "de");
t.add("ROLE_EDIT_SUMMARY", "Summaries \u00e4ndern", "messages", "de");
t.add("ROLE_A_MRA", "Analyse-Dokumente ansehen", "messages", "de");
t.add("ROLE_A_MRA_EDIT", "Analyse-Daten bearbeiten", "messages", "de");
t.add("ROLE_A_PS", "Press Summaries ansehen", "messages", "de");
t.add("ROLE_A_PL", "Reports ansehen", "messages", "de");
t.add("ROLE_A_ALI", "Konfiguration des Echtzeit-Monitorings", "messages", "de");
t.add("ROLE_FEED_EDITOR_ARGUS", "Feed-Redakteur ARGUS (ext. Inhalte)", "messages", "de");
t.add("ROLE_FEED_EDITOR", "Feed-Redakteur (ext. Inhalte)", "messages", "de");
t.add("ROLE_FEED_READER", "Feed-Betrachter (ext. Inhalte)", "messages", "de");
t.add("ROLE_EMBEDDING_FEATURES", "Embedding features", "messages", "de");
t.add("ROLE_TALKWALKER_SSO", "Talkwalker SSO", "messages", "de");
t.add("ROLE_A_MS_TEAMS", "MS Teams integration", "messages", "de");
t.add("ROLE_A_FULL_VIDEO", "View full video (Spot)", "messages", "de");
t.add("l.cl_clippings", "Clippings", "messages", "de");
t.add("l.cl_clippings_tiles", "Clippings (Magazin)", "messages", "de");
t.add("l.db_load", "Dashboard", "messages", "de");
t.add("l.product_reports", "Reports", "messages", "de");
t.add("l.product_summaries", "Press Summaries", "messages", "de");
t.add("l.pressespiegelListManage", "Medienspiegel", "messages", "de");
t.add("l.psp_current", "Aktueller Medienspiegel (Magazin)", "messages", "de");
t.add("l.msp_current_html", "Aktueller Html-Medienspiegel (ARGUS-Admin)", "messages", "de");
t.add("l.msp_current_pdf", "Aktueller PDF-Medienspiegel (ARGUS-Admin)", "messages", "de");
t.add("l.work_full_page", "Work", "messages", "de");
t.add("l.customer_admin", "Nutzerverwaltung", "messages", "de");
t.add("l.ext_newsboard", "Newsboard", "messages", "de");
t.add("l.ext_media_review_archive", "Medienspiegel Archiv", "messages", "de");
t.add("db.title", "Titel", "messages", "de");
t.add("db.defaultTitle", "Standard", "messages", "de");
t.add("db.titleTypeTemplate", "Standard-Titel", "messages", "de");
t.add("db.titleTypeDefault", "Standard-Titel", "messages", "de");
t.add("db.titleTypeCustom", "Eigener Titel", "messages", "de");
t.add("db.titleTypeNone", "Kein Titel", "messages", "de");
t.add("db.subtitle", "Untertitel", "messages", "de");
t.add("db.template", "Vorlage", "messages", "de");
t.add("db.type", "Diagrammart", "messages", "de");
t.add("db.noTemplate", "keine", "messages", "de");
t.add("db.choose", "Bitte ausw\u00e4hlen...", "messages", "de");
t.add("db.all", "Alle", "messages", "de");
t.add("db.allOrders", "Alle Auftr\u00e4ge", "messages", "de");
t.add("db.benchmark", "Benchmark definieren", "messages", "de");
t.add("db.benchmarkBez", "Bezeichnung", "messages", "de");
t.add("db.benchmarkFrom", "von", "messages", "de");
t.add("db.benchmarkTo", "bis", "messages", "de");
t.add("db.groupMisc", "Sonstige gruppieren", "messages", "de");
t.add("db.groupMiscValue", "Wert kleiner als", "messages", "de");
t.add("db.threshold", "Schwellenwert definieren", "messages", "de");
t.add("db.thresholdFrom", "von", "messages", "de");
t.add("db.thresholdTo", "bis", "messages", "de");
t.add("db.topsources", "Anzahl der Ergebnisse definieren", "messages", "de");
t.add("db.topsourcesFrom", "maximal", "messages", "de");
t.add("db.ownWidget", "Eigenes Widget definieren", "messages", "de");
t.add("db.splined", "gegl\u00e4ttet", "messages", "de");
t.add("db.stacked", "gestapelt", "messages", "de");
t.add("db.partitioned", "nach Medienart getrennt", "messages", "de");
t.add("db.subeditionsExcluded", "Unterausgaben ausschlie\u00dfen", "messages", "de");
t.add("db.inverted", "invertiert", "messages", "de");
t.add("db.medienart", "Medienart", "messages", "de");
t.add("db.xaxis", "x-Achse", "messages", "de");
t.add("db.yaxis", "y-Achse", "messages", "de");
t.add("db.level", "Level ausw\u00e4hlen", "messages", "de");
t.add("db.timeGroup", "Zeitachse gruppieren", "messages", "de");
t.add("db.timeGroup0", "keine Gruppierung", "messages", "de");
t.add("db.timeGroup1", "nach Tagen", "messages", "de");
t.add("db.timeGroup2", "nach Monaten", "messages", "de");
t.add("db.timeGroup3", "nach Jahren", "messages", "de");
t.add("db.timeGroup4", "nach Wochen", "messages", "de");
t.add("db.calendarWeek", "KW", "messages", "de");
t.add("db.kennzahlen", "Reichweiten", "messages", "de");
t.add("db.kennzahlenInTsd", "in Tsd.", "messages", "de");
t.add("db.kennzahlenTsd", "Tsd.", "messages", "de");
t.add("db.aaewInTsd", "in Tsd. \u20ac", "messages", "de");
t.add("db.aaewTsd", "Tsd. \u20ac", "messages", "de");
t.add("db.euroSign", "\u20ac", "messages", "de");
t.add("db.tsd", "Tsd.", "messages", "de");
t.add("db.mio", "Mio.", "messages", "de");
t.add("db.mrd", "Mrd.", "messages", "de");
t.add("db.size", "Gr\u00f6\u00dfe", "messages", "de");
t.add("db.country", "L\u00e4nder", "messages", "de");
t.add("db.germany", "Deutschland", "messages", "de");
t.add("db.dach", "DACH", "messages", "de");
t.add("db.europe", "Europa", "messages", "de");
t.add("db.world", "Welt", "messages", "de");
t.add("db.tagcloudTooltip", "H\u00e4ufigkeit Wortvorkommen", "messages", "de");
t.add("db.defaultTemplate", "Default", "messages", "de");
t.add("db.lineTemplate", "Liniendiagramm", "messages", "de");
t.add("db.areaTemplate", "Fl\u00e4chendiagramm", "messages", "de");
t.add("db.barTemplate", "Balkendiagramm horizontal", "messages", "de");
t.add("db.columnTemplate", "Balkendiagramm vertikal", "messages", "de");
t.add("db.pieTemplate", "Tortendiagramm", "messages", "de");
t.add("db.donutTemplate", "Donutdiagramm", "messages", "de");
t.add("db.listTemplate", "Top 10 Liste", "messages", "de");
t.add("db.tagcloudTemplate", "Tagcloud", "messages", "de");
t.add("db.countriesTemplate", "L\u00e4nder", "messages", "de");
t.add("db.topSourcesTemplate", "Top-Quellen", "messages", "de");
t.add("db.lastHeadlinesTemplate", "Letzte Headlines", "messages", "de");
t.add("db.averageTonalityTemplate", "Durchschnittsbewertung", "messages", "de");
t.add("db.zeitMedienArtTemplate", "Medienart im Zeitverlauf", "messages", "de");
t.add("db.anzahlMedienArtTemplate", "Anzahl Clippings nach Medienart", "messages", "de");
t.add("db.verteilungMedienArtTemplate", "Verteilung Clippings nach Medienart", "messages", "de");
t.add("db.iframeTemplate", "IFrame", "messages", "de");
t.add("db.imgTemplate", "IFrame-Alternative externes Bild", "messages", "de");
t.add("db.bewertungsart", "Bewertungsart", "messages", "de");
t.add("db.stopwords", "W\u00f6rter ausschlie\u00dfen (durch Leerzeichen trennen)", "messages", "de");
t.add("db.titleStandardEzmDb", "Echtzeitmonitoring", "messages", "de");
t.add("db.branche", "Branche", "messages", "de");
t.add("db.bundesland", "Bundesland", "messages", "de");
t.add("db.erscheinungsweise", "Erscheinungsweise", "messages", "de");
t.add("db.expressRubrik", "Rubrik", "messages", "de");
t.add("db.international", "International", "messages", "de");
t.add("db.kategorie", "Kategorie", "messages", "de");
t.add("db.landescode", "Landescode", "messages", "de");
t.add("db.medienArt", "Medienart", "messages", "de");
t.add("db.medienTyp", "Medientyp", "messages", "de");
t.add("db.medium", "Medium", "messages", "de");
t.add("db.nielsen", "Nielsen", "messages", "de");
t.add("db.sprache", "Sprache", "messages", "de");
t.add("db.sender", "Sender", "messages", "de");
t.add("db.alisuchprofil", "Suchagent", "messages", "de");
t.add("db.feedSource", "Feed-Quelle", "messages", "de");
t.add("db.tonalitaet", "Tonalit\u00e4t", "messages", "de");
t.add("db.autor", "Autor", "messages", "de");
t.add("db.aaew_gesamt", "A\u00c4W Gesamt", "messages", "de");
t.add("db.aaew_relevant", "A\u00c4W Relevant", "messages", "de");
t.add("db.aaew", "A\u00c4W", "messages", "de");
t.add("db.medienrubrik", "Medienrubrik", "messages", "de");
t.add("db.zeit", "Zeit", "messages", "de");
t.add("db.mkzQuelle", "Kennzahlquelle", "messages", "de");
t.add("db.mkzTyp", "Kennzahltyp", "messages", "de");
t.add("db.mraAufmerksamkeit", "Aufmerksamkeit", "messages", "de");
t.add("db.mraDarstellungsform", "Darstellungsform", "messages", "de");
t.add("db.mraUrheber", "Urheber", "messages", "de");
t.add("db.tags", "Tags", "messages", "de");
t.add("db.ordner", "Ordner", "messages", "de");
t.add("db.suchbegriff", "Suchbegriff", "messages", "de");
t.add("db.thema", "Thema", "messages", "de");
t.add("db.mraTop", "MRA Zusatz1", "messages", "de");
t.add("db.mraText1", "MRA Zusatz2", "messages", "de");
t.add("db.mraText2", "MRA Zusatz3", "messages", "de");
t.add("db.sonstige", "Sonstige", "messages", "de");
t.add("db.chooseMedienTyp", "Medientypen ausw\u00e4hlen", "messages", "de");
t.add("db.chooseThema", "Thema ausw\u00e4hlen", "messages", "de");
t.add("db.chooseTemplate", "Vorlage ausw\u00e4hlen", "messages", "de");
t.add("db.anzahl", "Anzahl", "messages", "de");
t.add("db.bewertung", "Anzahl Bewertung", "messages", "de");
t.add("db.meldungen", "Anzahl Meldungen", "messages", "de");
t.add("db.nennungen", "Anzahl Nennungen", "messages", "de");
t.add("db.reichweiten", "Reichweiten", "messages", "de");
t.add("db.averageBewertung", "Durchschnittsbewertung", "messages", "de");
t.add("db.optionsDb", "Men\u00fc", "messages", "de");
t.add("db.addDb", "Neues Dashboard anlegen", "messages", "de");
t.add("db.reloadDb", "Aktualisieren", "messages", "de");
t.add("db.editDb", "Dashboard bearbeiten", "messages", "de");
t.add("db.cloneDb", "Dashboard duplizieren", "messages", "de");
t.add("db.addWidget", "Neues Widget anlegen", "messages", "de");
t.add("db.resortDbWidgets", "Widgets umsortieren", "messages", "de");
t.add("db.resortDbTabs", "Dashboards umsortieren", "messages", "de");
t.add("db.stopResortDb", "Speichern", "messages", "de");
t.add("db.exportDb", "Dashboard exportieren", "messages", "de");
t.add("db.deleteDb", "Dashboard entfernen", "messages", "de");
t.add("db.addDbHeader", "Dashboard Einstellungen", "messages", "de");
t.add("db.addDbTitle", "Titel", "messages", "de");
t.add("db.addDbReference", "Datenbasis", "messages", "de");
t.add("db.addDbVisible", "Sichtbarkeit", "messages", "de");
t.add("db.addDbAuftrag", "Auftrag", "messages", "de");
t.add("db.addDbSuchfilter", "Filter", "messages", "de");
t.add("db.addDbNewFilter", "Neuen Filter mit aktuellen Sucheinstellungen speichern", "messages", "de");
t.add("db.addDbTemplate", "Vorlage", "messages", "de");
t.add("db.addDbVisibleCheckbox", "F\u00fcr alle sichtbar", "messages", "de");
t.add("db.addDbVisibleCheckboxHint", "Das Dashboard ist f\u00fcr andere Nutzer sichtbar, wenn <br\/> a) der zugrundeliegende Filter \u00fcber das Filtermen\u00fc auf \"global\" gesetzt ist und <br\/> b) der zugrundeliegende Auftrag f\u00fcr die Nutzer freigeschaltet ist.<br\/>\n", "messages", "de");
t.add("db.resizeWidget", "Gr\u00f6\u00dfe \u00e4ndern", "messages", "de");
t.add("db.resetZoomWidget", "Zoom zur\u00fccksetzen", "messages", "de");
t.add("db.togglePointMarkersWidget", "Datenpunkte an\/aus", "messages", "de");
t.add("db.toggleDataLabelsWidget", "Datenbeschriftung an\/aus", "messages", "de");
t.add("db.toggleLegendWidget", "Legende an\/aus", "messages", "de");
t.add("db.changeTypeWidget", "Typ \u00e4ndern", "messages", "de");
t.add("db.editWidget", "Widget bearbeiten", "messages", "de");
t.add("db.cloneWidget", "Widget duplizieren", "messages", "de");
t.add("db.reloadWidget", "Aktualisieren", "messages", "de");
t.add("db.exportWidget", "Widget exportieren", "messages", "de");
t.add("db.exportWidgetEmbed", "In anderem Platform einbetten", "messages", "de");
t.add("db.deleteWidget", "Widget l\u00f6schen", "messages", "de");
t.add("db.optionsWidget", "Optionen", "messages", "de");
t.add("db.noWidgetContent", "Ihre Abfrage liefert leider kein Ergebnis.", "messages", "de");
t.add("db.tooMuchResults", "Ihre Abfrage liefert f\u00fcr eine saubere Darstellung leider zu viele Ergebnisse.", "messages", "de");
t.add("db.loadingInterrupted", "Das Laden der Daten wurde unterbrochen, bitte erneut laden!", "messages", "de");
t.add("db.errorLoadingTab", "Es ist ein Fehler aufgetreten.", "messages", "de");
t.add("db.errorLoadingWidget", "Es ist ein Fehler aufgetreten.", "messages", "de");
t.add("db.errorLoadingWidgetNoPermission", "Keine Berechtigung", "messages", "de");
t.add("db.loadingWidget", "Ergebnis wird geladen...", "messages", "de");
t.add("db.errorLoadingDashboardNoPermission", "Keine Berechtigung", "messages", "de");
t.add("db.emptyDataName", "ohne Angaben", "messages", "de");
t.add("db.dbDelete", "Dashboard l\u00f6schen", "messages", "de");
t.add("db.modalDelete", "Widget l\u00f6schen", "messages", "de");
t.add("db.dbDeleteText", "Soll das Dashboard wirklich gel\u00f6scht werden?", "messages", "de");
t.add("db.modalDeleteText", "Soll das Widget wirklich gel\u00f6scht werden?", "messages", "de");
t.add("db.size0", "sehr klein", "messages", "de");
t.add("db.size1", "klein", "messages", "de");
t.add("db.size2", "mittel", "messages", "de");
t.add("db.size3", "gro\u00df", "messages", "de");
t.add("db.valDbTitle", "Titel darf nicht leer sein", "messages", "de");
t.add("db.valDbLetters", "Erlaubte Zeichen: Buchstaben, Ziffern, Leerzeichen, +, -", "messages", "de");
t.add("db.valDbLettersNumbersSigns", "Erlaubte Zeichen: Buchstaben, Ziffern, Sonderzeichen", "messages", "de");
t.add("db.valDbLettersBench", "Erlaubte Zeichen: Ziffern", "messages", "de");
t.add("db.valAuftrag", "Auftrag darf nicht leer sein", "messages", "de");
t.add("db.valSuchfilter", "Suchfilter darf nicht leer sein", "messages", "de");
t.add("db.valNewFilter", "neuer Filtername darf nicht leer sein", "messages", "de");
t.add("db.valDbTypeTemplate", "Bitte eine Vorlage ausw\u00e4hlen oder eine eigene definieren", "messages", "de");
t.add("db.valDbTemplate", "Vorlage ausw\u00e4hlen", "messages", "de");
t.add("db.valDbType", "Diagrammart festlegen", "messages", "de");
t.add("db.valDbReference", "Bezug definieren", "messages", "de");
t.add("db.valDbTimeGroup", "Gruppierung der Zeitachse festlegen", "messages", "de");
t.add("db.valDbGroupMisc", "Wert zum Gruppieren festlegen", "messages", "de");
t.add("db.valDbNoMkz", "Kennzahlen ausw\u00e4hlen", "messages", "de");
t.add("db.valDbNoTonalitaet", "Tonalit\u00e4ten ausw\u00e4hlen", "messages", "de");
t.add("db.valDbChooseLevel", "Level ausw\u00e4hlen", "messages", "de");
t.add("db.art", "Art", "messages", "de");
t.add("db.results", "Ergebnisse", "messages", "de");
t.add("db.totalResults", "Gesamt", "messages", "de");
t.add("db.exportEmbedHtml", "Teilen", "messages", "de");
t.add("db.updateInverval", "Update-Intervall", "messages", "de");
t.add("db.validUntil", "G\u00fcltig bis", "messages", "de");
t.add("db.link", "Link", "messages", "de");
t.add("db.exportEmbedEditHtml", "Embedding Links Konfiguration Bearbeiten", "messages", "de");
t.add("db.accessToken", "Access Token", "messages", "de");
t.add("db.defaultHeadlineForTemplateTagcloud", "Tagcloud", "messages", "de");
t.add("db.defaultHeadlineForTemplateCountries", "Geografische Verteilung", "messages", "de");
t.add("db.defaultHeadlineForTemplateTopSources", "Top-Quellen", "messages", "de");
t.add("db.defaultHeadlineForTemplateLastHeadlines", "Letzte Headlines", "messages", "de");
t.add("db.defaultHeadlineForTemplateZeitMart", "Medienart im Zeitverlauf", "messages", "de");
t.add("db.defaultHeadlineForTemplateAvgTonalitaet", "Tonalit\u00e4t", "messages", "de");
t.add("db.defaultHeadlineForTemplateVerteilungMart", "Anteil Clippings nach Medienarten", "messages", "de");
t.add("db.defaultHeadlineForTemplateAnzahlMart", "Anzahl der Clippings nach Medienarten im Zeitverlauf", "messages", "de");
t.add("db.defaultHeadlineForTemplateIframe", "Externe Daten", "messages", "de");
t.add("db.defaultHeadlineForTemplateImg", "Externes Bild", "messages", "de");
t.add("p.savePSP", "Als Entwurf Speichern", "messages", "de");
t.add("p.savePSPAndGenerate", "Erstellen", "messages", "de");
t.add("p.pspEdit", "Medienspiegel bearbeiten", "messages", "de");
t.add("p.selectFormat", "Format(e) w\u00e4hlen", "messages", "de");
t.add("p.selectConfig", "Konfiguration w\u00e4hlen", "messages", "de");
t.add("p.selectSelectionOrder", "Reihenfolge der Rubriken w\u00e4hlen", "messages", "de");
t.add("p.pleaseChooseConfig", "Bitte Konfiguration ausw\u00e4hlen", "messages", "de");
t.add("p.loadingConfigs", "L\u00e4dt Konfiguration(en)", "messages", "de");
t.add("p.loadingSectionOrders", "L\u00e4dt Rubrikenreihenfolgen", "messages", "de");
t.add("p.pressReviews", "Medienspiegel", "messages", "de");
t.add("p.pspziptitle", "Medienspiegel", "messages", "de");
t.add("p.createPSP", "Medienspiegel erstellen", "messages", "de");
t.add("p.managementSummary", "Management Summary", "messages", "de");
t.add("p.enterManagementSummary", "Management Summary eingeben", "messages", "de");
t.add("p.enterPspTitle", "Titel des Medienspiegels eingeben", "messages", "de");
t.add("p.pspVisibleAll", "Medienspiegel f\u00fcr alle Nutzer sichtbar?", "messages", "de");
t.add("p.PSP", "Medienspiegel", "messages", "de");
t.add("p.pspCategories", "Rubriken", "messages", "de");
t.add("p.pspArticles", "Hauptbeitr\u00e4ge", "messages", "de");
t.add("p.pspAllArticles", "Beitr\u00e4ge", "messages", "de");
t.add("p.pspDeletedArticles", "Gel\u00f6scht", "messages", "de");
t.add("p.noCategory", "ohne Rubrik", "messages", "de");
t.add("p.addClippings", "Clipping hinzuf\u00fcgen", "messages", "de");
t.add("p.showAllSections", "Alle Rubriken anzeigen", "messages", "de");
t.add("p.hideEmptySections", "Leere Rubriken ausblenden", "messages", "de");
t.add("p.image", "Bild", "messages", "de");
t.add("p.copyPsp2Tag", "Medienspiegel mit Tag markieren", "messages", "de");
t.add("p.copyPsp2Folder", "Medienspiegel in Ordner markieren", "messages", "de");
t.add("p.copyPsp2NewTag", "Medienspiegeln mit neuem Tag markieren", "messages", "de");
t.add("p.delTagFromPsp", "Tag von Medienspiegeln entfernen", "messages", "de");
t.add("p.tilesView", "Magazinansicht \u00f6ffnen", "messages", "de");
t.add("p.tilesViewClose", "Magazinansicht schlie\u00dfen", "messages", "de");
t.add("p.noteConfirm", "Hinweis best\u00e4tigen", "messages", "de");
t.add("p.mediaReviewGenerateDisabled", "Medienspiegel Generation deaktiviert von", "messages", "de");
t.add("p:mediaReviewGenerateDisabledTo", "Zu", "messages", "de");
t.add("p.mediaReviewDisabledTypeOfValueNotValid", "Der Werttyp ist ung\u00fcltig, es muss eine Zahl sein.", "messages", "de");
t.add("p.mediaReviewDisabledTimeNotValid", "Die Uhrzeit ist ung\u00fcltig.", "messages", "de");
t.add("p.mediaReviewDisabledTimeFromToNotValid", "Die \u201eVon\u201c-Zeit muss vor der \u201eBis\u201c-Zeit liegen.", "messages", "de");
t.add("p.mediaReviewDisabledTimeSaved", "Medienspiegel Zeitersparnis deaktiviert.", "messages", "de");
t.add("p.mediaReviewDisabledTimeNotSaved", "Medienspiegel deaktivierte Zeit nicht gespeichert.", "messages", "de");
t.add("p.choosePDFConfig", "PDF-Konfiguration ausw\u00e4hlen", "messages", "de");
t.add("p.chooseHTMLConfig", "HTML-Konfiguration ausw\u00e4hlen", "messages", "de");
t.add("p.done", "Fertig", "messages", "de");
t.add("p.to", "Empf\u00e4nger", "messages", "de");
t.add("p.emailSubject", "Betreff", "messages", "de");
t.add("p.emailMessage", "Nachricht", "messages", "de");
t.add("p.download", "Herunterladen", "messages", "de");
t.add("p.emailSend", "per E-Mail versenden", "messages", "de");
t.add("p.fileNotCreated", "Die Datei konnte nicht erstellt werden.", "messages", "de");
t.add("p.pspNameEmpty", "Bitte einen Namen des Medienspiegels eingeben.", "messages", "de");
t.add("p.errorNoteConfirm", "Bitte den Hinweis best\u00e4tigen.", "messages", "de");
t.add("p.errorSelectPspFormat", "Bitte ein Format w\u00e4hlen.", "messages", "de");
t.add("p.errorNoArticleSelected", "Bitte Artikel ausw\u00e4hlen.", "messages", "de");
t.add("p.errorNoConfigurationSelected", "Bitte eine Konfiguration ausw\u00e4hlen", "messages", "de");
t.add("p.chooseConfiguration", "-- Bitte Konfiguration ausw\u00e4hlen --", "messages", "de");
t.add("p.errorCouldNotLoadConfigs", "Konfiguration(en) konnten nicht geladen werden", "messages", "de");
t.add("p.isCreated", "wird erstellt...", "messages", "de");
t.add("p.isGenerated", "Dateien werden erstellt...", "messages", "de");
t.add("p.isImportingStart", "Artikel werden f\u00fcr den Medienspiegel vorbereitet...", "messages", "de");
t.add("p.isImporting", "Artikel %imported% von %total% wird verarbeitet...", "messages", "de");
t.add("p.isImportingFinished", "Alle Artikel wurden verarbeitet...", "messages", "de");
t.add("p.isImportingError", "Fehler beim Verarbeiten der Artikel", "messages", "de");
t.add("p.createdOn", "Erstellt:", "messages", "de");
t.add("p.mspSaved", "Entwurf:", "messages", "de");
t.add("p.lastModifiedOn", "Letztes Update:", "messages", "de");
t.add("p.errorOnCreating", "Fehler beim Erstellen", "messages", "de");
t.add("p.errorRedirectPsp", "Die Seite konnte nicht zu der Medienspiegel Liste weitergeleitet werden.", "messages", "de");
t.add("p.errorSavePsp", "Der Medienspiegel konnte nicht gespeichert werden.", "messages", "de");
t.add("p.errorGeneratePsp", "Der Medienspiegel konnte nicht erstellt werden.", "messages", "de");
t.add("p.selectedPspAction", "Aktionen f\u00fcr ausgew\u00e4hlte Medienspiegel", "messages", "de");
t.add("p.headerImagePostionSelect", "Position des Bildes in der Kopfzeile", "messages", "de");
t.add("p.htmlPressReview", "HTML Medienspiegel", "messages", "de");
t.add("p.recreate", "Erneut erstellen", "messages", "de");
t.add("p.validUntilArchivist", "Digitales Belegexemplar", "messages", "de");
t.add("p.validUntil", "sichtbar bis", "messages", "de");
t.add("p.validUntilEndless", "keine Einschr\u00e4nkung", "messages", "de");
t.add("p.viewPdf", "PDF \u00f6ffnen", "messages", "de");
t.add("p.viewHtml", "HTML \u00f6ffnen", "messages", "de");
t.add("p.noPsp", "Keine Medienspiegel", "messages", "de");
t.add("p.deleteConfirm", "Wollen Sie das Element wirklich l\u00f6schen?", "messages", "de");
t.add("p.errorDeleteConfig", "Die Medienspiegel Konfiguration konnte nicht gel\u00f6scht werden. Sie wird noch in den folgenden aktiven Medienspiegeln verwendet:", "messages", "de");
t.add("p.errorDeleteCoverpage", "Die Deckblatt Konfiguration \"%configName%\" konnte nicht gel\u00f6scht werden. Sie wird noch in den folgenden aktiven Konfigurationen verwendet:", "messages", "de");
t.add("p.errorDeleteToc", "Die Inhaltsverzeichnis Konfiguration konnte nicht gel\u00f6scht werden. Sie wird noch in den folgenden aktiven Konfigurationen verwendet:", "messages", "de");
t.add("p.errorDeleteUnknown", "Fehler beim L\u00f6schen der Konfiguration.", "messages", "de");
t.add("p.deleteSuccess", "L\u00f6schen von \"%configName%\" war erfolgreich", "messages", "de");
t.add("p.pspSaveSuccess", "Medienspiegel gespeichert.", "messages", "de");
t.add("p.pspGenerateSuccess", "Der Medienspiegel wird erstellt.", "messages", "de");
t.add("p.articlePage", "Artikel Detailseite", "messages", "de");
t.add("p.alertMaxClippings", "Sie haben zu viele Clippings ausgew\u00e4hlt. Das Maximum f\u00fcr einen Medienspiegel sind %maxPspClippings%. Die Clippings werden automatisch reduziert.", "messages", "de");
t.add("p.noPspSelected", "Sie haben keine Medienspiegel ausgew\u00e4hlt.", "messages", "de");
t.add("p.emailSendSuccess", "Die E-Mail wurde erfolgreich versendet.", "messages", "de");
t.add("p.emailChunkMessage", "Die E-Mail wurde in Teilen f\u00fcr jeweils %maxEmailRecipients%  Empf\u00e4nger versendet.", "messages", "de");
t.add("p.emailSendFail", "Die E-Mail konnte nicht versendet werden.", "messages", "de");
t.add("p.articlesCut", "Die Anzahl der Clippings wurde auf %articleNum% gek\u00fcrzt.", "messages", "de");
t.add("p.sortByDateModification", "Bearbeitungsdatum", "messages", "de");
t.add("p.sortByPspCreator", "Ersteller", "messages", "de");
t.add("p.sortByPspTitle", "Titel", "messages", "de");
t.add("p.sortByPspState", "Status", "messages", "de");
t.add("p.pspCreator", "Ersteller", "messages", "de");
t.add("p.pspUsedAs", "verwendet als", "messages", "de");
t.add("p.pspPrimarySource", "Hauptbeitrag", "messages", "de");
t.add("p.pspSecondarySource", "weitere Quelle", "messages", "de");
t.add("p.of", "von", "messages", "de");
t.add("p.resetPspList", "Liste aktualisieren", "messages", "de");
t.add("p.zipPsp", "Anhang archivieren (Zip - Datei)", "messages", "de");
t.add("p.showInClipList", "Clippings ansehen", "messages", "de");
t.add("p.pspDeletedSuccess", "Der Medienspiegel wurde erfolgreich gel\u00f6scht.", "messages", "de");
t.add("p.pspDeletedFail", "Der Medienspiegel konnte nicht gel\u00f6scht werden.", "messages", "de");
t.add("p.dmaRecreatePsp", "Mit Inhalten neu erstellen", "messages", "de");
t.add("p.standardEmailSubject", "Standard Betreff f\u00fcr Medienspiegel E-Mail Versand mit dieser Konfiguration angeben (optional)", "messages", "de");
t.add("p.standardEmailMessage", "Standard Nachricht f\u00fcr Medienspiegel E-Mail Versand mit dieser Konfiguration angeben (optional)", "messages", "de");
t.add("p.emailRecipientsVisible", "Alle Empf\u00e4nger sichtbar", "messages", "de");
t.add("p.emailRecipientsVisibleLimitHint", "Diese Funktion ist begrenzt auf %count% Empf\u00e4nger", "messages", "de");
t.add("p.attachement", "Anhang", "messages", "de");
t.add("p.emailKonfig", "Vorlage", "messages", "de");
t.add("p.pspEmailKonfig", "Vorlage", "messages", "de");
t.add("p.includeDateInSubject", "Das aktuelle Datum im Betreff hinzuf\u00fcgen ( ...,%currDate%)", "messages", "de");
t.add("p.selectEmail", "E-Mail ausw\u00e4hlen", "messages", "de");
t.add("p.maxDragDrop", "Die maximale Anzahl von 250 Artikeln f\u00fcr das Drag & Drop wurde \u00fcberschritten. Diese Sortierung wurde deaktiviert.", "messages", "de");
t.add("p.maxArticles", "Die maximale Anzahl von 800 Artikeln wurde erreicht und ggf. eingek\u00fcrzt.", "messages", "de");
t.add("p.pspEmailKonfigHint", "Verwendet Betreff, Text und Footereinstellungen aus der gew\u00e4hlten Vorlage", "messages", "de");
t.add("p.Autor", "Autor", "messages", "de");
t.add("p.Reichweite", "Reichweite", "messages", "de");
t.add("p.Seite", "Seite", "messages", "de");
t.add("p.Anriss", "Teaser", "messages", "de");
t.add("p.Tag", "Tag", "messages", "de");
t.add("p.Kommentar", "Kommentar", "messages", "de");
t.add("p.Gesamtbewertung", "Gesamtbewertung", "messages", "de");
t.add("p.mq", "Mehrfachverquellung", "messages", "de");
t.add("p.mqClippingListHint", "Bewegen Sie das Clipping nach rechts, um es als weitere Quelle zu setzen.", "messages", "de");
t.add("p.statusDraft", "Entwurf", "messages", "de");
t.add("p.statusError", "Fehler", "messages", "de");
t.add("p.sectionOrder", "Reihenfolge", "messages", "de");
t.add("p.alphabeticalOrder", "alphabetische Reihenfolge", "messages", "de");
t.add("p.individualOrder", "individuelle Reihenfolge", "messages", "de");
t.add("p.individual", "Individuell", "messages", "de");
t.add("p.bySubscription", "nach Abo", "messages", "de");
t.add("p.msgLoadConfigFailed", "Konfiguration(en) konnten nicht geladen werden", "messages", "de");
t.add("p.msgLoadSectionOrderFailed", "Rubrikenreihenfolgen konnten nicht geladen werden", "messages", "de");
t.add("p.config", "Konfiguration", "messages", "de");
t.add("p.deleteConfirmObject", "Wollen sie wirklich %delObject% l\u00f6schen?", "messages", "de");
t.add("p.deleteObject", "%delObject% l\u00f6schen", "messages", "de");
t.add("p.insertAfterSection", "ausgew\u00e4hlte Rubriken nach dieser einf\u00fcgen", "messages", "de");
t.add("p.insertAfterArticle", "nach dem Clipping einf\u00fcgen", "messages", "de");
t.add("p.insertAfterSubarticle", "nach der Quelle einf\u00fcgen", "messages", "de");
t.add("p.insertAsSectionArticle", "zur Rubrik hinzuf\u00fcgen", "messages", "de");
t.add("p.insertAsSubarticle", "als Quelle hinzuf\u00fcgen", "messages", "de");
t.add("p.insertBeforeSection", "Rubrik hier einf\u00fcgen", "messages", "de");
t.add("p.toggleObjectList", "%object% auf\/zuklappen", "messages", "de");
t.add("p.expandObjectList", "%object% aufklappen", "messages", "de");
t.add("p.collapseObjectList", "%object% zuklappen", "messages", "de");
t.add("p.toggleSelectSection", "zum Verschieben aus-\/abw\u00e4hlen", "messages", "de");
t.add("p.setSubarticleState", "{2} nicht aktivierbare Quelle | {1} Quelle auf passiv setzen | {0} Quelle auf aktiv setzen", "messages", "de");
t.add("p.functionIsDisabled", "Diese Funktion ist f\u00fcr die aktuelle Auswahl deaktiviert.", "messages", "de");
t.add("p.insertIntoAssignedCategories", "in definierte Rubrik \u00fcbernehmen", "messages", "de");
t.add("p.insertIntoAssignedCategoriesFullText", "die ausgew\u00e4hlten Clippings automatisch in die jeweils daf\u00fcr definierten Rubriken \u00fcbernehmen (\"ohne Rubrik\", falls nicht definiert)", "messages", "de");
t.add("p.alreadyContainsClippings", "Folgende Clippings sind bereits enthalten", "messages", "de");
t.add("p.section", "die Rubrik", "messages", "de");
t.add("p.article", "das Clipping", "messages", "de");
t.add("p.source", "die Quelle", "messages", "de");
t.add("p.moreSources", "weitere Quellen", "messages", "de");
t.add("p.passiveOnly", "nicht aktivierbar", "messages", "de");
t.add("upload", "Hochladen", "messages", "de");
t.add("f.fileUploaded", "Fertig", "messages", "de");
t.add("f.UploadedSave", "Fertig! Bitte speichern.", "messages", "de");
t.add("fileUploadValue", "Datei suchen...", "messages", "de");
t.add("fontfamily", "Schriftart", "messages", "de");
t.add("fontsize", "Schriftgr\u00f6\u00dfe", "messages", "de");
t.add("fontcolor", "Schriftfarbe", "messages", "de");
t.add("choose", "W\u00e4hlen", "messages", "de");
t.add("c.contact", "Kontakt", "messages", "de");
t.add("c.form", "Formular", "messages", "de");
t.add("c.sender", "Ihre Kontaktdaten", "messages", "de");
t.add("c.name", "Name:", "messages", "de");
t.add("c.emailAdr", "E-Mail:", "messages", "de");
t.add("c.telephone", "Telefon:", "messages", "de");
t.add("c.content", "Wie k\u00f6nnen wir Ihnen helfen?", "messages", "de");
t.add("c.request", "Bitte kontaktieren Sie mich:", "messages", "de");
t.add("c.message", "Nachricht:", "messages", "de");
t.add("c.account", "zur Rechnung", "messages", "de");
t.add("c.order", "zum Auftrag", "messages", "de");
t.add("c.portal", "zum ARGUS Portal", "messages", "de");
t.add("c.advice", "zu allgemeinen Fragen", "messages", "de");
t.add("js.loadingMessage", "Daten werden geladen, bitte warten\u2026", "messages", "de");
t.add("js.savingMessage", "Daten werden gespeichert, bitte warten\u2026", "messages", "de");
t.add("js.loadingErrorMessage", "Fehler beim Laden der Daten", "messages", "de");
t.add("js.unkownErrorMessage", "Unbekannter Fehler", "messages", "de");
t.add("js.loadingMediumMessage", "Mediendaten werden geladen, bitte warten\u2026", "messages", "de");
t.add("js.loadingAdresseMessage", "Adressdaten werden geladen, bitte warten\u2026", "messages", "de");
t.add("select2.formatNoMatches", "Keine Resultate gefunden", "messages", "de");
t.add("select2.formatInputTooShort", "Bitte mindestens %cnt% Zeichen eingeben", "messages", "de");
t.add("select2.formatInputTooLong", "Bitte %cnt% Zeichen weniger eingeben", "messages", "de");
t.add("select2.formatSelectionTooBig", "{1} Sie k\u00f6nnen nur %cnt% Eintrag ausw\u00e4hlen |]1,Inf] Sie k\u00f6nnen nur %cnt% Eintr\u00e4ge ausw\u00e4hlen", "messages", "de");
t.add("select2.formatLoadMore", "Lade mehr Ergebnisse...", "messages", "de");
t.add("select2.formatSearching", "Suche...", "messages", "de");
t.add("cd.headline", "Headline", "messages", "de");
t.add("cd.www", "Link", "messages", "de");
t.add("cd.datum", "Erscheinungsdatum", "messages", "de");
t.add("cd.auftrag", "Auftrag", "messages", "de");
t.add("cd.abo", "Abo", "messages", "de");
t.add("cd.teaser", "Teaser", "messages", "de");
t.add("cd.meldung", "Meldung", "messages", "de");
t.add("cd.beitragslaenge", "Beitragsl\u00e4nge", "messages", "de");
t.add("cd.suchbegriff", "Suchbegriffe", "messages", "de");
t.add("cd.gesamtTonalitaet", "Gesamtbewertung", "messages", "de");
t.add("cd.tag", "Tags", "messages", "de");
t.add("cd.ordner", "Ordner", "messages", "de");
t.add("cd.kommentar", "Kommentare", "messages", "de");
t.add("cd.expressrubrik", "Rubrik", "messages", "de");
t.add("cd.titel", "Medientitel", "messages", "de");
t.add("cd.newMedium", "Eigenes Medium definieren", "messages", "de");
t.add("cd.untertitel", "Untertitel", "messages", "de");
t.add("cd.medienarttyp", "Medienart und -Typ", "messages", "de");
t.add("cd.branche", "Branche", "messages", "de");
t.add("cd.bundesland", "Bundesland", "messages", "de");
t.add("cd.landescode", "Land", "messages", "de");
t.add("cd.erscheinungsweise", "Erscheinungsweise", "messages", "de");
t.add("cd.international", "International", "messages", "de");
t.add("cd.sprache", "Sprache", "messages", "de");
t.add("cd.verlag", "Verlag", "messages", "de");
t.add("cd.redaktion", "Redaktion", "messages", "de");
t.add("cd.sender", "Sender", "messages", "de");
t.add("cd.nielsen", "Nielsen", "messages", "de");
t.add("cd.kategorie", "Kategorie", "messages", "de");
t.add("cd.dimension", "Dimension", "messages", "de");
t.add("cd.seite", "Seite", "messages", "de");
t.add("cd.reichweite", "Reichweite", "messages", "de");
t.add("cd.aaew", "A\u00c4W Gesamt", "messages", "de");
t.add("cd.dokument", "Dokumente", "messages", "de");
t.add("cd.noEditPermission", "Sie haben keine Berechtigung zum Bearbeiten.", "messages", "de");
t.add("cd.artikel", "Artikel", "messages", "de");
t.add("cd.medium", "Medium", "messages", "de");
t.add("cd.dokumente", "Dokumente", "messages", "de");
t.add("cd.add", "hinzuf\u00fcgen", "messages", "de");
t.add("cd.createItem", "%item% anlegen", "messages", "de");
t.add("cd.addItem", "%item% hinzuf\u00fcgen", "messages", "de");
t.add("cd.rubrikAdd", "Rubrik hinzuf\u00fcgen", "messages", "de");
t.add("cd.tagAdd", "Tag anlegen und hinzuf\u00fcgen", "messages", "de");
t.add("cd.ordnerAdd", "Ordner anlegen und hinzuf\u00fcgen", "messages", "de");
t.add("cd.kommentarAdd", "Kommentar hinzuf\u00fcgen", "messages", "de");
t.add("cd.dokumentUpload", "Dokument hinzuf\u00fcgen", "messages", "de");
t.add("cd.mkzTypAdd", "Reichweite hinzuf\u00fcgen", "messages", "de");
t.add("cd.viewDokument", "ansehen", "messages", "de");
t.add("cd.delete", "l\u00f6schen", "messages", "de");
t.add("cd.choose", "Ausw\u00e4hlen...", "messages", "de");
t.add("cd.editThema", "Themen bearbeiten", "messages", "de");
t.add("cd.editThemaTonalitaet", "Bewertung", "messages", "de");
t.add("cd.allowedFormats", "Erlaubte Formate", "messages", "de");
t.add("cd.mspHint", "Medienspiegel: Nur PDF und JPEG\/JPG werden ber\u00fccksichtigt. Reihenfolge der Anzeige im Medienspiegel entspricht Reihenfolge des Hochladens.", "messages", "de");
t.add("cd.hint", "Hinweis:", "messages", "de");
t.add("cd.trefferDelete", "Clipping l\u00f6schen", "messages", "de");
t.add("cd.trefferDeleteText", "M\u00f6chten Sie dieses Clipping wirklich l\u00f6schen?", "messages", "de");
t.add("cd.emailFileTip", " (eine E-Mail pro Zeile)", "messages", "de");
t.add("cd.emailUploadSuccess", "Alle E-Mail-Adressen wurden hinzugef\u00fcgt.", "messages", "de");
t.add("cd.emailUploadFileError", "Die gew\u00e4hlte Datei konnte nicht gelesen werden. Bitte pr\u00fcfen Sie das Format.", "messages", "de");
t.add("cd.emailUploadDataError", "Folgende E-Mail-Adressen sind ung\u00fcltig und wurden nicht hinzugef\u00fcgt:", "messages", "de");
t.add("cd.file_upload", "Datei-Import \"%file%\": ", "messages", "de");
t.add("cd.valCdLettersNumbersSigns", "Erlaubte Zeichen: Buchstaben, Ziffern, Leerzeichen, +, -, \/", "messages", "de");
t.add("cd.valCdUrl", "Ung\u00fcltige URL", "messages", "de");
t.add("cd.valCdDate", "Ung\u00fcltiges Datum", "messages", "de");
t.add("cd.valCdTime", "Ung\u00fcltige Uhrzeit", "messages", "de");
t.add("cd.valCdIllegalKey", "Ung\u00fcltiger Key", "messages", "de");
t.add("cd.valCdInvalidSigns", "Ung\u00fcltige Zeichen", "messages", "de");
t.add("cd.valCdInvalidNumbers", "Bitte nur Ziffern eingeben", "messages", "de");
t.add("cd.valCdChooseSubject", "Bitte Abo ausw\u00e4hlen", "messages", "de");
t.add("cd.valCdChooseTitle", "Medientitel ausw\u00e4hlen", "messages", "de");
t.add("cd.valCdChooseMedienArtTyp", "Medienart ausw\u00e4hlen", "messages", "de");
t.add("cd.valCdChooseAdrBez", "Namen ausw\u00e4hlen", "messages", "de");
t.add("cd.valCdChooseAbo", "Abo ausw\u00e4hlen", "messages", "de");
t.add("cd.valNoAuftragSelected", "Auftrag ausw\u00e4hlen", "messages", "de");
t.add("cd.valCdFloat", "Ung\u00fcltige Zahl", "messages", "de");
t.add("cd.valCdLanguage", "Bitte Sprache ausw\u00e4hlen", "messages", "de");
t.add("cd.valCdEmpty", "Bitte Feld ausf\u00fcllen", "messages", "de");
t.add("cd.logoHeightHint", "Das Logo wird auf eine H\u00f6he von 40 Pixel skaliert.", "messages", "de");
t.add("cd.validHeadlineLength", "Die L\u00e4nge der Headline ist auf %limit% Zeichen begrenzt.", "messages", "de");
t.add("cd.validSubtitleLength", "Die L\u00e4nge des Untertitels ist auf %limit% Zeichen begrenzt.", "messages", "de");
t.add("cd.validTeaserLength", "Die L\u00e4nge des Teasers ist auf %limit% Zeichen begrenzt.", "messages", "de");
t.add("cd.validSectionLength", "Die L\u00e4nge des Rubriknamens ist auf %limit% Zeichen begrenzt.", "messages", "de");
t.add("cd.validPageLength", "Die L\u00e4nge der Seite ist auf %limit% Zeichen begrenzt.", "messages", "de");
t.add("cd.error415", "Dateiformat wird nicht unterst\u00fctzt.", "messages", "de");
t.add("cd.error406", "Die Datei ist zu gro\u00df. Erlaubt sind maximal %maxval% MB.", "messages", "de");
t.add("cd.error406_imgheight", "Die Bildh\u00f6he ist nicht korrekt. Sie muss exakt %imgheight% Pixel betragen.", "messages", "de");
t.add("cd.error500", "Ein Fehler ist aufgetreten.", "messages", "de");
t.add("cd.error002", "Derzeit kann nur ein zus\u00e4tzliches Dokument pro Dateityp hochgeladen werden.", "messages", "de");
t.add("cd.uploadError", "Fehler bei Upload der Datei.", "messages", "de");
t.add("rss.admin", "Feeds", "messages", "de");
t.add("rss.name", "Bezeichnung", "messages", "de");
t.add("rss.database", "Datengrundlage", "messages", "de");
t.add("rss.suchfilter", "Filter", "messages", "de");
t.add("rss.auftrag", "Auftr\u00e4ge", "messages", "de");
t.add("rss.abo", "Abos", "messages", "de");
t.add("rss.suchbegriff", "Suchbegriffe", "messages", "de");
t.add("rss.medienart", "Medienarten", "messages", "de");
t.add("rss.lengthheadline", "L\u00e4nge der Headline", "messages", "de");
t.add("rss.signs", "Zeichen", "messages", "de");
t.add("rss.komplett", "komplett", "messages", "de");
t.add("rss.leer", "leer", "messages", "de");
t.add("rss.lengthmeldung", "L\u00e4nge der Meldung", "messages", "de");
t.add("rss.results", "Ergebnisse", "messages", "de");
t.add("rss.countresults", "Anzahl Ergebnisse", "messages", "de");
t.add("rss.heute", "heute", "messages", "de");
t.add("rss.heuteundgestern", "gestern und heute", "messages", "de");
t.add("rss.aktuelleWoche", "aktuelle Woche", "messages", "de");
t.add("rss.ausgabe", "Ausgabe", "messages", "de");
t.add("rss.showvolltext", "Abstracts komplett", "messages", "de");
t.add("rss.showtonalitaet", "Gesamtbewertung ausgeben", "messages", "de");
t.add("rss.showSubEditions", "Unterausgaben ausgeben", "messages", "de");
t.add("rss.ipconstraint", "IP-Beschr\u00e4nkung", "messages", "de");
t.add("rss.intranetDokument", "Dokumente nur im Intranet anzeigen", "messages", "de");
t.add("rss.intranetall", "Kompletten Feed nur im Intranet anzeigen", "messages", "de");
t.add("rss.noconstraint", "Alles anzeigen", "messages", "de");
t.add("rss.ipaddress", "IP-Adressen", "messages", "de");
t.add("rss.ipaddressFormat", "IP-Adressen und -Ranges sind durch Semikolon \";\" zu trennen. Folgende Formate sind m\u00f6glich:", "messages", "de");
t.add("rss.ipaddressFormat1", "IP-Adresse: 123.234.123.234", "messages", "de");
t.add("rss.ipaddressFormat2", "Wildcards: 123.234.123.*", "messages", "de");
t.add("rss.ipaddressFormat3", "CIDR: 123.234.1.0\/24", "messages", "de");
t.add("rss.ipaddressFormat4", "Start-Ende-Range: 123.234.123.0-123.234.123.255", "messages", "de");
t.add("rss.language", "Sprache", "messages", "de");
t.add("rss.de", "Deutsch", "messages", "de");
t.add("rss.en", "Englisch", "messages", "de");
t.add("rss.active", "Aktiv", "messages", "de");
t.add("rss.feedType", "Feedtyp", "messages", "de");
t.add("rss.JsonFeed", "JSON Feed", "messages", "de");
t.add("rss.XmlFeed", "XML Feed", "messages", "de");
t.add("rss.addRss", "RSS Feed", "messages", "de");
t.add("rss.rssDelete", "RSS Feed l\u00f6schen", "messages", "de");
t.add("rss.rssDeleteText", "M\u00f6chten sie den Feed wirklich l\u00f6schen?", "messages", "de");
t.add("rss.linkTagAsDocument", "Link-Tag als Dokument", "messages", "de");
t.add("rss.valRssInvalidKey", "Ung\u00fcltige Auswahl.", "messages", "de");
t.add("rss.valRssError", "Es ist ein Fehler aufgetreten.", "messages", "de");
t.add("rss.valRssInvalidName", "Bitte eine Bezeichnung eingeben. G\u00fcltige Zeichen: Buchstaben, Leerzeichen und Ziffern.", "messages", "de");
t.add("rss.valRssSetValidIp", "Bitte eine g\u00fcltige IP-Adresse eingeben.", "messages", "de");
t.add("rss.valRssChooseFilter", "Bitte einen Filter ausw\u00e4hlen.", "messages", "de");
t.add("rss.valRssChooseAuftrag", "Bitte mindestens einen Auftrag ausw\u00e4hlen.", "messages", "de");
t.add("rss.maxReachedCount", "Die maximale Anzahl von %count% RSS-Feeds ist erreicht.", "messages", "de");
t.add("rss.maxReached", "Die maximale Anzahl an RSS-Feeds ist erreicht.", "messages", "de");
t.add("rss.maxReachedModalHeader", "Hinweis", "messages", "de");
t.add("rss.noTitle", "kein Titel", "messages", "de");
t.add("rss.noDescription", "keine Inhalte verf\u00fcgbar", "messages", "de");
t.add("rss.tonalitaet", "Bewertung", "messages", "de");
t.add("rss.empty", "", "messages", "de");
t.add("api.feedLimit", "(%currentNumber%\/%maxNumber%)", "messages", "de");
t.add("api.clipping", "Artikeldaten", "messages", "de");
t.add("api.mediaData", "Mediendaten", "messages", "de");
t.add("api.analysis", "Analysedaten", "messages", "de");
t.add("api.typeOfRange", "Kennzahlen", "messages", "de");
t.add("api.mediaFiles", "Medien Dateien", "messages", "de");
t.add("api.updateInterval", "Update Intervall", "messages", "de");
t.add("api.feedDuration", "G\u00fcltigkeitsdauer", "messages", "de");
t.add("api.sortBy", "Sortierung nach", "messages", "de");
t.add("api.includeHtmlTags", "F\u00fcgen Sie HTML-Tags ein", "messages", "de");
t.add("api.excludeSubedition", "Unterausgaben ausschlie\u00dfen", "messages", "de");
t.add("api.exportFieldNaming", "Benennung von Exportfeldern", "messages", "de");
t.add("api.projectId", "Projekt-ID", "messages", "de");
t.add("api.accessToken", "Zugriffstoken", "messages", "de");
t.add("api.exportFieldNamingDefault", "Standard", "messages", "de");
t.add("api.exportFieldNamingEnBW", "EnBW", "messages", "de");
t.add("api.exportFieldNamingTalkwalker", "Talkwalker", "messages", "de");
t.add("api.selectAll", "Alle anw\u00e4hlen", "messages", "de");
t.add("api.deselectAll", "Alle abw\u00e4hlen", "messages", "de");
t.add("api.settings", "Einstellungen", "messages", "de");
t.add("api.id", "Treffernummer", "messages", "de");
t.add("api.headline", "Headline", "messages", "de");
t.add("api.subtitle", "Untertitel", "messages", "de");
t.add("api.issueNumber", "Ausgabe", "messages", "de");
t.add("api.publicationDate", "Erscheinungsdatum", "messages", "de");
t.add("api.searchTerms", "Suchbegriffe", "messages", "de");
t.add("api.category", "Rubrik", "messages", "de");
t.add("api.pressSummary", "Press Summary", "messages", "de");
t.add("api.abstract", "Meldung", "messages", "de");
t.add("api.teaser", "Teaser", "messages", "de");
t.add("api.searchTermContext", "Suchwortumgebung", "messages", "de");
t.add("api.AVEtotal", "A\u00c4W Gesamt", "messages", "de");
t.add("api.totalRating", "Gesamtbewertung", "messages", "de");
t.add("api.deeplink", "Deeplink", "messages", "de");
t.add("api.clippingLength", "Beitragsl\u00e4nge", "messages", "de");
t.add("api.numberOfCharacters", "Zeichenanzahl (Clipping)", "messages", "de");
t.add("api.order", "Auftrag", "messages", "de");
t.add("api.subject", "Abo", "messages", "de");
t.add("api.formatRTV", "Format (RTV)", "messages", "de");
t.add("api.author", "Autor", "messages", "de");
t.add("api.language", "Sprache", "messages", "de");
t.add("api.page", "Seite", "messages", "de");
t.add("api.topicData", "Topic Data", "messages", "de");
t.add("api.mediaTitle", "Medientitel", "messages", "de");
t.add("api.editorialOffice", "Redaktion", "messages", "de");
t.add("api.publisher", "Verlag", "messages", "de");
t.add("api.broadcastStation", "Sender", "messages", "de");
t.add("api.mediaGenre", "Medienart", "messages", "de");
t.add("api.mediaType", "Medientyp", "messages", "de");
t.add("api.mediaSector", "Medienbranche", "messages", "de");
t.add("api.federalState", "Bundesland", "messages", "de");
t.add("api.country", "Land", "messages", "de");
t.add("api.countryIso1", "ISO Landescode 1", "messages", "de");
t.add("api.countryIso2", "ISO Landescode 2", "messages", "de");
t.add("api.frequency", "Erscheinungsweise", "messages", "de");
t.add("api.mediumID", "Medium ID", "messages", "de");
t.add("api.mediaUrl", "URL Medium", "messages", "de");
t.add("api.analysis.subject", "Thema", "messages", "de");
t.add("api.rating", "Tonalit\u00e4t", "messages", "de");
t.add("api.AVE", "A\u00c4W", "messages", "de");
t.add("api.text1", "Text 1", "messages", "de");
t.add("api.text2", "Text 2", "messages", "de");
t.add("api.text3", "Text 3", "messages", "de");
t.add("api.ARGUSPortal1", "ARGUS Portal 1", "messages", "de");
t.add("api.ARGUSPortal2", "ARGUS Portal 2", "messages", "de");
t.add("api.ARGUSPortal3", "ARGUS Portal 3", "messages", "de");
t.add("api.ARGUSPortal4", "ARGUS Portal 4", "messages", "de");
t.add("api.ARGUSPortal5", "ARGUS Portal 5", "messages", "de");
t.add("api.portal1", "ARGUS Portal 1", "messages", "de");
t.add("api.portal2", "ARGUS Portal 2", "messages", "de");
t.add("api.portal3", "ARGUS Portal 3", "messages", "de");
t.add("api.portal4", "ARGUS Portal 4", "messages", "de");
t.add("api.portal5", "ARGUS Portal 5", "messages", "de");
t.add("api.topSubject", "Top Thema", "messages", "de");
t.add("api.source", "Quelle", "messages", "de");
t.add("api.sourceCategory", "Quelle Kategorie", "messages", "de");
t.add("api.pressRelease", "Pressemitteilung", "messages", "de");
t.add("api.keyMessage", "Schl\u00fcsselbotschaft", "messages", "de");
t.add("api.attention", "Aufmerksamkeit", "messages", "de");
t.add("api.attentionType", "Aufmerksamkeit Art", "messages", "de");
t.add("api.imageFactor", "Imagefaktoren", "messages", "de");
t.add("api.ratingImageFactor", "Tonalit\u00e4t Imagefaktoren", "messages", "de");
t.add("api.issues", "Issues", "messages", "de");
t.add("api.tonalityIssues", "Tonalit\u00e4t Issues", "messages", "de");
t.add("api.themaEbens1", "Thema Ebene 1", "messages", "de");
t.add("api.themaEbens2", "Thema Ebene 2", "messages", "de");
t.add("api.themaEbens3", "Thema Ebene 3", "messages", "de");
t.add("api.grossReach", "Reichweite", "messages", "de");
t.add("api.printRun", "Gedruckte Auflage", "messages", "de");
t.add("api.copiesSold", "Verkaufte Auflage", "messages", "de");
t.add("api.copiesDistributed", "Verbreitete Auflage", "messages", "de");
t.add("api.visits", "Visits", "messages", "de");
t.add("api.pageViews", "Page Impressions", "messages", "de");
t.add("api.uniqueUser", "Unique User", "messages", "de");
t.add("api.mediaLogo", "Medien-Logo", "messages", "de");
t.add("api.mediaCategory", "Medienkategorie", "messages", "de");
t.add("api.videoThumbnail", "Video thumbnail", "messages", "de");
t.add("api.picture", "Picture", "messages", "de");
t.add("api.PDFlink", "PDF (link)", "messages", "de");
t.add("api.displayFormat", "Darstellungsform", "messages", "de");
t.add("api.linkToOriginalVideo", "Link zum Originalvideo", "messages", "de");
t.add("api.fullTextPressRelease", "Volltext Pressemitteilung", "messages", "de");
t.add("api.sort.dateAscending", "Datum aufsteigend", "messages", "de");
t.add("api.sort.dateDescending", "Datum absteigend", "messages", "de");
t.add("api.sort.reachAscending", "Reichweite aufsteigend", "messages", "de");
t.add("api.sort.reachDescending", "Reichweite absteigend", "messages", "de");
t.add("api.15min", "15 min", "messages", "de");
t.add("api.60min", "Alle 60 min", "messages", "de");
t.add("api.4hour", "Alle 4 Stunden", "messages", "de");
t.add("api.twiceADay", "Zweimal t\u00e4glich", "messages", "de");
t.add("api.onceADay", "T\u00e4glich", "messages", "de");
t.add("api.onceAWeek", "W\u00f6chentlich", "messages", "de");
t.add("api.never", "Nie", "messages", "de");
t.add("api.1month", "1 Monat", "messages", "de");
t.add("api.6weeks", "6 Wochen", "messages", "de");
t.add("api.6months", "6 Monate", "messages", "de");
t.add("api.12months", "12 Monate", "messages", "de");
t.add("api.24months", "24 Monate", "messages", "de");
t.add("api.forever", "Unbegrenzt", "messages", "de");
t.add("api.copy", "Kopieren", "messages", "de");
t.add("api.last7days", "Letzte 7 Tage", "messages", "de");
t.add("api.feedId", "Custom Feed ID", "messages", "de");
t.add("api.expirationDate", "G\u00fcltig bis:", "messages", "de");
t.add("api.addJSON", "JSON Feed", "messages", "de");
t.add("api.addXML", "XML Feed", "messages", "de");
t.add("api.feedIdValidation", "Bitte geben Sie eine andere Feed ID ein. Diese wird in Ihrem Firmenaccount bereits verwendet.", "messages", "de");
t.add("api.nameValidation", "Fehler: Dieser Name wird bereits verwendet.", "messages", "de");
t.add("se.createNewMedium", "\"medium\" als neues Medium anlegen", "messages", "de");
t.add("se.createNewMediumAddress", "\"###\" als neue Adresse anlegen", "messages", "de");
t.add("docart", "{10,80} Nachweis PDF |{20,65,82} Nachweis XML |{22,81} Nachweis TXT |{23} Nachweis HTML |{25,26,83,126} Nachweis Word |{30,31,32,34,87,132,133} TV Mitschnitt |{33,130} TV Thumbnail |{35,86,172,173} H\u00f6rfunk Mitschnitt |{-35,171} H\u00f6rfunk Mitschnitt Preview |{36,37,131} TV Mitschnitt Preview |{60} MRA-Sujetblatt |{84} Nachweis PPT |{85,88} Bild", "messages", "de");
t.add("docartMobile", "{10,80} Download Nachweis PDF |{20,65,82} Download Nachweis XML |{22,81} Download Nachweis TXT |{23} Download Nachweis HTML |{25,26,83,126} Download Nachweis Word |{30,31,32,34,87,132,133} TV Mitschnitt abspielen |{33,130} TV Thumbnail ansehen |{35,86,172,173} H\u00f6rfunk Mitschnitt abspielen |{-35,171} H\u00f6rfunk Mitschnitt Preview abspielen |{36,37,131} TV Mitschnitt Preview abspielen |{60} Download MRA-Sujetblatt |{84} Download Nachweis PPT |{85,88} Bild", "messages", "de");
t.add("ak.auftragKonfig", "MRA Auftrags-Konfiguration", "messages", "de");
t.add("ak.auftrag", "Auftrag", "messages", "de");
t.add("ak.abo", "Standard MRA-Abo", "messages", "de");
t.add("ak.vorlage", "Vorlage", "messages", "de");
t.add("ak.mraAufmerksamkeit", "Aufmerksamkeit", "messages", "de");
t.add("ak.mraAufmerksamkeitArt", "Aufmerksamkeit Art", "messages", "de");
t.add("ak.mraDarstellungsform", "Darstellungsform", "messages", "de");
t.add("ak.mraDarstellungsformArt", "DarstellungsformArt", "messages", "de");
t.add("ak.mraImagefaktor", "Imagefaktoren", "messages", "de");
t.add("ak.mraIssues", "Issues", "messages", "de");
t.add("ak.mraIssuesArt", "Issues Art", "messages", "de");
t.add("ak.mraKernbotschaft", "Kernbotschaft", "messages", "de");
t.add("ak.mraTop", "Top Thema", "messages", "de");
t.add("ak.mraText1", "Text 1", "messages", "de");
t.add("ak.mraText2", "Text 2", "messages", "de");
t.add("ak.mraText3", "Text 3", "messages", "de");
t.add("ak.mraPortal1", "ARGUS Portal 1", "messages", "de");
t.add("ak.mraPortal2", "ARGUS Portal 2", "messages", "de");
t.add("ak.mraPortal3", "ARGUS Portal 3", "messages", "de");
t.add("ak.mraPortal4", "ARGUS Portal 4", "messages", "de");
t.add("ak.mraPortal5", "ARGUS Portal 5", "messages", "de");
t.add("ak.mraPressemitteilung", "Pressemitteilung", "messages", "de");
t.add("ak.mraQuelle", "Quelle", "messages", "de");
t.add("ak.mraQuelleKategorie", "Quelle Kategorie", "messages", "de");
t.add("ak.mraUrheber", "Urheber", "messages", "de");
t.add("ak.mraUrheberKategorie", "Urheber Kategorie", "messages", "de");
t.add("ak.mraThema", "Thema", "messages", "de");
t.add("ak.mraWgk", "Wiedergabe korrekt", "messages", "de");
t.add("ak.mraWgv", "Wiedergabe vollst\u00e4ndig", "messages", "de");
t.add("ak.gesamtTonalitaet", "Gesamtbewertung", "messages", "de");
t.add("ak.themaTonalitaet", "Tonalit\u00e4t Thema", "messages", "de");
t.add("ak.issuesTonalitaet", "Tonalit\u00e4t Issues", "messages", "de");
t.add("ak.imagefaktorTonalitaet", "Tonalit\u00e4t Imagefaktoren", "messages", "de");
t.add("ak.themenebene", "Ebene", "messages", "de");
t.add("ak.themenebeneHeadline", "Themenebenen", "messages", "de");
t.add("ak.mraAufmerksamkeitDefault", "{1} erzielte Aufmerksamkeit |{2} Attention", "messages", "de");
t.add("ak.mraAufmerksamkeitArtDefault", "{1} erzielte Aufmerksamkeit Art |{2} Attention type", "messages", "de");
t.add("ak.mraDarstellungsformDefault", "{1} Darstellungsform |{2} Display format", "messages", "de");
t.add("ak.mraDarstellungsformArtDefault", "{1} Darstellungsform Art |{2} Display format type", "messages", "de");
t.add("ak.mraImagefaktorDefault", "{1} Imagefaktor |{2} Image factor", "messages", "de");
t.add("ak.mraIssuesDefault", "{1} Issues |{2} Issues", "messages", "de");
t.add("ak.mraIssuesArtDefault", "{1} Issues Art |{2} Issues type", "messages", "de");
t.add("ak.mraKernbotschaftDefault", "{1} Kernbotschaft |{2} Key message", "messages", "de");
t.add("ak.mraPressemitteilungDefault", "{1} Pressemitteilung |{2} Press release", "messages", "de");
t.add("ak.mraQuelleDefault", "{1} Quelle |{2} Source", "messages", "de");
t.add("ak.mraQuelleKategorieDefault", "{1} Quelle Kategorie |{2} Source category", "messages", "de");
t.add("ak.mraPortalDefault", "{1} ARGUS Portal |{2} ARGUS Portal", "messages", "de");
t.add("ak.mraTextDefault", "{1} Text |{2} Text", "messages", "de");
t.add("ak.mraTopDefault", "{1} Top Thema |{2} Top Subject", "messages", "de");
t.add("ak.mraThemaDefault", "{1} Thema |{2} Subject", "messages", "de");
t.add("ak.mraUrheberDefault", "{1} Urheber |{2} Author", "messages", "de");
t.add("ak.mraUrheberKategorieDefault", "{1} Urheber Kategorie |{2} Author category", "messages", "de");
t.add("ak.mraWgkDefault", "{1} Wiedergabe korrekt |{2} Correct Repetition", "messages", "de");
t.add("ak.mraWgvDefault", "{1} Wiedergabe vollst\u00e4ndig |{2} Complete Repetition", "messages", "de");
t.add("ak.gesamtTonalitaetDefault", "{1} Gesamtbewertung |{2} Total rating ", "messages", "de");
t.add("ak.themaTonalitaetDefault", "{1} Tonalit\u00e4t |{2} Rating", "messages", "de");
t.add("ak.issuesTonalitaetDefault", "{1} Tonalit\u00e4t Issues |{2} Rating Issues", "messages", "de");
t.add("ak.imagefaktorTonalitaetDefault", "{1} Tonalit\u00e4t Imagefaktoren |{2} Rating image factor", "messages", "de");
t.add("ak.ebeneDefault", "{1} Ebene |{2} Level ", "messages", "de");
t.add("ak.field", "Variable", "messages", "de");
t.add("ak.fieldHover", "Variablenbezeichnungen laut MRA-Einrichtung in Auftragskonfiguration in Erfassung", "messages", "de");
t.add("ak.showField", "Anzeigen Detailsicht", "messages", "de");
t.add("ak.showFieldHover", "Codierung dieser Variable wird bei \"Clippings\" rechts im Bereich Analyse angezeigt", "messages", "de");
t.add("ak.listField", "Anzeigen Kachelansicht", "messages", "de");
t.add("ak.listFieldHover", "Ausgabe der Auspr\u00e4gung in der Listendarstellung und Detailsicht der Kachelansicht", "messages", "de");
t.add("ak.editClipField", "Codierung im ARGUS Portal", "messages", "de");
t.add("ak.editClipFieldHover", "Variable kann im ARGUS Portal codiert werden", "messages", "de");
t.add("ak.editCoreField", "Neue Auspr\u00e4gung im ARGUS Portal", "messages", "de");
t.add("ak.editCoreFieldHover", "Beim Codieren im ARGUS Portal k\u00f6nnen neue Auspr\u00e4gungen hinzugef\u00fcgt werden", "messages", "de");
t.add("ak.filterField", "Filter", "messages", "de");
t.add("ak.filterFieldHover", "Variable wird als Filterm\u00f6glichkeit angezeigt", "messages", "de");
t.add("ak.dbField", "Dashboard", "messages", "de");
t.add("ak.dbFieldHover", "Variable kann im Dashboard angezeigt werden", "messages", "de");
t.add("ak.selectBoxField", "Filter: SelectBox \/ Volltext \/ Autocomplete", "messages", "de");
t.add("ak.selectBoxFieldHover", "Art, wie die Auspr\u00e4gungen der Variable im Filter ausgew\u00e4hlt werden", "messages", "de");
t.add("ak.tonalitaetField", "Dashboard Bezug Bewertung: Treffer \/ Thema \/ Keine \/ Eigene", "messages", "de");
t.add("ak.tonalitaetFieldHover", "W\u00e4hlt aus, welche bzw. wie die Bewertung im ARGUS Portal dargestellt wird: Treffer: Durchschnittsbewertung aller Bewertungen im Beitrag | Thema: \"normale\" Bewertung der Themennennungen | Keine: nicht darstellbar | Eigene: Bei IF\/Issues wird die dort erhobene Bewertung angezeigt, nicht diejenige auf Themenebene\n", "messages", "de");
t.add("ak.fieldBezDe", "Bezeichnung deutsch", "messages", "de");
t.add("ak.fieldBezDeHover", "Bezeichnung deutsch", "messages", "de");
t.add("ak.fieldBezEn", "Bezeichnung englisch", "messages", "de");
t.add("ak.fieldBezEnHover", "Bezeichnung englisch", "messages", "de");
t.add("ak.breakTrefferBez", "Treffer", "messages", "de");
t.add("ak.breakThemaBez", "Thema", "messages", "de");
t.add("ak.breakPmBez", "Pressemitteilung", "messages", "de");
t.add("ak.enableEbene", "Anzeigen", "messages", "de");
t.add("ak.enableEbeneHover", "Ausblenden kompletter Themenebenen. Diese werden dann nicht im Themenbaum angezeigt und werden nirgends ausgewertet.", "messages", "de");
t.add("ak.extendedMraPm", "erweiterte Ansicht PM", "messages", "de");
t.add("ak.extendedMraPmHover", "Zeigt auch den Volltext der Pressemitteilung an", "messages", "de");
t.add("ak.mraPortal1Hover", "Einfachauswahl, 255 Zeichen, reine ARGUS Portal-Variable, nicht in MRA-Einrichtung ausw\u00e4hlbar!", "messages", "de");
t.add("ak.mraPortal2Hover", "Einfachauswahl, Zeichenanzahl unbegrenzt, reine ARGUS Portal-Variable, nicht in MRA-Einrichtung ausw\u00e4hlbar!", "messages", "de");
t.add("ak.mraPortal3Hover", "Mehrfachauswahl, 255 Zeichen, reine ARGUS Portal-Variable, nicht in MRA-Einrichtung ausw\u00e4hlbar!", "messages", "de");
t.add("ak.mraPortal4Hover", "Mehrfachauswahl, 255 Zeichen, reine ARGUS Portal-Variable, nicht in MRA-Einrichtung ausw\u00e4hlbar!", "messages", "de");
t.add("ak.mraPortal5Hover", "Mehrfachauswahl, 255 Zeichen, reine ARGUS Portal-Variable, nicht in MRA-Einrichtung ausw\u00e4hlbar!", "messages", "de");
t.add("ak.saveKonfig", "Als neue Konfiguration anlegen", "messages", "de");
t.add("ak.updateKonfig", "Ausgew\u00e4hlte Konfiguration aktualisieren", "messages", "de");
t.add("ak.updateKonfigAuftrag", "und diesem Auftrag hinzuf\u00fcgen", "messages", "de");
t.add("ak.resetKonfig", "\u00c4nderungen verwerfen", "messages", "de");
t.add("ak.aaew", "A\u00c4W Relevant", "messages", "de");
t.add("ak.aaewDefault", "A\u00c4W Relevant", "messages", "de");
t.add("ak.errChangeDefaultConfig", "Sie d\u00fcrfen die Vorlage \"Default\" nicht \u00e4ndern. Bitte legen Sie eine neue Konfiguration an.", "messages", "de");
t.add("e.embedWidget", "den Link", "messages", "de");
t.add("e.maxlinkCountError", "Sie haben die maximale Anzahl an Widget-Links erreicht. Bitte l\u00f6schen Sie ungenutze Links oder kontaktieren Ihren Kundenbetreuer.", "messages", "de");
t.add("e.linkPlaceHolder", "Bitte \"%button%\" klicken, um den Link zu generieren.", "messages", "de");
t.add("ed.bold", "Fett", "messages", "de");
t.add("ed.italic", "Kursiv", "messages", "de");
t.add("ed.underline", "Unterstrichen", "messages", "de");
t.add("ed.removeFormatting", "Formatierung entfernen", "messages", "de");
t.add("ed.fontSize", "Schriftgr\u00f6\u00dfe", "messages", "de");
t.add("ed.fontColor", "Schriftfarbe", "messages", "de");
t.add("ed.backgroundColor", "Hintergrundfarbe", "messages", "de");
t.add("ed.justifyLeft", "Links ausrichten", "messages", "de");
t.add("ed.justifyCenter", "Zentriert ausrichten", "messages", "de");
t.add("ed.justifyRight", "Rechts ausrichten", "messages", "de");
t.add("ed.link", "Link einbinden", "messages", "de");
t.add("ed.image", "Bild hochladen", "messages", "de");
t.add("ed.orderedList", "Nummerierung", "messages", "de");
t.add("ed.unorderedList", "Aufz\u00e4hlungszeichen", "messages", "de");
t.add("ed.heading", "\u00dcberschrift", "messages", "de");
t.add("ed.text", "Text", "messages", "de");
t.add("ed.url", "URL", "messages", "de");
t.add("ed.urlTitle", "Bezeichnung", "messages", "de");
t.add("ed.invalidUrl", "Ung\u00fcltige URL", "messages", "de");
t.add("ed.invalidUrlTitle", "Ung\u00fcltige Bezeichnung", "messages", "de");
t.add("ed.insertImage", "Bild hinzuf\u00fcgen", "messages", "de");
t.add("ed.galleryLoadingPlaceholder", "Bilderverwaltung wird geladen ...", "messages", "de");
t.add("nv.color", "Farbe", "messages", "de");
t.add("nv.dashboardColor", "Dashboard Farbe", "messages", "de");
t.add("nv.colorDescription0", "Hintergrundfarbe: Dropdown-Boxen in MSP Konfiguration", "messages", "de");
t.add("nv.colorDescription1", "Hintergrundfarbe: Filter, Buttons, Blackboard, Accordion Kopf", "messages", "de");
t.add("nv.colorDescription2", "Textfarbe: Auf Elementen mit Farbe 3 als Hintergrund", "messages", "de");
t.add("nv.colorDescription3", "Hintergrundfarbe: Auswahl, Hover auf Buttons mit Farbe 1, Hover in Menus, Aktivzustand in Menu und Dashboard-Tabs, Hervorgehobene Buttons, Neue Clippings, Textfarbe: Hervorgehobene Hinweistexte", "messages", "de");
t.add("nv.colorDescription4", "Textfarbe: Eingabefelder im Filter, Hover auf Dashboard-Tabs", "messages", "de");
t.add("nv.colorDescription5", "Hintergrundfarbe: Eingabefelder im Filter, Hover auf Dashboard-Tabs, Hover auf Tags", "messages", "de");
t.add("nv.colorDescription6", "Textfarbe: Standardfarbe", "messages", "de");
t.add("nv.colorDescription7", "Hintergrundfarbe: Bereiche zur Bearbeitung", "messages", "de");
t.add("nv.colorDescription8", "Textfarbe: Filter (Texte und Symbole zwischen den Eingabeelementen)", "messages", "de");
t.add("nv.colorDescription9", "Textfarbe: Links", "messages", "de");
t.add("nv.colorDescription10", "Textfarbe: Blackboard, Buttons mit Farbe 1 als Hintergrund", "messages", "de");
t.add("nv.colorDescription11", "Hintergrundfarbe: Rubriken in HTML-Medienspiegel", "messages", "de");
t.add("nv.colorDescription12", "Textfarbe: Rubriken in HTML-Medienspiegel", "messages", "de");
t.add("nv.colorDescription13", "Textfarbe: Links in HTML-Medienspiegel", "messages", "de");
t.add("nv.dbColorMain", "Hauptfarbe", "messages", "de");
t.add("nv.dbColorMainDescription", "Farbe wird z.B. als Basis f\u00fcr L\u00e4nder-Widget verwendet", "messages", "de");
t.add("nv.dbColorPlotLine", "Benchmark Linie", "messages", "de");
t.add("nv.dbColorPlotLineDescription", "Linie die einen Vergleichswert in Linien- und Balkendiagrammen setzt", "messages", "de");
t.add("nv.dbColorPlotBand", "Benchmark Bereich", "messages", "de");
t.add("nv.dbColorPlotBandDescription", "Hintergrundfarbe: Bereich in Linien- und Balkendiagrammen", "messages", "de");
t.add("nv.screenshotTooltipClippings", "Clippingliste", "messages", "de");
t.add("nv.screenshotTooltipClippingsEdit", "Clippings bearbeiten", "messages", "de");
t.add("nv.screenshotTooltipPspTiles", "Medienspiegel Kacheln", "messages", "de");
t.add("nv.screenshotTooltipDashboard", "Farben im Dashboard", "messages", "de");
t.add("nv.screenshotTooltipHtmlPsp", "HTML Medienspiegel", "messages", "de");
t.add("e.sendToEmails", "gesendet an folgende E-Mail-Adressen:", "messages", "de");
t.add("e.sendToEmailDistributionList", "gesendet an folgende Verteiler:", "messages", "de");
t.add("e.displayEmailsInEmail", "E-Mail-Adressen in der E-Mail anzeigen", "messages", "de");
t.add("e.displayEmailDistributionListsInEmail", "Verteiler in der E-Mail anzeigen", "messages", "de");
t.add("ceconomy.source", "Medium", "messages", "de");
t.add("ceconomy.published", "Ver\u00f6ffentlicht", "messages", "de");
t.add("ceconomy.sentBy", "Versendet von", "messages", "de");
t.add("ceconomy.sentTo", "Versendet an", "messages", "de");
t.add("ceconomy.footerText", "If you have any questions regarding the Monitoring Service, please contact CECONOMY Communications", "messages", "de");
t.add("label.newsboard", "Newsboard", "messages", "de");
t.add("label.pressreviewviewer", "Medienspiegel Archiv", "messages", "de");
t.add("nv.mrView.customer.config.label", "Sichtbarkeit Medienspiegel", "messages", "de");
t.add("nv.mrView.customer.config.button.label", "Konfigurieren der Sichtbarkeit", "messages", "de");
t.add("nv.mrView.customer.buttonChoice.config.label", "Konfiguration der Bearbeitungsart", "messages", "de");
t.add("nv.mrView.customer.buttonChoice.config.description", "Diese Konfiguration legt fest wie die Sichtbarkeit von Medienspiegeln in der Medienspiegelliste gesteuert werden soll. Die Schnellwahl erm\u00f6glicht den schnellen Wechsel von \"anzeigen\" zu \"nicht anzeigen\" zu \"Standard\". Die Einzelauswahl erm\u00f6glicht die Sichtbarkeit eines Medienspiegels f\u00fcr jede Ansicht einzeln festzulegen.", "messages", "de");
t.add("nv.mrView.customer.buttonChoice.label.noButton", "nicht bearbeitbar", "messages", "de");
t.add("nv.mrView.customer.buttonChoice.label.simpleChoiceButton", "Schnellwahl", "messages", "de");
t.add("nv.mrView.customer.buttonChoice.label.complexChoiceButton", "Einzelauswahl", "messages", "de");
t.add("nv.mrView.customer.viewRestriction.config.label", "Beschr\u00e4nkungen der Ansichten", "messages", "de");
t.add("nv.mrView.customer.viewRestriction.config.description", "Diese Konfiguration legt fest in welcher Ansicht die Aussteuerung der Sichtbarkeit von Medienspiegel geschehen soll.", "messages", "de");
t.add("nv.mrView.customer.defaults.config.label", "Standards f\u00fcr die Sichtbarkeit der Medienspiegel", "messages", "de");
t.add("nv.mrView.customer.defaults.config.description", "Diese Konfiguration steuert in welcher Ansicht Medienspiegel angezeigt werden sollen, wenn die Sichtbarkeit dieser Medienspiegel noch nicht in der Medienspiegelliste festgelegt worden ist. Die Einstellungen kommen jedoch nur dann zum Tragen, wenn der aufrufende Nutzer nicht eigene Einschr\u00e4nkungen definiert hat.", "messages", "de");
t.add("nv.mrView.user.defaults.config.description", "Diese Konfiguration steuert in welcher Ansicht Medienspiegel angezeigt werden sollen, wenn die Sichtbarkeit dieser Medienspiegel noch nicht in der Medienspiegelliste festgelegt worden ist.", "messages", "de");
t.add("nv.mrView.visibility.customer", "Kundeneinstellungen \u00fcbernehmen", "messages", "de");
t.add("nv.mrView.visibility.true", "anzeigen", "messages", "de");
t.add("nv.mrView.visibility.false", "nicht anzeigen", "messages", "de");
t.add("mrView.visibility.visible", "anzeigen", "messages", "de");
t.add("mrView.visibility.default", "Standard", "messages", "de");
t.add("mrView.visibility.notVisible", "nicht anzeigen", "messages", "de");
t.add("mrView.visibility.individual", "individuell", "messages", "de");
t.add("mrView.visibility.tooltip.prefix", "Sichtbarkeit: ", "messages", "de");
t.add("mrView.visibility.headline", "Sichtbarkeit bearbeiten", "messages", "de");
t.add("nv.mrArchiveConfig.customer.config.label", "Medienspiegel Archiv Konfiguration", "messages", "de");
t.add("nv.mrDisabled.label", "Medienspiegel deaktiviert (Aus-Zu)", "messages", "de");
t.add("nv.mrArchiveConfig.customer.config.shortLabel", "Medienspiegel Archiv Konfig.", "messages", "de");
t.add("nv.mrArchiveConfig.customer.config.button.label", "Medienspiegel Archiv konfigurieren", "messages", "de");
t.add("nv.mrArchiveConfig.customer.configure.mediareviewlist", "Medienspiegelliste konfigurieren", "messages", "de");
t.add("nv.mrArchiveConfig.customer.configure.mediareviewview", "Medienspiegelansicht konfigurieren", "messages", "de");
t.add("nv.mrArchiveConfig.customer.configure.showLicencedDocuments", "Lizenzpflichtige Dokumente anzeigen", "messages", "de");
t.add("nv.mrArchiveConfig.customer.configure.showLastDays.part1", "Anzeige der Medienspiegel, der letzten ", "messages", "de");
t.add("nv.mrArchiveConfig.customer.configure.showLastDays.part2", " Tage.", "messages", "de");
t.add("nv.mrArchiveConfig.error.errors", "Folgende Fehler sind aufgetreten:", "messages", "de");
t.add("nv.mrArchiveConfig.error.blankName", "Der Name darf nicht leer sein.", "messages", "de");
t.add("nv.mrArchiveConfig.error.blankDaysToShow", "Die Anzahl der Tage darf nicht leer sein.", "messages", "de");
t.add("nv.mrArchiveConfig.error.notNumericDaysToShow", "Nur Ziffern sind bei der Anzahl der Tage erlaubt.", "messages", "de");
t.add("nv.mrArchiveConfig.user.useCustomerSettings", "Kundeneinstellungen \u00fcbernehmen", "messages", "de");
t.add("work.mraOrderConfigAdmin", "MRA Auftrags-Konfiguration", "messages", "de");
t.add("work.newsAdmin", "News Verwaltung", "messages", "de");
t.add("profile.show.username", "Benutzername", "messages", "de");
t.add("profile.show.email", "E-Mail", "messages", "de");
t.add("change_password.submit", "Passwort \u00e4ndern", "messages", "de");
t.add("security.login.username", "Benutzername", "messages", "de");
t.add("security.login.password", "Passwort", "messages", "de");
t.add("security.login.remember_me", "An mich erinnern", "messages", "de");
t.add("security.login.submit", "Anmelden", "messages", "de");
t.add("form.group_name", "Gruppenname", "messages", "de");
t.add("form.username", "Benutzername", "messages", "de");
t.add("form.email", "E-Mail-Adresse", "messages", "de");
t.add("form.current_password", "Derzeitiges Passwort", "messages", "de");
t.add("form.password", "Passwort", "messages", "de");
t.add("form.password_confirmation", "Passwort best\u00e4tigen", "messages", "de");
t.add("form.new_password", "Neues Passwort", "messages", "de");
t.add("form.new_password_confirmation", "Neues Passwort best\u00e4tigen", "messages", "de");
t.add("n.includeHitsLabel", "Hits", "messages", "de");
t.add("n.includeAllWithSubClippings", "ALL media\/passive hit", "messages", "de");
t.add("n.includeOnlyMainClippings", "ONLY main issue\/first hit", "messages", "de");
t.add("map.HR", "Kroatien", "messages", "de");
t.add("map.CV", "Kap Verde", "messages", "de");
t.add("map.DJ", "Dschibuti", "messages", "de");
t.add("map.DK", "D\u00e4nemark", "messages", "de");
t.add("map.DM", "Dominica", "messages", "de");
t.add("map.GY", "Guyana", "messages", "de");
t.add("map.SV", "El Salvador", "messages", "de");
t.add("map.ET", "\u00c4thiopien", "messages", "de");
t.add("map.FR", "Frankreich", "messages", "de");
t.add("map.FI", "Finnland", "messages", "de");
t.add("map.FJ", "Fidschi", "messages", "de");
t.add("map.GA", "Gabun", "messages", "de");
t.add("map.IT", "Italien", "messages", "de");
t.add("map.ID", "Indonesien", "messages", "de");
t.add("map.IE", "Irland", "messages", "de");
t.add("map.IM", "Isle of Man", "messages", "de");
t.add("map.IN", "Indien", "messages", "de");
t.add("map.IO", "Britisches Territorium im Indischen Ozean", "messages", "de");
t.add("map.VU", "Vanuatu", "messages", "de");
t.add("map.WS", "Samoa", "messages", "de");
t.add("map.CM", "Kamerun", "messages", "de");
t.add("map.CC", "Kokosinseln", "messages", "de");
t.add("map.CD", "DR Kongo", "messages", "de");
t.add("map.CH", "Schweiz", "messages", "de");
t.add("map.CI", "Elfenbeink\u00fcste", "messages", "de");
t.add("map.CK", "Cookinseln", "messages", "de");
t.add("map.CL", "Chile", "messages", "de");
t.add("map.CN", "China", "messages", "de");
t.add("map.CX", "Christmas Island", "messages", "de");
t.add("map.CY", "Zypern", "messages", "de");
t.add("map.CZ", "Tschechien", "messages", "de");
t.add("map.DE", "Deutschland", "messages", "de");
t.add("map.HK", "Hongkong", "messages", "de");
t.add("map.HU", "Ungarn", "messages", "de");
t.add("map.IR", "Iran", "messages", "de");
t.add("map.IQ", "Irak", "messages", "de");
t.add("map.DO", "Dominikanische Republik", "messages", "de");
t.add("map.EC", "Ecuador", "messages", "de");
t.add("map.EE", "Estland", "messages", "de");
t.add("map.ER", "Eritrea", "messages", "de");
t.add("map.EU", "Europa", "messages", "de");
t.add("map.GB", "Gro\u00dfbritannien", "messages", "de");
t.add("map.GD", "Grenada", "messages", "de");
t.add("map.KR", "S\u00fcdkorea", "messages", "de");
t.add("map.KG", "Kirgisistan", "messages", "de");
t.add("map.KW", "Kuwait", "messages", "de");
t.add("map.KY", "Cayman Islands", "messages", "de");
t.add("map.KZ", "Kasachstan", "messages", "de");
t.add("map.LU", "Luxemburg", "messages", "de");
t.add("map.LA", "Laos", "messages", "de");
t.add("map.LI", "Liechtenstein", "messages", "de");
t.add("map.LK", "Sri Lanka", "messages", "de");
t.add("map.LR", "Liberia", "messages", "de");
t.add("map.LS", "Lesotho", "messages", "de");
t.add("map.LT", "Litauen", "messages", "de");
t.add("map.LV", "Lettland", "messages", "de");
t.add("map.LY", "Libyen", "messages", "de");
t.add("map.MA", "Marokko", "messages", "de");
t.add("map.MC", "Monaco", "messages", "de");
t.add("map.MD", "Moldawien", "messages", "de");
t.add("map.MG", "Madagaskar", "messages", "de");
t.add("map.MN", "Mongolei", "messages", "de");
t.add("map.GE", "Georgien", "messages", "de");
t.add("map.GH", "Ghana", "messages", "de");
t.add("map.GI", "Gibraltar", "messages", "de");
t.add("map.GL", "Gr\u00f6nland", "messages", "de");
t.add("map.GM", "Gambia", "messages", "de");
t.add("map.GN", "Guinea", "messages", "de");
t.add("map.GQ", "\u00c4quatorialguinea", "messages", "de");
t.add("map.GT", "Guatemala", "messages", "de");
t.add("map.GU", "Guam", "messages", "de");
t.add("map.GW", "Guinea-Bissau", "messages", "de");
t.add("map.OM", "Oman", "messages", "de");
t.add("map.PA", "Panama", "messages", "de");
t.add("map.PE", "Peru", "messages", "de");
t.add("map.NE", "Niger", "messages", "de");
t.add("map.IS", "Island", "messages", "de");
t.add("map.IL", "Israel", "messages", "de");
t.add("map.JM", "Jamaika", "messages", "de");
t.add("map.RU", "Russland", "messages", "de");
t.add("map.RW", "Ruanda", "messages", "de");
t.add("map.SA", "Saudi-Arabien", "messages", "de");
t.add("map.SC", "Seychellen", "messages", "de");
t.add("map.SD", "Sudan", "messages", "de");
t.add("map.SO", "Somalia", "messages", "de");
t.add("map.SB", "Salomonen", "messages", "de");
t.add("map.SR", "Suriname", "messages", "de");
t.add("map.RS", "Serbien", "messages", "de");
t.add("map.SS", "S\u00fcdsudan", "messages", "de");
t.add("map.TN", "Tunesien", "messages", "de");
t.add("map.TO", "Tonga", "messages", "de");
t.add("map.TR", "T\u00fcrkei", "messages", "de");
t.add("map.US", "USA", "messages", "de");
t.add("map.UY", "Uruguay", "messages", "de");
t.add("map.UZ", "Usbekistan", "messages", "de");
t.add("map.VA", "Staat Vatikanstadt", "messages", "de");
t.add("map.VE", "Venezuela", "messages", "de");
t.add("map.VG", "Britische Jungferninseln", "messages", "de");
t.add("map.VI", "Amerikanischen Jungferninseln", "messages", "de");
t.add("map.VN", "Vietnam", "messages", "de");
t.add("map.VC", "St. Vincent und die Grenadinen", "messages", "de");
t.add("map.GF", "Franz\u00f6sisch-Guayana", "messages", "de");
t.add("map.JE", "Jersey", "messages", "de");
t.add("map.JO", "Jordanien", "messages", "de");
t.add("map.KP", "Nordkorea", "messages", "de");
t.add("map.CO", "Kolumbien", "messages", "de");
t.add("map.KM", "Komoren", "messages", "de");
t.add("map.CR", "Costa Rica", "messages", "de");
t.add("map.DZ", "Algerien", "messages", "de");
t.add("map.ES", "Spanien", "messages", "de");
t.add("map.UG", "Uganda", "messages", "de");
t.add("map.FO", "F\u00e4r\u00f6er", "messages", "de");
t.add("map.FM", "Mikronesien", "messages", "de");
t.add("map.MH", "Marshallinseln", "messages", "de");
t.add("map.MK", "Mazedonien", "messages", "de");
t.add("map.ML", "Mali", "messages", "de");
t.add("map.ME", "Montenegro", "messages", "de");
t.add("map.MO", "Macau", "messages", "de");
t.add("map.MZ", "Mosambik", "messages", "de");
t.add("map.MP", "Northern Mariana Islands", "messages", "de");
t.add("map.MQ", "Martinique", "messages", "de");
t.add("map.MU", "Mauritius", "messages", "de");
t.add("map.MT", "Malta", "messages", "de");
t.add("map.MV", "Malediven", "messages", "de");
t.add("map.MW", "Malawi", "messages", "de");
t.add("map.MX", "Mexiko", "messages", "de");
t.add("map.MY", "Malaysia", "messages", "de");
t.add("map.MM", "Myanmar", "messages", "de");
t.add("map.GR", "Griechenland", "messages", "de");
t.add("map.HN", "Honduras", "messages", "de");
t.add("map.PH", "Philippinen", "messages", "de");
t.add("map.PL", "Polen", "messages", "de");
t.add("map.PG", "Papua-Neuguinea", "messages", "de");
t.add("map.JP", "Japan", "messages", "de");
t.add("map.KH", "Kambodscha", "messages", "de");
t.add("map.KE", "Kenia", "messages", "de");
t.add("map.KN", "Saint Kitts And Nevis", "messages", "de");
t.add("map.NO", "Norwegen", "messages", "de");
t.add("map.NA", "Namibia", "messages", "de");
t.add("map.NC", "Neukaledonien", "messages", "de");
t.add("map.NP", "Nepal", "messages", "de");
t.add("map.NG", "Nigeria", "messages", "de");
t.add("map.NI", "Nicaragua", "messages", "de");
t.add("map.NL", "Niederlande", "messages", "de");
t.add("map.NU", "Niue", "messages", "de");
t.add("map.NZ", "Neuseeland", "messages", "de");
t.add("map.PR", "Puerto Rico", "messages", "de");
t.add("map.PS", "Pal\u00e4stina", "messages", "de");
t.add("map.PT", "Portugal", "messages", "de");
t.add("map.PY", "Paraguay", "messages", "de");
t.add("map.PF", "Franz\u00f6sisch-Polynesien", "messages", "de");
t.add("map.QA", "Katar", "messages", "de");
t.add("map.CF", "Zentralafrikanische Republik", "messages", "de");
t.add("map.CG", "Republik Kongo", "messages", "de");
t.add("map.HT", "Haiti", "messages", "de");
t.add("map.MR", "Mauretanien", "messages", "de");
t.add("map.XK", "Kosovo", "messages", "de");
t.add("map.SE", "Schweden", "messages", "de");
t.add("map.SG", "Singapur", "messages", "de");
t.add("map.SK", "Slowakei", "messages", "de");
t.add("map.SL", "Sierra Leone", "messages", "de");
t.add("map.SI", "Slowenien", "messages", "de");
t.add("map.SM", "San Marino", "messages", "de");
t.add("map.SN", "Senegal", "messages", "de");
t.add("map.LC", "St. Lucia", "messages", "de");
t.add("map.TT", "Trinidad und Tobago", "messages", "de");
t.add("map.TV", "Tuvalu", "messages", "de");
t.add("map.TZ", "Tansania", "messages", "de");
t.add("map.UA", "Ukraine", "messages", "de");
t.add("map.AE", "Vereinigte Arabische Emirate", "messages", "de");
t.add("map.PK", "Pakistan", "messages", "de");
t.add("map.ST", "Sao Tome und Principe", "messages", "de");
t.add("map.SY", "Syrien", "messages", "de");
t.add("map.SZ", "Swaziland", "messages", "de");
t.add("map.TW", "Taiwan", "messages", "de");
t.add("map.TC", "Turks And Caicos Islands", "messages", "de");
t.add("map.TD", "Tschad", "messages", "de");
t.add("map.YE", "Jemen", "messages", "de");
t.add("map.YU", "Jugoslawien", "messages", "de");
t.add("map.ZM", "Sambia", "messages", "de");
t.add("map.ZA", "S\u00fcdafrika", "messages", "de");
t.add("map.ZW", "Simbabwe", "messages", "de");
t.add("map.TG", "Togo", "messages", "de");
t.add("map.TH", "Thailand", "messages", "de");
t.add("map.TJ", "Tadschikistan", "messages", "de");
t.add("map.TL", "Osttimor", "messages", "de");
t.add("map.TM", "Turkmenistan", "messages", "de");
t.add("map.CA", "Kanada", "messages", "de");
t.add("map.AT", "\u00d6sterreich", "messages", "de");
t.add("map.EG", "\u00c4gypten", "messages", "de");
t.add("map.AF", "Afghanistan", "messages", "de");
t.add("map.AL", "Albanien", "messages", "de");
t.add("map.AG", "Antigua und Barbuda", "messages", "de");
t.add("map.AI", "Anguilla", "messages", "de");
t.add("map.AM", "Armenien", "messages", "de");
t.add("map.AN", "Niederl\u00e4ndischen Antillen", "messages", "de");
t.add("map.AD", "Andorra", "messages", "de");
t.add("map.AO", "Angola", "messages", "de");
t.add("map.AR", "Argentinien", "messages", "de");
t.add("map.BG", "Bulgarien", "messages", "de");
t.add("map.AS", "Amerikanisch-Samoa", "messages", "de");
t.add("map.AU", "Australien", "messages", "de");
t.add("map.AW", "Aruba", "messages", "de");
t.add("map.AZ", "Aserbaidschan", "messages", "de");
t.add("map.BE", "Belgien", "messages", "de");
t.add("map.BD", "Bangladesch", "messages", "de");
t.add("map.BB", "Barbados", "messages", "de");
t.add("map.BF", "Burkina Faso", "messages", "de");
t.add("map.BT", "Bhutan", "messages", "de");
t.add("map.BA", "Bosnien und Herzegowina", "messages", "de");
t.add("map.BJ", "Benin", "messages", "de");
t.add("map.BM", "Bermuda", "messages", "de");
t.add("map.BO", "Bolivien", "messages", "de");
t.add("map.BR", "Brasilien", "messages", "de");
t.add("map.BH", "Bahrain", "messages", "de");
t.add("map.BN", "Brunei", "messages", "de");
t.add("map.BS", "Bahamas", "messages", "de");
t.add("map.BI", "Burundi", "messages", "de");
t.add("map.BW", "Botswana", "messages", "de");
t.add("map.BY", "Wei\u00dfrussland", "messages", "de");
t.add("map.BZ", "Belize", "messages", "de");
t.add("map.CU", "Kuba", "messages", "de");
t.add("map.LB", "Libanon", "messages", "de");
t.add("map.RO", "Rum\u00e4nien", "messages", "de");
})(Translator);
