(function (t) {
// en
t.add("l.layoutExampleHeadline", "Simple customer-specific layout example", "messages", "en");
t.add("l.betaNote", ".#BETA", "messages", "en");
t.add("title", "ARGUS Portal", "messages", "en");
t.add("footerTitle", "ARGUS DATA INSIGHTS", "messages", "en");
t.add("footerImpressum", "Imprint", "messages", "en");
t.add("footerChangeDisplayMode", "Change to ARGUS Portal desktop mode", "messages", "en");
t.add("or", "or", "messages", "en");
t.add("d.dateformat", "m\/d\/Y", "messages", "en");
t.add("d.timeformat", "h:i A", "messages", "en");
t.add("d.timeformatLong", "h:i:s A", "messages", "en");
t.add("d.dateIntervalFormat", "%D:%M:%Y", "messages", "en");
t.add("d.timeIntervalFormat", "%H:%I:%S", "messages", "en");
t.add("f.dateformat", "mm\/dd\/yyyy", "messages", "en");
t.add("ntf.dateformat", "MM\/dd\/yyyy", "messages", "en");
t.add("ntf.dateformat_js", "mm\/dd\/yyyy", "messages", "en");
t.add("d.dateformat_excel", "MM\\\/dd\\\/yyyy", "messages", "en");
t.add("d.datetimeformat_excel", "MM\\\/dd\\\/yyyy h:mm AM\/PM", "messages", "en");
t.add("d.datetimeformat_weekday_locale", "l, jS F Y", "messages", "en");
t.add("d.weekday_1", "Monday", "messages", "en");
t.add("d.weekday_2", "Tuesday", "messages", "en");
t.add("d.weekday_3", "Wednesday", "messages", "en");
t.add("d.weekday_4", "Thursday", "messages", "en");
t.add("d.weekday_5", "Friday", "messages", "en");
t.add("d.weekday_6", "Saturday", "messages", "en");
t.add("d.weekday_7", "Sunday", "messages", "en");
t.add("d.month_1", "January", "messages", "en");
t.add("d.month_2", "February", "messages", "en");
t.add("d.month_3", "March", "messages", "en");
t.add("d.month_4", "April", "messages", "en");
t.add("d.month_5", "May", "messages", "en");
t.add("d.month_6", "June", "messages", "en");
t.add("d.month_7", "July", "messages", "en");
t.add("d.month_8", "August", "messages", "en");
t.add("d.month_9", "September", "messages", "en");
t.add("d.month_10", "October", "messages", "en");
t.add("d.month_11", "November", "messages", "en");
t.add("d.month_12", "December", "messages", "en");
t.add("d.oclock", "o'clock", "messages", "en");
t.add("ali.dateformat", "MM\/dd\/yyyy", "messages", "en");
t.add("n.datetime", "Y-m-d h:i A", "messages", "en");
t.add("n.datetimeHeader", "l, F j, Y h:i A", "messages", "en");
t.add("n.dateArticle", "Y-m-d", "messages", "en");
t.add("n.decimalpoint", ".", "messages", "en");
t.add("n.thousandsseperator", ",", "messages", "en");
t.add("n.EURO", "EUR", "messages", "en");
t.add("n.million", "mi.", "messages", "en");
t.add("btn.cancel", "Cancel", "messages", "en");
t.add("btn.transfer", "Transfer", "messages", "en");
t.add("btn.save", "Save", "messages", "en");
t.add("btn.save_email_group", "Save e-mail group", "messages", "en");
t.add("btn.add_email_group", "Add e-mail group", "messages", "en");
t.add("btn.add_email", "Add e-mail", "messages", "en");
t.add("btn.exportEmails", "Export e-mails", "messages", "en");
t.add("btn.importEmails", "Import e-mails", "messages", "en");
t.add("btn.send_immediately", "Send immediately", "messages", "en");
t.add("btn.send_immediately_warn", "Newsletter will be sent after confirmation!", "messages", "en");
t.add("btn.choose", "Choose", "messages", "en");
t.add("btn.apply", "Apply", "messages", "en");
t.add("btn.reset", "Reset", "messages", "en");
t.add("btn.delete", "Delete", "messages", "en");
t.add("btn.remove", "Remove", "messages", "en");
t.add("btn.upload", "Upload", "messages", "en");
t.add("btn.keep", "Keep", "messages", "en");
t.add("btn.bulkEditMra", "Edit Analysis data", "messages", "en");
t.add("btn.edit", "Edit", "messages", "en");
t.add("btn.ok", "Ok", "messages", "en");
t.add("btn.close", "Close", "messages", "en");
t.add("btn.print", "Print", "messages", "en");
t.add("btn.send", "Send", "messages", "en");
t.add("btn.yes", "Yes", "messages", "en");
t.add("btn.no", "No", "messages", "en");
t.add("btn.goOn", "Next", "messages", "en");
t.add("btn.back", "Back", "messages", "en");
t.add("val.saved", "Saved", "messages", "en");
t.add("val.error", "An error occurred", "messages", "en");
t.add("val.invalidEmailRemovedHint", "Invalid email address was removed", "messages", "en");
t.add("val.invalidEmailsRemovedHint", "Invalid email addresses were removed", "messages", "en");
t.add("error.sqlstate", "An internal system error has occurred. Please try again later.", "messages", "en");
t.add("Tag", "Tag", "messages", "en");
t.add("Bewertung", "Rating", "messages", "en");
t.add("Header", "Header", "messages", "en");
t.add("Medium", "Medium", "messages", "en");
t.add("Datum", "Date", "messages", "en");
t.add("logo", "Logo", "messages", "en");
t.add("psptitle", "media review title", "messages", "en");
t.add("freetext", "freetext", "messages", "en");
t.add("date", "date", "messages", "en");
t.add("pageNumber", "page number", "messages", "en");
t.add("sortSysEdatum", "date created", "messages", "en");
t.add("sortSysMdatum", "date modified", "messages", "en");
t.add("sortPspName", "title", "messages", "en");
t.add("sortSysEuser", "creator", "messages", "en");
t.add("deletedButNotGone", "Trash", "messages", "en");
t.add("and", "and", "messages", "en");
t.add("everything", "all", "messages", "en");
t.add("pmgNote", "The newsletters and clippings that we provide are duly licensed for media monitoring and distribution to you. <br\/> Please be aware that it is your duty to ensure any further distribution of clippings and\/or links you choose to undertake is not in breach of your contractual obligations, or of copyright law.\n", "messages", "en");
t.add("archivistOnlyNote", "This press review was created under the advanced archive law and conforms to its requirements and guidelines.", "messages", "en");
t.add("l.orders", "Orders", "messages", "en");
t.add("l.clippings", "Clippings", "messages", "en");
t.add("l.dashboard", "Dashboard", "messages", "en");
t.add("l.work", "Work", "messages", "en");
t.add("l.customlink1", "Custom link", "messages", "en");
t.add("l.profile", "Profile", "messages", "en");
t.add("l.help", "Help", "messages", "en");
t.add("l.options", "Options", "messages", "en");
t.add("l.feedback", "Feedback", "messages", "en");
t.add("l.logout", "Logout", "messages", "en");
t.add("l.useradmin", "User administration", "messages", "en");
t.add("l.tagadmin", "Tags", "messages", "en");
t.add("l.folderadmin", "Folder", "messages", "en");
t.add("l.newsletteradmin", "Newsletter", "messages", "en");
t.add("l.aliadmin", "Real-time monitoring", "messages", "en");
t.add("l.pspadmin", "Media reviews", "messages", "en");
t.add("l.sessionExpired", "Your session has expired.", "messages", "en");
t.add("l.companyName", "ARGUS DATA INSIGHTS", "messages", "en");
t.add("l.portalManual", "Guidebook (German only)", "messages", "en");
t.add("l.portalManualFile", "201705_Portalhandbuch.pdf", "messages", "en");
t.add("l.videoTut", "Tutorials", "messages", "en");
t.add("l.videoTut1", "Tutorial 1 - Edit Clippings", "messages", "en");
t.add("l.videoTut1File", "AUSSCHNITT_Portal_Tutorial_1_Clippings_bearbeiten.mp4", "messages", "en");
t.add("l.videoTut2", "Tutorial 2 - Searching & Filtering", "messages", "en");
t.add("l.videoTut2File", "AUSSCHNITT_Portal_Tutorial_2_Suchen_Filtern.mp4", "messages", "en");
t.add("l.videoTut3", "Tutorial 3 - Structuring & Exporting", "messages", "en");
t.add("l.videoTut3File", "AUSSCHNITT_Portal_Tutorial_3_Strukturiere_Exportieren.mp4", "messages", "en");
t.add("l.videoTut4", "Tutorial 4 - Setup Newsletters", "messages", "en");
t.add("l.videoTut4File", "AUSSCHNITT_Portal_Tutorial_4_Newsletter_einrichten.mp4", "messages", "en");
t.add("l.videoTut5", "Tutorial 5 - Uploading Contents", "messages", "en");
t.add("l.videoTut5File", "AUSSCHNITT_Portal_Tutorial_5_Inhalte_hochladen.mp4", "messages", "en");
t.add("l.videoTut6", "Tutorial 6 - Creating Media Reviews", "messages", "en");
t.add("l.videoTut6File", "AUSSCHNITT_Portal_Tutorial_6_Pressespiegel_erstellen.mp4", "messages", "en");
t.add("l.videoTut7", "Tutorial 7 - Visual Analysing Data", "messages", "en");
t.add("l.videoTut7File", "AUSSCHNITT_Portal_Tutorial_7_Daten_visuell_analysieren.mp4", "messages", "en");
t.add("l.videoTut8", "Tutorial 8 - Managing Users", "messages", "en");
t.add("l.videoTut8File", "AUSSCHNITT_Portal_Tutorial_8_Benutzer_verwalten.mp4", "messages", "en");
t.add("l.switchToMobileView", "Mobile View", "messages", "en");
t.add("l.switchToClassicView", "Classic View", "messages", "en");
t.add("l.viewMode", "View mode", "messages", "en");
t.add("l.defaultViewMode", "Default display", "messages", "en");
t.add("l.displayAuto", "Automatic", "messages", "en");
t.add("l.displayClassic", "Desktop mode", "messages", "en");
t.add("l.displayMobile", "Mobile mode", "messages", "en");
t.add("l.classic", "Desktop", "messages", "en");
t.add("l.mobile", "Mobil", "messages", "en");
t.add("l.lang", "En", "messages", "en");
t.add("l.menuEntry", "Menu entry", "messages", "en");
t.add("l.showMenuEntry", "Show menu entry", "messages", "en");
t.add("lp.pleaseNotice", "Please note:", "messages", "en");
t.add("lp.ie", "Using a outdated version of your browser", "messages", "en");
t.add("lp.ie8", "the service can still be used but with limited functionality.", "messages", "en");
t.add("lp.ie9", "you will experience reduced performance.", "messages", "en");
t.add("lp.noCookies", "You have disabled cookies in your browser and cannot use this service.", "messages", "en");
t.add("lp.noJs", "You have disabled JavaScript in your browser and cannot use this service.", "messages", "en");
t.add("l.authenticationCode", "Authentication code", "messages", "en");
t.add("l.enterAuthenticationCode", "Enter authentication code sent to your email", "messages", "en");
t.add("l.authenticationCodeEmailText", "Your authentication code is:", "messages", "en");
t.add("l.defaultlist", "Default list view", "messages", "en");
t.add("l.shortlist", "Short list view", "messages", "en");
t.add("l.selectAll", "Select all", "messages", "en");
t.add("l.deselectAll", "Deselect all", "messages", "en");
t.add("l.selection", "Selection", "messages", "en");
t.add("l.sort", "Sort", "messages", "en");
t.add("l.of", "of", "messages", "en");
t.add("l.sortByDatePublication", "Publication date", "messages", "en");
t.add("l.sortByProdDate", "Entry date", "messages", "en");
t.add("l.sortByDateProduced", "Production date", "messages", "en");
t.add("l.sortByCreatedAt", "Entry date", "messages", "en");
t.add("l.sortByDateUploaded", "Upload date", "messages", "en");
t.add("l.sortByTitle", "Headline", "messages", "en");
t.add("l.sortByMdTitle", "Medium title", "messages", "en");
t.add("l.sortByMdMart", "Media genre", "messages", "en");
t.add("l.sortByTonality", "Rating", "messages", "en");
t.add("l.sortByRelevance", "Relevance", "messages", "en");
t.add("l.sortByRange", "Range", "messages", "en");
t.add("l.clWOSubedition", "w\/o sub editions", "messages", "en");
t.add("l.checkAllUa", "Select all sub editions", "messages", "en");
t.add("l.selectArticleFromCurrentPagecheckAllUa", "Select current page", "messages", "en");
t.add("l.autoreload", "Reload automatically", "messages", "en");
t.add("l.includeFilter", "Include filter in export", "messages", "en");
t.add("l.useActiveFilter", "Use active filter", "messages", "en");
t.add("l.reload", "Reload list", "messages", "en");
t.add("l.upload", "Upload", "messages", "en");
t.add("l.relevanz", "Relevance", "messages", "en");
t.add("l.shoppingCartIcon", "Add to shopping cart", "messages", "en");
t.add("l.show", "show", "messages", "en");
t.add("l.rename", "rename", "messages", "en");
t.add("l.previous", "previous", "messages", "en");
t.add("l.next", "next", "messages", "en");
t.add("l.shareFacebook", "Share on Facebook", "messages", "en");
t.add("l.shareTwitter", "Share on X", "messages", "en");
t.add("l.shareLinkedin", "Share on Linkedin", "messages", "en");
t.add("l.shareTeams", "Share on Teams", "messages", "en");
t.add("l.artikel", "Article", "messages", "en");
t.add("l.analyse", "Analysis", "messages", "en");
t.add("l.medientitel", "Media title", "messages", "en");
t.add("l.headline", "Headline", "messages", "en");
t.add("I.link", "Link", "messages", "en");
t.add("l.untertitel", "Subtitle", "messages", "en");
t.add("l.addData", "Additional data", "messages", "en");
t.add("l.date", "Date", "messages", "en");
t.add("l.addOnlineIcons", "Share link", "messages", "en");
t.add("l.tvthumbnail", "Preview image", "messages", "en");
t.add("l.tvCutmarks", "Cutmarks", "messages", "en");
t.add("l.tvCutmarksStart", "Start", "messages", "en");
t.add("l.tvCutmarksEnd", "End", "messages", "en");
t.add("l.tvCutmarksLength", "Length", "messages", "en");
t.add("l.suchbegriffe", "Search terms", "messages", "en");
t.add("l.suchwortumgebung", "Search term context", "messages", "en");
t.add("l.aliSuchprofil", "Search agent", "messages", "en");
t.add("l.teaser", "Teaser", "messages", "en");
t.add("l.presssummary", "Press summary", "messages", "en");
t.add("l.rubrik", "Category", "messages", "en");
t.add("l.kundenrubrik", "User sector", "messages", "en");
t.add("l.seite", "Page", "messages", "en");
t.add("l.erscheinungsdatum", "Publication date", "messages", "en");
t.add("l.produktionsdatum", "Production date", "messages", "en");
t.add("l.tags", "Tags", "messages", "en");
t.add("l.ordner", "Folder", "messages", "en");
t.add("l.verfuegbaretags", "Available tags", "messages", "en");
t.add("l.kommentare", "Comments", "messages", "en");
t.add("l.auftrag", "Order", "messages", "en");
t.add("l.abo", "Subject", "messages", "en");
t.add("l.abo_s", "Subject(s)", "messages", "en");
t.add("l.abo_converted", "Converted into subject(s)", "messages", "en");
t.add("l.converted_into", "Converted into", "messages", "en");
t.add("l.treffernummer", "ID", "messages", "en");
t.add("l.medium", "Medium", "messages", "en");
t.add("l.mediumId", "Medium ID", "messages", "en");
t.add("l.mkz", "Types of ratio", "messages", "en");
t.add("l.externalLink", "External link", "messages", "en");
t.add("l.internalLink", "Internal link", "messages", "en");
t.add("l.ausgabe", "Issue number", "messages", "en");
t.add("l.medienart", "Media genre", "messages", "en");
t.add("l.medientyp", "Media type", "messages", "en");
t.add("l.medienbranche", "Media sector", "messages", "en");
t.add("l.erscheinungsweise", "Frequency", "messages", "en");
t.add("l.redaktion", "Editorial office", "messages", "en");
t.add("l.verlag", "Publisher", "messages", "en");
t.add("l.verlagfamilie", "Publishing group", "messages", "en");
t.add("l.sender", "Broadcast station", "messages", "en");
t.add("l.bundesland", "Federal state", "messages", "en");
t.add("l.land", "Country", "messages", "en");
t.add("l.nielsen", "Nielsen", "messages", "en");
t.add("l.sprache", "Language", "messages", "en");
t.add("l.thema", "Topic", "messages", "en");
t.add("l.aaew", "AVE Total", "messages", "en");
t.add("l.mraAufmerksamkeit", "Attention", "messages", "en");
t.add("l.mraAufmerksamkeitArt", "Attention genre", "messages", "en");
t.add("l.mraDarstellungsform", "Display format", "messages", "en");
t.add("l.mraDarstellungsformArt", "Display format genre", "messages", "en");
t.add("l.mraUrheber", "Author", "messages", "en");
t.add("l.mraUrheberKategorie", "Author category", "messages", "en");
t.add("I.mraIssues", "Actors quoted", "messages", "en");
t.add("I.mraThema", "MRA-Subject", "messages", "en");
t.add("I.portalAlertValue", "True", "messages", "en");
t.add("I.summaryValue", "True", "messages", "en");
t.add("I.uploadsValue", "True", "messages", "en");
t.add("I.abstractsValue", "True", "messages", "en");
t.add("I.aaewValue", "True", "messages", "en");
t.add("l.trefferPm", "Press release", "messages", "en");
t.add("l.wiedergabeKorrekt", "correct Repetition", "messages", "en");
t.add("l.wiedergabeVollstaendig", "complete Repetition", "messages", "en");
t.add("l.mraQuelle", "Source", "messages", "en");
t.add("l.mraQuelleKategorie", "Source category", "messages", "en");
t.add("l.bewertung", "Evaluation", "messages", "en");
t.add("l.kommentarAdd", "Add comment", "messages", "en");
t.add("l.kommentarAddAll", "Visible to all users", "messages", "en");
t.add("l.rubrikAdd", "Add category", "messages", "en");
t.add("l.rubrikAddOwnImg", "Own images", "messages", "en");
t.add("l.rubrikAddNoImg", "No image", "messages", "en");
t.add("l.rubrikAddRemoveImg", "Remove image", "messages", "en");
t.add("l.tonalitaet", "Total rating", "messages", "en");
t.add("l.artikelfreigabe", "Approval", "messages", "en");
t.add("l.setzen", "set", "messages", "en");
t.add("l.beitragslaenge", "Clipping length", "messages", "en");
t.add("l.abstract", "Abstract", "messages", "en");
t.add("l.volltext", "Full text", "messages", "en");
t.add("l.kennzahlen", "Range", "messages", "en");
t.add("l.name", "Name", "messages", "en");
t.add("l.strasse", "Street", "messages", "en");
t.add("l.plz", "Zip code city", "messages", "en");
t.add("l.ort", "City", "messages", "en");
t.add("l.ortzusatz", "Additional details", "messages", "en");
t.add("l.meldung", "Note", "messages", "en");
t.add("l.dokument", "Documents", "messages", "en");
t.add("l.openLink", "Open clipping", "messages", "en");
t.add("l.new", "new", "messages", "en");
t.add("l.summaryRelatedArticles", "Other related articles", "messages", "en");
t.add("l.newPressSummary", "New Press Summary", "messages", "en");
t.add("l.summaries", "Summaries", "messages", "en");
t.add("l.vorschaubildtv", "Preview image (TV)", "messages", "en");
t.add("l.autor", "Author", "messages", "en");
t.add("l.mainEdition", "Main edition", "messages", "en");
t.add("l.socialMedia", "Social Media", "messages", "en");
t.add("l.feedsource", "Feed Source", "messages", "en");
t.add("l.inweiterenausgaben", "Article in further issues", "messages", "en");
t.add("l.erstausstrahlung", "First broadcast", "messages", "en");
t.add("l.wiederholungssendung", "Repeat", "messages", "en");
t.add("l.articleHasAnalysesData", "Please consider that at least one of the selected articles was annotated with analysis data which will be possibly overwritten.", "messages", "en");
t.add("l.warningNoAnalysesData", "You dont have permission to edit.", "messages", "en");
t.add("l.mraportalfields", "Analysis ARGUS Portal fields", "messages", "en");
t.add("l.bigLogoTitle", "to ARGUS DATA INSIGHTS webpage", "messages", "en");
t.add("l.bigLogoAlternative", "ARGUS DATA INSIGHTS", "messages", "en");
t.add("l.toWebpage", "to webpage", "messages", "en");
t.add("l.sentTime", "Time sent", "messages", "en");
t.add("l.sentBy", "Sent by", "messages", "en");
t.add("l.additionalInformation", "Additional informationen", "messages", "en");
t.add("l.recipients", "{0} recipient |{1} recipient |]1,Inf] recipients", "messages", "en");
t.add("l.emailDistributor", "email {0} distributor |{1} distributor |]1,Inf] distributors", "messages", "en");
t.add("l.recipientsNotVisible", "recipients not visible", "messages", "en");
t.add("l.includedInThisMediaReviews", "Included in this media reviews", "messages", "en");
t.add("l.sentAsEmail", "Sent as E-Mail", "messages", "en");
t.add("l.articleUsedIn", "Used in", "messages", "en");
t.add("l.clippingsChoice", "{0} clippings |{1} clipping |]1,Inf] clippings", "messages", "en");
t.add("l.bookmark", "Bookmark", "messages", "en");
t.add("l.linkToBookmark", "Link to bookmark", "messages", "en");
t.add("l.flagProcessed", "Mark: progressed", "messages", "en");
t.add("Nachweis-pdf", "pdf document", "messages", "en");
t.add("Nachweis-xml", "xml document", "messages", "en");
t.add("Nachweis-txt", "txt document", "messages", "en");
t.add("TV-Mitschnitt", "TV clip", "messages", "en");
t.add("TV-Mitschnitt MP4", "MP4 TV clip", "messages", "en");
t.add("TV-Mitschnitt ComVision", "TV clip ComVision", "messages", "en");
t.add("TV-Thumbnail", "TV thumbnail", "messages", "en");
t.add("l.wiederholungssendungen", "Rebroadcasting", "messages", "en");
t.add("l.selectedArticleMenueTitle", "Actions for selected clippings", "messages", "en");
t.add("l.selectedFeedContentMenueTitle", "Actions for selected feed contents", "messages", "en");
t.add("l.selectedArticleList", "Selected clippings", "messages", "en");
t.add("l.selectedFeedArticleList", "Selected feed contents", "messages", "en");
t.add("l.selectedPspList", "Selected press reviews", "messages", "en");
t.add("l.generatePSP", "Create media review", "messages", "en");
t.add("l.export", "Export", "messages", "en");
t.add("l.exportAction", "Export", "messages", "en");
t.add("l.alert", "Alert", "messages", "en");
t.add("l.autoAlert", "Auto-Alert", "messages", "en");
t.add("l.sendPerEmail", "Email", "messages", "en");
t.add("l.sendPerAlert", "Alert", "messages", "en");
t.add("l.newsletter-delivery", "Newsletter delivery", "messages", "en");
t.add("l.sendFeedContentPerEmail", "Email", "messages", "en");
t.add("l.sendFeedContentPerAlert", "Alert", "messages", "en");
t.add("l.sendPerEmailTitle", "Send via email", "messages", "en");
t.add("l.sendPerAlertTitle", "Send as alert", "messages", "en");
t.add("l.sendFeedContentPerEmailTitle", "Send via email", "messages", "en");
t.add("l.sendFeedContentPerAlertTitle", "Send as alert", "messages", "en");
t.add("l.sendClippingPerEmail", "Send clipping", "messages", "en");
t.add("l.wasSentAsAlert", "Was sent as alert", "messages", "en");
t.add("l.hide", "Hide", "messages", "en");
t.add("l.order", "Order", "messages", "en");
t.add("l.analysisChart", "Analysis chart", "messages", "en");
t.add("l.taggen", "Tagging", "messages", "en");
t.add("l.tagClipping", "Edit tags", "messages", "en");
t.add("l.newTag", "Create new tag", "messages", "en");
t.add("l.publicTag", "New tag visible to all users", "messages", "en");
t.add("l.removeFromTag", "Remove from current tag", "messages", "en");
t.add("l.copy2dir", "Folder", "messages", "en");
t.add("l.newDir", "Folder name", "messages", "en");
t.add("l.publicDir", "New folder visible to all users", "messages", "en");
t.add("l.removeFromDir", "Remove from current folder", "messages", "en");
t.add("l.folder", "Folder", "messages", "en");
t.add("l.tag", "Tag", "messages", "en");
t.add("l.deleteTagMsg", "Do you really want to delete %bez% \"%tagName%\"?", "messages", "en");
t.add("l.maxSelArticle", "Could not select more than %selArticleCnt% clippings.", "messages", "en");
t.add("l.coverLetter", "Cover letter", "messages", "en");
t.add("l.freeEmail", "Email (fill in)", "messages", "en");
t.add("l.content", "Content", "messages", "en");
t.add("l.newEntry", "New entry", "messages", "en");
t.add("l.articleSent", "The clipping has been sent.", "messages", "en");
t.add("l.articlesSent", "The clippings have been sent.", "messages", "en");
t.add("l.keepFeedContentSuccess", "The selected feed contents will be keept.", "messages", "en");
t.add("l.actionSuccess", "The action was successful.", "messages", "en");
t.add("l.actionError", "The action could not be executed.", "messages", "en");
t.add("l.someActionError", "The action could only be executed in parts.", "messages", "en");
t.add("l.provideErrorId", "Error number (please always provide): ", "messages", "en");
t.add("l.numberOfErrors", "Errors in %amount% of %selected% selected articles:", "messages", "en");
t.add("l.selectionError", "Please select a feed content first.", "messages", "en");
t.add("l.articlesSentError", "The clippings could not be sent successfully.", "messages", "en");
t.add("l.errorNothingSelected", "No clipping selected.", "messages", "en");
t.add("l.articleSelectionFrom", "Clippings selection of", "messages", "en");
t.add("l.textWrongRecipents", "The folowing recipents could not be reached:", "messages", "en");
t.add("l.textNoRecipents", "You have not entered a recipent.", "messages", "en");
t.add("l.coverLetterHeader", "has sent you the following message:", "messages", "en");
t.add("l.selArtMenuDeleteTreffer", "delete", "messages", "en");
t.add("l.deleteTreffer", "{0,1} Delete clipping |]1,Inf] Delete clippings?", "messages", "en");
t.add("l.deleteTrefferText", "{0} No clipping selected |{1} Do you really want to delete the selected clipping? |]1,Inf] Do you really want to delete selected clippings?", "messages", "en");
t.add("l.deleteTrefferDeletedText", "{0} No clipping selected |{1} %count% clipping was deleted. |]1,Inf] %count% clippings were deleted.", "messages", "en");
t.add("l.deleteTrefferNotDeletedText", "{0} No clipping selected |{1} %count% clipping could not be deleted because of missing permissions. |]1,Inf] %count% clippings could not be deleted because of missing permissions.", "messages", "en");
t.add("l.keepTreffer", "{0} No clipping selected |{1} Keep clipping |]1,Inf] Keep clippings", "messages", "en");
t.add("l.keepTrefferText", "{0} No clipping selected |{1} Do you really want to keep the selected clipping? |]1,Inf] Do you really want to keep selected clippings?", "messages", "en");
t.add("l.keepTrefferKeptText", "{0} No clipping selected |{1} %count% clipping was set to be kept. |]1,Inf] %count% clippings were set to be kept.", "messages", "en");
t.add("l.keepTrefferNotKeptText", "{0} No clipping selected |{1} %count% clipping could not set to be kept (Source is not Real-time monitoring). |]1,Inf] %count% clippings could not set to be kept (Sources are not Real-time monitoring).", "messages", "en");
t.add("l.keepFeedContent", "Keep", "messages", "en");
t.add("l.feedContentProcessed", "Processed", "messages", "en");
t.add("l.feedContentUnprocessed", "Unprocessed", "messages", "en");
t.add("l.sendFeedContent", "Send alert", "messages", "en");
t.add("l.addFeedToMediaReview", "Add to media review draft", "messages", "en");
t.add("l.showDetails", "show details", "messages", "en");
t.add("l.action", "Action", "messages", "en");
t.add("l.createTag", "Create tag", "messages", "en");
t.add("l.createFolder", "Create folder", "messages", "en");
t.add("l.copy2NewTag", "Mark with new tag", "messages", "en");
t.add("l.copy2Tag", "Mark with tag", "messages", "en");
t.add("l.delFromTag", "Remove tag", "messages", "en");
t.add("l.copy2NewFolder", "Create", "messages", "en");
t.add("l.copy2Folder", "Copy to folder", "messages", "en");
t.add("l.delFromFolder", "Remove from folder", "messages", "en");
t.add("l.errNameRequired", "Please enter name", "messages", "en");
t.add("l.articlesTaggedMobile", "Selected clipping was tagged.", "messages", "en");
t.add("l.articlesTagged", "Selected elements tagged with \"%name%\".", "messages", "en");
t.add("l.articles2folder", "Selected elements copied to folder \"%name%\".", "messages", "en");
t.add("l.articleTagDel", "Tag \"%name%\" removed from selected clippings.", "messages", "en");
t.add("l.articleFolderDel", "Selected elements removed from folder \"%name%\".", "messages", "en");
t.add("l.tagCreated", "Tag \"%name%\" created.", "messages", "en");
t.add("l.folderCreated", "Folder \"%name%\" created.", "messages", "en");
t.add("l.pressreview", "Media review", "messages", "en");
t.add("l.showArticle", "Show Clippings", "messages", "en");
t.add("l.showPsp", "Show Media Reviews", "messages", "en");
t.add("l.setSentiment", "Set rating", "messages", "en");
t.add("l.setCategory", "Set category", "messages", "en");
t.add("l.createCategory", "Create category", "messages", "en");
t.add("l.applySectionToMsp", "Apply category changes to current media review", "messages", "en");
t.add("l.categoryNotSelectedSetError", "Please choose a category!", "messages", "en");
t.add("l.categoryNotCreatedSetError", "Please enter a category name!", "messages", "en");
t.add("l.reuseMediaReviewArticles", "Consider previous usage of the articles in other media reviews", "messages", "en");
t.add("l.deselectChoice", "Do you want to deselect the chosen clippings?", "messages", "en");
t.add("l.rememberDeselectChoice", "remember choice", "messages", "en");
t.add("l.unsetDeselectChoice", "Unset Choice for automatically deselecting Clippings Selection after Batch Process", "messages", "en");
t.add("l.fileNotFound", "Sorry, the requested file does not exist:", "messages", "en");
t.add("l.noTag", "no tag", "messages", "en");
t.add("l.noSearchTerm", "no search term", "messages", "en");
t.add("l.noSearchProfile", "no search profile", "messages", "en");
t.add("l.noMediaType", "no media genre", "messages", "en");
t.add("l.noMediaType1", "no media type", "messages", "en");
t.add("l.noTotalRating", "no total rating", "messages", "en");
t.add("l.emailGroupBy", "Group clippings", "messages", "en");
t.add("l.emailGroupedBy", "Grouping:", "messages", "en");
t.add("l.emailGroupTag", "by tag", "messages", "en");
t.add("l.emailGroupSearchTerm", "by search term", "messages", "en");
t.add("l.emailGroupSearchProfile", "by search profile", "messages", "en");
t.add("l.emailGroupMediaType", "by media genre", "messages", "en");
t.add("l.emailGroupMediaType1", "by media type", "messages", "en");
t.add("l.emailGroupTotalRating", "by total rating", "messages", "en");
t.add("l.emailGroupUngrouped", "no group", "messages", "en");
t.add("l.emailGroupToc", "Table of contents with groups", "messages", "en");
t.add("l.example", "example", "messages", "en");
t.add("l.examples", "examples", "messages", "en");
t.add("l.updateStatusNothingToChange", "There are no clippings selected to edit.", "messages", "en");
t.add("l.updateRubrikenStatusSuccess", "The category was set successfully.", "messages", "en");
t.add("l.updateRubrikenStatusError", "Error while setting category.", "messages", "en");
t.add("l.removeRubrikenStatusSuccess", "Category removed successfully.", "messages", "en");
t.add("l.removeRubrikenStatusError", "Failure while removing category.", "messages", "en");
t.add("l.updateTonalitaetStatusSuccess", "Tonality was set successfully.", "messages", "en");
t.add("l.updateTonalitaetStatusError", "Error while setting tonality.", "messages", "en");
t.add("l.removeTonalitaetStatusSuccess", "Tonality removed successfully.", "messages", "en");
t.add("l.removeTonalitaetStatusError", "Failure while removing tonality.", "messages", "en");
t.add("l.updateMraStatusSuccess", "Analysis data of clippings succesfully changed.", "messages", "en");
t.add("l.updateMraStatusError", "An error occurred. Analysis data of clippings will not be changed.", "messages", "en");
t.add("l.updateReleaseStatusSuccess", "Release was set successfully.", "messages", "en");
t.add("l.updateReleaseStatusError", "Error while setting release.", "messages", "en");
t.add("l.disabledUserRightsTooltip", "You cannot choose this user right.", "messages", "en");
t.add("l.auftragRolesTooltip", "Order includes these user roles", "messages", "en");
t.add("l.auftragNoRolesTooltip", "This order has no user rights included", "messages", "en");
t.add("l.notifications", "Notifications", "messages", "en");
t.add("l.noNotificationsAvailable", "No notifications available", "messages", "en");
t.add("l.notificationTooltip", "{0} No new notifications |{1} One new notification |]1,Inf[ %count% new notifications", "messages", "en");
t.add("l.notificationManagement", "news management", "messages", "en");
t.add("l.addNotification", "Add news", "messages", "en");
t.add("l.emptyHTMLError", "This field cannot be empty", "messages", "en");
t.add("l.newsboardConfig", "Newsboard config", "messages", "en");
t.add("l.enable2fa", "Enable two factor authentication", "messages", "en");
t.add("l.enable2faIsEnabled", "Enabled", "messages", "en");
t.add("l.enable2faIsDisabled", "Disabled", "messages", "en");
t.add("l.setDefaultNewsboardConfig", "Set default newsboard config", "messages", "en");
t.add("l.newsboardConfigName_mm", "Media monitoring", "messages", "en");
t.add("l.newsboardConfigDescription_mm", "Default newsboard configuration for media monitoring.", "messages", "en");
t.add("l.newsboardConfigName_mr", "Media reviews", "messages", "en");
t.add("l.newsboardConfigDescription_mr", "Default newsboard configuration for media reviews.", "messages", "en");
t.add("l.newsboardConfigName_mr_summaries", "Media reviews with summaries", "messages", "en");
t.add("l.newsboardConfigDescription_mr_summaries", "Default newsboard configuration for media reviews with summaries.", "messages", "en");
t.add("l.newsboardConfigName_mr_analysis", "Media reviews & analysis", "messages", "en");
t.add("l.newsboardConfigDescription_mr_analysis", "Default newsboard configuration for media reviews and analysis.", "messages", "en");
t.add("l.newsboardConfigName_agency", "Agencies", "messages", "en");
t.add("l.newsboardConfigDescription_agency", "Default newsboard configuration for agencies.", "messages", "en");
t.add("l.defaultSentimentPositive", "positive", "messages", "en");
t.add("l.defaultSentimentNeutral", "neutral", "messages", "en");
t.add("l.defaultSentimentNegative", "negative", "messages", "en");
t.add("f.filter", "Filter", "messages", "en");
t.add("f.filterSearchMobile", "Fulltext search in clippings", "messages", "en");
t.add("f.admin", "Filter administration", "messages", "en");
t.add("f.name", "Name", "messages", "en");
t.add("f.dashboard", "Dashboards", "messages", "en");
t.add("f.newsletter", "Newsletters", "messages", "en");
t.add("f.rss", "Feeds", "messages", "en");
t.add("f.filterReset", "Reset current filter", "messages", "en");
t.add("f.auswahl", "Select", "messages", "en");
t.add("f.abo", "Subject", "messages", "en");
t.add("f.auftrag", "Order", "messages", "en");
t.add("f.auftragAll", "all orders", "messages", "en");
t.add("f.autor", "Author", "messages", "en");
t.add("f.branche", "Media sector", "messages", "en");
t.add("f.bundesland", "Federal state", "messages", "en");
t.add("f.dimension", "Dimension online", "messages", "en");
t.add("f.dokument", "With files", "messages", "en");
t.add("f.erscheinungsweise", "Frequency", "messages", "en");
t.add("f.international", "International", "messages", "en");
t.add("f.kategorie", "Category", "messages", "en");
t.add("f.land", "Country", "messages", "en");
t.add("f.medienart", "Media genre", "messages", "en");
t.add("f.medientyp", "Media type", "messages", "en");
t.add("f.medium", "Media", "messages", "en");
t.add("f.nielsen", "Nielsen", "messages", "en");
t.add("f.gesamtTonalitaet", "Total rating", "messages", "en");
t.add("f.tonalitaet", "Rating", "messages", "en");
t.add("f.volltext", "Full text", "messages", "en");
t.add("f.mrathema", "MRA-Subject", "messages", "en");
t.add("f.mratop", "Top Subject", "messages", "en");
t.add("f.mratext1", "Text 1", "messages", "en");
t.add("f.mratext2", "Text 2", "messages", "en");
t.add("f.mratext3", "Text 3", "messages", "en");
t.add("f.ordner", "Folder", "messages", "en");
t.add("f.expressrubrik", "Sector", "messages", "en");
t.add("f.summaryrubrik", "Summary sector", "messages", "en");
t.add("f.sprache", "Language", "messages", "en");
t.add("f.suchbegriff", "Search terms", "messages", "en");
t.add("f.tag", "Tag", "messages", "en");
t.add("f.upload", "Uploads", "messages", "en");
t.add("f.abstract", "Abstracts", "messages", "en");
t.add("f.unterausgabe", "Sub editions", "messages", "en");
t.add("f.lektoriert", "Not\/proofread", "messages", "en");
t.add("f.freigabe", "Approval", "messages", "en");
t.add("f.zeitraum", "Time period", "messages", "en");
t.add("f.treffernummer", "Reference number", "messages", "en");
t.add("f.prodId", "ProdId", "messages", "en");
t.add("f.prodInternId", "Reference number", "messages", "en");
t.add("f.aliSuchprofil", "Search agent", "messages", "en");
t.add("f.dienstleistung", "Service", "messages", "en");
t.add("f.pressespiegel", "Media Review", "messages", "en");
t.add("f.pressespiegelLast", "Last Media Review", "messages", "en");
t.add("f.sender", "Broadcast station Radio & tv", "messages", "en");
t.add("f.keinThemenBaum", "no MRA-Subject tree available", "messages", "en");
t.add("f.zeitbereich", "Time period", "messages", "en");
t.add("f.anmeldung", "Since last login", "messages", "en");
t.add("f.archiv", "Archive", "messages", "en");
t.add("f.heute", "Today", "messages", "en");
t.add("f.24hours", "Last 24 hours", "messages", "en");
t.add("f.48hours", "Last 48 hours", "messages", "en");
t.add("f.3tage", "Last 3 days", "messages", "en");
t.add("f.7tage", "This week", "messages", "en");
t.add("f.l7tage", "Last week", "messages", "en");
t.add("f.1monat", "This month", "messages", "en");
t.add("f.l1monat", "Last month", "messages", "en");
t.add("f.90tage", "Last 90 days", "messages", "en");
t.add("f.1jahr", "This year", "messages", "en");
t.add("f.l1jahr", "Last year", "messages", "en");
t.add("f.alle", "Over all fields", "messages", "en");
t.add("f.meldung", "Note", "messages", "en");
t.add("f.headline", "Headline", "messages", "en");
t.add("f.untertitel", "Subtitle", "messages", "en");
t.add("f.teaser", "Teaser", "messages", "en");
t.add("f.swu", "Search term context", "messages", "en");
t.add("f.kommentar", "Comment", "messages", "en");
t.add("f.summary", "Summary", "messages", "en");
t.add("f.feedsource", "Feed Source", "messages", "en");
t.add("f.portalAlert", "Sent as alert", "messages", "en");
t.add("f.portalReviewed", "Reviewed", "messages", "en");
t.add("f.lektoriertChoice", "Proofread", "messages", "en");
t.add("f.unlektoriertChoice", "Not proofread", "messages", "en");
t.add("f.un-lektoriertChoice", "No distinction", "messages", "en");
t.add("f.adhocChoice", "Ad hoc", "messages", "en");
t.add("f.freigabeAllChoice", "No constraint", "messages", "en");
t.add("f.freigabeOpenChoice", "Open", "messages", "en");
t.add("f.freigabeApprovedChoice", "Released", "messages", "en");
t.add("f.freigabeUnapprovedChoice", "Not released", "messages", "en");
t.add("f.landescodeD", "D", "messages", "en");
t.add("f.landescodeDACH", "D-A-CH", "messages", "en");
t.add("f.landescodeAll", "All countries", "messages", "en");
t.add("f.mediumMedienart", "Narrow media genre...", "messages", "en");
t.add("f.mrathemaWithAaew", "Exclusively with AVE Relevant", "messages", "en");
t.add("I.mrathemaWithAaewValue", "True", "messages", "en");
t.add("f.input", "Input", "messages", "en");
t.add("f.doc_all", "All", "messages", "en");
t.add("f.doc_pdf", "PDF", "messages", "en");
t.add("f.doc_video", "Video", "messages", "en");
t.add("f.doc_video_m", "TV clip", "messages", "en");
t.add("f.doc_video_p", "TV clip preview", "messages", "en");
t.add("f.doc_audio", "Audio", "messages", "en");
t.add("f.doc_audio_m", "Audio clip", "messages", "en");
t.add("f.doc_audio_p", "Audio clip preview", "messages", "en");
t.add("f.doc_txt", "Text", "messages", "en");
t.add("f.doc_table", "Table", "messages", "en");
t.add("f.doc_pres", "Presentation", "messages", "en");
t.add("f.doc_pic", "Picture", "messages", "en");
t.add("f.doc_office", "Office", "messages", "en");
t.add("f.analyse", "Own analysis", "messages", "en");
t.add("f.analyseEditTrue", "edited", "messages", "en");
t.add("f.analyseEditFalse", "not yet edited", "messages", "en");
t.add("f.choose", "Please choose...", "messages", "en");
t.add("f.chooseShort", "Choose...", "messages", "en");
t.add("f.searchChoice", "Search ...", "messages", "en");
t.add("f.searchMedium", "Search for media...", "messages", "en");
t.add("f.searchMediumURL", "Search for URL...", "messages", "en");
t.add("f.searchAdress", "Search for address...", "messages", "en");
t.add("f.searchAuftrag", "Search for order...", "messages", "en");
t.add("f.searchAutor", "Search for author...", "messages", "en");
t.add("f.newFilter", "Empty filter", "messages", "en");
t.add("f.setAsStartFilter", "Set start filter", "messages", "en");
t.add("f.unsetAsStartFilter", "Delete start filter", "messages", "en");
t.add("f.isGlobalFilter", "Visible to all", "messages", "en");
t.add("f.isNoGlobalFilter", "Visible only to me", "messages", "en");
t.add("f.overwrite", "Overwrite", "messages", "en");
t.add("f.delete", "Delete", "messages", "en");
t.add("f.filterDelete", "Delete filter", "messages", "en");
t.add("f.filterDeleteText", "Do you really want to delete the filter \"%filterName%\"?", "messages", "en");
t.add("f.filterAlreadyDeleted", "This filter has already been deleted.", "messages", "en");
t.add("f.filterDeleteTextWarning", "Warning:", "messages", "en");
t.add("f.filterDeleteDashboardText", "Filter is attached to a dashboard and will also be deleted.", "messages", "en");
t.add("f.filterDeleteFilterText", "Filter is used as a restricted filter. Users assigned to it will have no restricted view.", "messages", "en");
t.add("f.filterDeleteNewsletterText", "Filter is attached to a newsletter. Dispatch will be stopped.", "messages", "en");
t.add("f.aaewGesamt", "AVE Total", "messages", "en");
t.add("f.themenbaum", "Theme tree", "messages", "en");
t.add("f.medienarttyp", "Media genre and type", "messages", "en");
t.add("f.von", "from", "messages", "en");
t.add("f.bis", "to", "messages", "en");
t.add("f.dl0", "Media Monitoring", "messages", "en");
t.add("f.dl1", "Media Review", "messages", "en");
t.add("f.dl2", "Media Impact Analysis", "messages", "en");
t.add("f.dl7", "Press Summary", "messages", "en");
t.add("f.dlShort0", "MB", "messages", "en");
t.add("f.dlShort1", "MSP", "messages", "en");
t.add("f.dlShort2", "MRA", "messages", "en");
t.add("f.dlShort7", "PS", "messages", "en");
t.add("f.filterNameExists", "Filter renamed because the given name already exists.", "messages", "en");
t.add("f.id", "Id", "messages", "en");
t.add("f.accesstoken", "Access Token", "messages", "en");
t.add("f.validUntil", "Valid until", "messages", "en");
t.add("f.updateInterval", "Update Interval", "messages", "en");
t.add("f.creator", "Creator", "messages", "en");
t.add("f.active", "Active", "messages", "en");
t.add("f.yes", "Yes", "messages", "en");
t.add("f.no", "No", "messages", "en");
t.add("f.expiredLink", "Expired link (use edit to reactivate)", "messages", "en");
t.add("l.nopermission", "Sorry, you are not permitted to use this service.", "messages", "en");
t.add("l.nopermissionMsp", "You do not have permission to view the details of this media review", "messages", "en");
t.add("l.nofilepermission", "Sorry, you are not permitted to open the requested file:", "messages", "en");
t.add("l.nofilepermissionRole", "Sorry, you have no permission to view licenced content:", "messages", "en");
t.add("l.nofilepermissionLicence", "Sorry, the licence term of the file is ran out:", "messages", "en");
t.add("l.nofilepermissionOrder", "Sorry, you have no permission for the order the file belongs to:", "messages", "en");
t.add("l.nofilepermissionStatus", "Sorry, the file is no more valid:", "messages", "en");
t.add("l.dmaAdminPortalkunde", "Administration ARGUS Portal Customers", "messages", "en");
t.add("l.adminPortalkunde", "Administration ARGUS Portal Customer", "messages", "en");
t.add("l.createPortalkunde", "Create ARGUS Portal Customer", "messages", "en");
t.add("l.search", "search", "messages", "en");
t.add("l.auftragsnummer", "Order no.", "messages", "en");
t.add("l.debitornummer", "Debitor no.", "messages", "en");
t.add("l.customerlist", "Customer list", "messages", "en");
t.add("l.customerId", "Customer ID", "messages", "en");
t.add("l.customerName", "Customer name", "messages", "en");
t.add("l.userLoginName", "User login name", "messages", "en");
t.add("l.userLastName", "User surname", "messages", "en");
t.add("l.aboNo", "Subscription no.", "messages", "en");
t.add("l.user", "Users", "messages", "en");
t.add("l.customer", "Customer", "messages", "en");
t.add("l.loginName", "Login name", "messages", "en");
t.add("l.email", "Email", "messages", "en");
t.add("l.firstname", "First name", "messages", "en");
t.add("l.title", "Title", "messages", "en");
t.add("l.password", "Password", "messages", "en");
t.add("l.passwordFromEmail", "Password of then e-mail:", "messages", "en");
t.add("l.newPasswordSet", "New password successfully set", "messages", "en");
t.add("l.confirm", "Confirm", "messages", "en");
t.add("l.status", "State", "messages", "en");
t.add("l.save", "Save", "messages", "en");
t.add("l.active", "Active", "messages", "en");
t.add("l.inactive", "Inactive", "messages", "en");
t.add("l.deleted", "Deleted", "messages", "en");
t.add("l.userGroup", "User group", "messages", "en");
t.add("l.defaultPortalLayout", "Default layout", "messages", "en");
t.add("l.clUpdIntervalSec", "Clipping list auto update", "messages", "en");
t.add("l.mediatypeIcons", "Mediatype icons", "messages", "en");
t.add("l.hideMediatypeIconsHint", "If you have issues with clipping emails or newsletters, you can disable mediatype icons here.", "messages", "en");
t.add("l.hideMediatypeIcons", "Hide icons", "messages", "en");
t.add("l.showMediatypeIcons", "Show icons", "messages", "en");
t.add("l.emailSenderName", "E-Mail sender name", "messages", "en");
t.add("l.emailSenderAddress", "E-Mail sender address", "messages", "en");
t.add("l.emailReturnPath", "E-Mail return-path", "messages", "en");
t.add("l.useMspGenerator", "Media Review Generator", "messages", "en");
t.add("l.useNewswebMspGenerator", "Newsweb-MSP-Generator (NEW)", "messages", "en");
t.add("l.usePortalMspGenerator", "Portal-MSP-Generator (OLD)", "messages", "en");
t.add("l.superUser", "(Super-Admin)", "messages", "en");
t.add("l.argusAdmin", "(ARGUS-Admin)", "messages", "en");
t.add("l.minutes", "{1} minute |]1,Inf] minutes", "messages", "en");
t.add("l.defaultPortalDesign", "Default design", "messages", "en");
t.add("l.defaultPortalMenu", "Default menu", "messages", "en");
t.add("l.defaultPspHtmlKonfig", "Default media review HTML configuration", "messages", "en");
t.add("l.defaultPspEmailKonfig", "Default media review email configuration", "messages", "en");
t.add("l.defaultEmailKonfig", "Default email configuration", "messages", "en");
t.add("l.portalLayout", "Layout", "messages", "en");
t.add("l.portalDesign", "Design", "messages", "en");
t.add("l.portalMenu", "Menu", "messages", "en");
t.add("l.defaultDataFieldSet", "Default data field set", "messages", "en");
t.add("l.dataFieldSet", "Data field set", "messages", "en");
t.add("l.defaultPspConfig", "Default media review configuration", "messages", "en");
t.add("l.pspConfig", "media review configuration", "messages", "en");
t.add("l.createNewGroup", "New email group", "messages", "en");
t.add("l.currentEmailGroup", "Email group:", "messages", "en");
t.add("l.emailGroup", "Email group:", "messages", "en");
t.add("l.newEmail", "New email", "messages", "en");
t.add("l.deactivateEmailGroup", "Deactivate e-mail group", "messages", "en");
t.add("l.deleteEmailGroup", "Delete e-mail group finally", "messages", "en");
t.add("l.activateEmailGroup", "Activate e-mail group", "messages", "en");
t.add("l.intoEmailGroup", "into e-mail group ", "messages", "en");
t.add("l.deleteEmail", "Delete e-mail finally", "messages", "en");
t.add("l.newEmailOwner", "Email owner", "messages", "en");
t.add("l.sendRegMailAgain", "Send invitation email again", "messages", "en");
t.add("l.callRegForm", "Call registration page", "messages", "en");
t.add("l.sent", "Sent", "messages", "en");
t.add("l.auftragStatistics", "Order Statistics", "messages", "en");
t.add("l.timerange", "Timerange based on production date", "messages", "en");
t.add("l.timerangeFrom", "From", "messages", "en");
t.add("l.timerangeTill", "Till", "messages", "en");
t.add("l.timerangeError", "Time range: \"From\" has to be lower than \"Till\".", "messages", "en");
t.add("l.orderemptyError", "Please select an order.", "messages", "en");
t.add("l.trefferCountPSP", "Media review articles", "messages", "en");
t.add("l.trefferCountTv", "TV articles", "messages", "en");
t.add("l.trefferCountTvClip", "TV clips", "messages", "en");
t.add("l.trefferCountHf", "Audio articles", "messages", "en");
t.add("l.trefferCountHfClip", "Audio clips", "messages", "en");
t.add("l.trefferCount", "Absolute articles", "messages", "en");
t.add("l.errorMsg", "Sorry, an error has been occured.", "messages", "en");
t.add("l.emailGroupUndefined", "Email group", "messages", "en");
t.add("l.infoTrefferCountPSP", "Number of non-deleted hits that were produced by the ARGUS DATA INSIGHTS media review department.", "messages", "en");
t.add("l.infoTrefferCountTv", "Number of non-deleted hits for TV, without customer-owned.", "messages", "en");
t.add("l.infoTrefferCountTvClip", "Number of hits for TV, that have a recording in ARGUS Portal. Previews are excluded.", "messages", "en");
t.add("l.infoTrefferCountHf", "Number of non-deleted hits for radio, without customer-owned.", "messages", "en");
t.add("l.infoTrefferCountHfClip", "Number of hits for radio, that have a recording in ARGUS Portal. Previews are excluded.", "messages", "en");
t.add("l.infoTrefferCountAbsolute", "Total number of non-deleted hits including customer-owned.", "messages", "en");
t.add("l.getResult", "Get Result", "messages", "en");
t.add("l.standardDashboard", "Standard-Dashboard", "messages", "en");
t.add("l.ezmDashboard", "EZM-Dashboard", "messages", "en");
t.add("l.pspHtmlEmailName", "Name", "messages", "en");
t.add("l.pspHtmlEmailPath", "Path", "messages", "en");
t.add("l.pspHtmlEmailCssPath", "Path CSS file", "messages", "en");
t.add("l.pspHtmlEmailLanguage", "Language", "messages", "en");
t.add("l.pspHtmlEmailFields", "Additional fields", "messages", "en");
t.add("l.pspHtmlEmailDate", "Date in subject", "messages", "en");
t.add("l.pspHtmlEmailLoadTrefferData", "Load clipping data", "messages", "en");
t.add("l.pspHtmlEmailSubject", "Email subject", "messages", "en");
t.add("l.pspHtmlEmailContent", "Content", "messages", "en");
t.add("l.pspHtmlEmailAsEmailContent", "Include HTML media review als email content", "messages", "en");
t.add("l.pspHtmlEmailSetUrls", "Embed link to media review file(s)", "messages", "en");
t.add("l.pspHtmlEmailAttachement", "Select attachment automatically", "messages", "en");
t.add("l.pspHtmlEmailUrls", "Embed URLs", "messages", "en");
t.add("l.pspHtmlEmailUrlsOpen", "open links", "messages", "en");
t.add("l.pspHtmlEmailUrlsClosed", "protected links", "messages", "en");
t.add("l.pspHtmlEmailFooter", "Footer", "messages", "en");
t.add("l.pspHtmlEmailSetFooter", "Embed footer?", "messages", "en");
t.add("l.allEmailsVisible", "Show all recipients", "messages", "en");
t.add("l.emailTemplate", "E-mail template path (e.g. customLayouts\/4089\/layout1, default: empty)", "messages", "en");
t.add("psp.ENUM_PSP_EMAIL_ATTACHEMENT_NONE", "None", "messages", "en");
t.add("psp.ENUM_PSP_EMAIL_ATTACHEMENT_HTML", "HTML", "messages", "en");
t.add("psp.ENUM_PSP_EMAIL_ATTACHEMENT_PDF", "PDF", "messages", "en");
t.add("psp.ENUM_PSP_EMAIL_ATTACHEMENT_HTML_PDF", "HTML + PDF", "messages", "en");
t.add("l.pwdForgotten", "Did you forget your password?", "messages", "en");
t.add("l.enterEmail", "Please fill in your email address", "messages", "en");
t.add("l.pwdForgottenSendError", "An error occurred while sending the email.", "messages", "en");
t.add("l.pwdForgottenSendSuccess", "An email with an activation link was sent to your address.", "messages", "en");
t.add("l.errPwdForgotten_noEmail", "Your email address is missing.", "messages", "en");
t.add("l.errPwdForgotten_wrongEmail", "Your email contains a mistake, please check the spelling.", "messages", "en");
t.add("l.errPwdForgotten_noAccount", "Invalid email address.", "messages", "en");
t.add("l.pwdForgotten_line1", "As requested, we have generated a new password for the account \"%username%\":", "messages", "en");
t.add("l.pwdForgotten_line2", "Please activate the new password within 24 hours by using the following link:", "messages", "en");
t.add("l.pwdForgotten_line3", "For security reasons we recommend you change this password immediately after activation. If you did not send us this request please inform us via email: @ kunden@argusdatainsights.de. Without activating the new password your current password will remain valid.", "messages", "en");
t.add("l.pwdActivation", "Password activation", "messages", "en");
t.add("l.errPwdForgotten_requestNotExists", "Sorry, a link for this request does not exist.", "messages", "en");
t.add("l.errPwdForgotten_requestNoMMoreValid", "Sorry, this request has already been executed.", "messages", "en");
t.add("l.errPwdForgotten_requestTooOld", "Sorry, your request is no longer valid after 24 hours.", "messages", "en");
t.add("l.errPwdForgotten_userNotValid", "Sorry, the user is no longer valid.", "messages", "en");
t.add("l.errPwdForgotten_no_ad_password_reset", "You credentials are managed with Azure AD. Please contact you administrator.", "messages", "en");
t.add("l.errPwdForgotten_exception", "An error occurred while processing your request.", "messages", "en");
t.add("l.errPwdNumber", "The password must contain at least one digit.", "messages", "en");
t.add("l.errPwdChar", "The password must contain at least one character.", "messages", "en");
t.add("l.errPwdSpace", "The password may not contain a space.", "messages", "en");
t.add("l.errPwdEmpty", "The password may not be empty.", "messages", "en");
t.add("l.errPwdCharNum", "The password must have at least 6 signs.", "messages", "en");
t.add("l.errPwdOldNew", "The new password may not be the same as the old one.", "messages", "en");
t.add("l.errPwdWrongFormat", "The password must contain at least 6 characters and 1 digit.", "messages", "en");
t.add("I.errPwdUserLocked", "Unfortunately,your account is locked due to too many login attempts.", "messages", "en");
t.add("l.errReg", "An error occurred", "messages", "en");
t.add("l.errReg_invalidToken", "The registration token is invalid.", "messages", "en");
t.add("l.errReg_done", "This registration has already been executed.", "messages", "en");
t.add("l.newcustomer", "New customer", "messages", "en");
t.add("l.update", "Update", "messages", "en");
t.add("l.createUser", "Register new user", "messages", "en");
t.add("l.createUserGroup", "Create user group", "messages", "en");
t.add("l.createPortalDesign", "Create design", "messages", "en");
t.add("l.createPortalMenu", "Create menu", "messages", "en");
t.add("l.createEmailKonfig", "Create email configuration", "messages", "en");
t.add("l.createPspEmailKonfig", "Create media review email configuration", "messages", "en");
t.add("l.createPspHtmlKonfig", "Create media review HTML configuration", "messages", "en");
t.add("l.createDataFieldSet", "Create data field set", "messages", "en");
t.add("l.edit", "edit", "messages", "en");
t.add("l.emailAdmin", "Email administration", "messages", "en");
t.add("l.rubrikAdmin", "Category images administration", "messages", "en");
t.add("l.noData", "No data available", "messages", "en");
t.add("l.defaultConfig", "default configuration", "messages", "en");
t.add("l.default", "default", "messages", "en");
t.add("l.adminPortaluser", "User administration", "messages", "en");
t.add("l.adminUserGroup", "User group administration", "messages", "en");
t.add("l.display", "Display", "messages", "en");
t.add("l.adminPortaColorDesign", "Design", "messages", "en");
t.add("l.adminPortaMenu", "Menu", "messages", "en");
t.add("l.adminEmailKonfig", "Email configuration", "messages", "en");
t.add("l.adminPspEmailKonfig", "Media review email configuration", "messages", "en");
t.add("l.adminPspHtmlKonfig", "Media review HTML configuration", "messages", "en");
t.add("l.adminPortaLayout", "Layout", "messages", "en");
t.add("l.adminDatenfeldset", "Data field set", "messages", "en");
t.add("l.adminPspConfig", "Media review configuration", "messages", "en");
t.add("l.adminMspGenerator", "MSP generator", "messages", "en");
t.add("l.startPage", "Starting page", "messages", "en");
t.add("l.startAuftrag", "Starting order", "messages", "en");
t.add("l.emailManage", "Manage Emails", "messages", "en");
t.add("l.switchUser", "Switch user", "messages", "en");
t.add("l.switchUserExit", "Exit impersonation", "messages", "en");
t.add("l.changelogin", "Change login data", "messages", "en");
t.add("l.showData", "Show data", "messages", "en");
t.add("l.hideData", "Hide data", "messages", "en");
t.add("l.gender", "Salutation", "messages", "en");
t.add("l.female", "Mrs.", "messages", "en");
t.add("l.male", "Mr.", "messages", "en");
t.add("l.german", "German", "messages", "en");
t.add("l.english", "English", "messages", "en");
t.add("l.pleaseSelect", "Please select", "messages", "en");
t.add("l.pleaseSelectAddress", "Please select address", "messages", "en");
t.add("l.noSelection", "No selection", "messages", "en");
t.add("l.formError", "Form error", "messages", "en");
t.add("l.internalServerError", "An internal error occurred. Please contact support if the error recurs.", "messages", "en");
t.add("l.formResult", "Result", "messages", "en");
t.add("l.updateSuccess", "Data changed successfully", "messages", "en");
t.add("l.updateError", "An error occurred during updating", "messages", "en");
t.add("l.userGroups", "User groups", "messages", "en");
t.add("l.roles", "User permissions", "messages", "en");
t.add("l.defaultRoles", "Default permissions", "messages", "en");
t.add("l.extendedRoles", "Extended permissions", "messages", "en");
t.add("l.errTokenNomoreValid", "Token is not valid anymore", "messages", "en");
t.add("l.errTokenInvalid", "Invalid token", "messages", "en");
t.add("l.errUsernameExists", "The username already exists.", "messages", "en");
t.add("l.errUsernameSpace", "The username may not contain a space.", "messages", "en");
t.add("l.errEmailExists", "Email already exists", "messages", "en");
t.add("l.userNamePrefix", "Username prefix", "messages", "en");
t.add("l.labelEmailGroups", "Manage groups", "messages", "en");
t.add("l.labelEmailGroupsDisabled", "Inactive groups", "messages", "en");
t.add("l.formDeleteUserHint", "Please note: the user has RSS feeds and\/or newsletters. These will be deactivated on deletion of user.", "messages", "en");
t.add("l.ssoIframeIntegration", "Iframe Integration", "messages", "en");
t.add("l.ssoTalkwalkerIntegration", "Talkwalker SSO", "messages", "en");
t.add("l.argusFlatRoles", "Argus predefined roles", "messages", "en");
t.add("l.argusPredefinedRole.S", "ARGUS Portal S", "messages", "en");
t.add("l.argusPredefinedRole.M", "ARGUS Portal M", "messages", "en");
t.add("l.argusPredefinedRole.L", "ARGUS Portal L", "messages", "en");
t.add("l.argusPredefinedRole.XL", "ARGUS Portal XL", "messages", "en");
t.add("r.azLogin", "Login with Azure AD", "messages", "en");
t.add("r.userName", "User name or E-Mail", "messages", "en");
t.add("r.password", "Password", "messages", "en");
t.add("r.passwordConfirm", "Repeat password", "messages", "en");
t.add("r.emailAddress", "Email address", "messages", "en");
t.add("r.newsletterRegistration", "Ja, ich m\u00f6chte Unternehmensnews und Informationen zu neuesten Produkten sowie Aktionen von ARGUS DATA INSIGHTS\u00ae per Newsletter erhalten (es wird nur die E-Mail-Adresse \u00fcbermittelt).", "messages", "en");
t.add("l.invitationSuccessMsg", "User \"%username%\" has been created successfully. A registration email was sent to \"%email%\".", "messages", "en");
t.add("l.roleUserGroupMsg", "The user permissions are added to those of a selected user group", "messages", "en");
t.add("l.note", "Note", "messages", "en");
t.add("l.registrationInvMsg", "An email with a confirmation token will be sent to the user`s email address. The user then needs to confirm the registration and enter a user name and password.", "messages", "en");
t.add("l.sendRegEmail", "Registration", "messages", "en");
t.add("l.register", "Registration", "messages", "en");
t.add("l.userregistration4", "User registration for", "messages", "en");
t.add("l.chpwd", "Change password", "messages", "en");
t.add("l.openInvitations", "Not sent registrations", "messages", "en");
t.add("l.pendingInvitations", "Pending registrations", "messages", "en");
t.add("l.allInvitations", "All registrations", "messages", "en");
t.add("l.invitations", "Registration list", "messages", "en");
t.add("l.errAuftragRequired", "Please select an order", "messages", "en");
t.add("l.errFilterRequired", "Please select a filter (Saved filter has to exist)", "messages", "en");
t.add("l.errMinTargetHitsRequired", "Please insert the number", "messages", "en");
t.add("l.errDailyTimeRequired", "Please insert the time", "messages", "en");
t.add("l.errIntervallRequired", "Please insert the interval", "messages", "en");
t.add("l.errEmailRequired", "Please select an email address (Configuration in user administration)", "messages", "en");
t.add("l.errEmailGroupEmpty", "Email group has no emails selected (Configuration in user administration)", "messages", "en");
t.add("l.autologin", "Automatic IP login", "messages", "en");
t.add("l.autologinKey", "Automatic key login", "messages", "en");
t.add("l.autologinKeyGenerate", "Generate", "messages", "en");
t.add("l.autologinKeyRemove", "Remove", "messages", "en");
t.add("l.autologinKeyRemoveWarning", "Do you really want to remove the URL? You can generate a new URL at any time, but never the same again.", "messages", "en");
t.add("l.autologinSubnet", "IP subnet automatic login", "messages", "en");
t.add("l.errIpAlreadyUsed", "The IP is already assigned to another user", "messages", "en");
t.add("l.errWrongIpFormat", "The IP is not valid", "messages", "en");
t.add("l.errIpSubnetAlreadyUsed", "The IP subnet is already assigned to another user", "messages", "en");
t.add("l.errWrongIpSubnetFormat", "The IP subnet is not valid", "messages", "en");
t.add("l.autologinMsg1", "Please separate different entries with a comma, semicolon or space.", "messages", "en");
t.add("l.autologinMsg2", "Please use the URL: \"%baseurl%\" for automatic login", "messages", "en");
t.add("l.selectAdditionalFields", "Select additional data", "messages", "en");
t.add("l.datenFeldSetDescr", "Change the display of a single article within the detailed output of an article list.", "messages", "en");
t.add("l.portalDesignDescr", "Change the display`s colors and logo", "messages", "en");
t.add("l.portalMenuDescr", "Change entries in main menu. The menu entry \"Clippings\" has to be activated if dashboard or media review is enabled.<br \/>Please note: If you switch off menu entry \"Work\" user administration is not accessible anymore.", "messages", "en");
t.add("l.reichweite", "Range", "messages", "en");
t.add("l.reachZero", "No data available for this broadcast.", "messages", "en");
t.add("l.reachNotAvailable", "The reach has not yet been provided.", "messages", "en");
t.add("l.portalColors", "Common colors", "messages", "en");
t.add("l.dashboardColors", "Dashboard colors", "messages", "en");
t.add("l.logo", "Logo", "messages", "en");
t.add("l.deactivatePspConfig", "deactivate", "messages", "en");
t.add("l.activatePspConfig", "activate", "messages", "en");
t.add("l.cannotDisableMenuEntry", "This entry cannot be disabled.", "messages", "en");
t.add("l.rtvformat", "Format", "messages", "en");
t.add("l.rtvformatexport", "Format (RTV)", "messages", "en");
t.add("l.errorEmailSave", "The email could not be saved. Wrong format.", "messages", "en");
t.add("l.emailEmpty", "Please enter an email.", "messages", "en");
t.add("l.emailOwnerEmpty", "Please enter an email owner.", "messages", "en");
t.add("l.emailGroupEmpty", "Please enter an email group.", "messages", "en");
t.add("l.emailGroupName", "Name", "messages", "en");
t.add("l.url", "URL", "messages", "en");
t.add("l.disabledMraButtonHint", "This function is not available for editing clippings of different orders.", "messages", "en");
t.add("l.disabledMraButtonHint2", "No analysis function for clippings of this order available.", "messages", "en");
t.add("l.disabledPspButtonHint", "This function is not available for press reviews that are based on the advanced achiv law.", "messages", "en");
t.add("l.feedcontent", "Feed List", "messages", "en");
t.add("l.sortArticleFields", "Clipping fields", "messages", "en");
t.add("l.sortArticleMkzFields", "Range fields", "messages", "en");
t.add("l.export_kennzahlen", "Range (selection)", "messages", "en");
t.add("l.anz_zchn", "Number of characters (clipping)", "messages", "en");
t.add("l.medienrubrik", "Media category", "messages", "en");
t.add("l.articlerubrik", "Category (clipping)", "messages", "en");
t.add("l.checkAll", "Check all", "messages", "en");
t.add("l.selectFileFormat", "Select file format", "messages", "en");
t.add("l.selectedArticlesAddedToMediaReview", "Selected clippings added to media review.", "messages", "en");
t.add("l.selectedArticlesCouldntBeAddedToMediaReview", "Selected clippings could`nt be added to media review.", "messages", "en");
t.add("l.selectedFeedContentAddedToMediaReview", "Selected feed content added to media review.", "messages", "en");
t.add("l.selectedFeedContentCouldntBeAddedToMediaReview", "Selected feed content could`nt be added to media review.", "messages", "en");
t.add("l.mediaGenreOnline", "Online", "messages", "en");
t.add("l.mediaGenreNewsAgencies", "News agencies", "messages", "en");
t.add("w.overview", "Overview", "messages", "en");
t.add("w.tag", "Tags", "messages", "en");
t.add("w.folder", "Folders", "messages", "en");
t.add("w.newsletter", "Newsletter", "messages", "en");
t.add("w.ali", "Real-time monitoring", "messages", "en");
t.add("w.aliKampagne", "Campaign", "messages", "en");
t.add("w.aliSuchprofi", "Search agents", "messages", "en");
t.add("w.psp", "Media Reviews", "messages", "en");
t.add("w.clippingtiles", "Clipping tiles", "messages", "en");
t.add("w.report", "Reports", "messages", "en");
t.add("w.presssummary", "Summaries", "messages", "en");
t.add("w.rss", "Feeds", "messages", "en");
t.add("w.filter", "Filter", "messages", "en");
t.add("w.useradmin", "User administration", "messages", "en");
t.add("w.thisWeek", "This week", "messages", "en");
t.add("w.thisMonth", "This month", "messages", "en");
t.add("w.total", "Total", "messages", "en");
t.add("w.activ", "Active", "messages", "en");
t.add("w.inactiv", "Inactive", "messages", "en");
t.add("w.taggedArticle", "Clippings", "messages", "en");
t.add("w.taggedPSP", "Media Reviews", "messages", "en");
t.add("w.blackboardWidget", "Clippings per media type", "messages", "en");
t.add("w.userNumber", "User", "messages", "en");
t.add("w.blackboardTitle", "Overview (all orders)", "messages", "en");
t.add("w.medienarten", "Media types", "messages", "en");
t.add("w.showMdMart1", "Show print clippings", "messages", "en");
t.add("w.showMdMart2", "Show agency clippings", "messages", "en");
t.add("w.showMdMart3", "Show TV broadcasts", "messages", "en");
t.add("w.showMdMart4", "Show online pages", "messages", "en");
t.add("w.showMdMart6", "Show teletext pages", "messages", "en");
t.add("w.showMdMart7", "Show radio broadcasts", "messages", "en");
t.add("w.andMore", "... and more", "messages", "en");
t.add("w.showAll", "Show all", "messages", "en");
t.add("w.embed", "Embedding widgets", "messages", "en");
t.add("ali.kampagnen", "Campaigns", "messages", "en");
t.add("ali.kampagne", "Campaign", "messages", "en");
t.add("ali.editKampagnen", "Edit campaigns", "messages", "en");
t.add("ali.addKampagne", "Add campaign", "messages", "en");
t.add("ali.addSearchprofil", "Add search agent", "messages", "en");
t.add("ali.suchprofile", "Search agents", "messages", "en");
t.add("ali.suchprofil", "Search agent", "messages", "en");
t.add("ali.auftrag", "Order", "messages", "en");
t.add("ali.saved", "Saved", "messages", "en");
t.add("ali.error", "Error", "messages", "en");
t.add("ali.errorMessageValidation", "The data could not be saved, please check the input fields.", "messages", "en");
t.add("ali.noKampagnenAvailable", "No campaign available", "messages", "en");
t.add("ali.noSuchprofileAvailable", "No search agents available", "messages", "en");
t.add("ali.createNewKampagnen", "Create new campaign", "messages", "en");
t.add("ali.createNewSuchprofil", "Create new search agents", "messages", "en");
t.add("ali.suchprofileSectionPreferences", "Preferences", "messages", "en");
t.add("ali.suchprofileSectionQuery", "Query", "messages", "en");
t.add("ali.suchprofileSectionTimerange", "Time frame", "messages", "en");
t.add("ali.suchprofileSectionLanguage", "Language", "messages", "en");
t.add("ali.suchprofileSectionMedia", "Media", "messages", "en");
t.add("ali.name", "Name", "messages", "en");
t.add("ali.activ", "Active", "messages", "en");
t.add("ali.adhoc", "Ad hoc search", "messages", "en");
t.add("ali.casesensitive", "Case-sensitive", "messages", "en");
t.add("ali.tonalitaet", "Sentiment", "messages", "en");
t.add("ali.suchbegriff", "Search terms", "messages", "en");
t.add("ali.kontextbegriffe", "Context terms", "messages", "en");
t.add("ali.ausschluesse", "Exclusion terms", "messages", "en");
t.add("ali.expertsearch", "Expert search", "messages", "en");
t.add("ali.timerangeFrom", "From", "messages", "en");
t.add("ali.timerangeTill", "Till", "messages", "en");
t.add("ali.languageDe", "German", "messages", "en");
t.add("ali.languageEn", "English", "messages", "en");
t.add("ali.mediaTwitter", "X", "messages", "en");
t.add("ali.mediaFacebook", "Facebook", "messages", "en");
t.add("ali.mediaYoutube", "Youtube", "messages", "en");
t.add("ali.mediaGoogleplus", "Google+", "messages", "en");
t.add("ali.mediaOnlineK", "News", "messages", "en");
t.add("ali.mediaBlogs", "Blogs", "messages", "en");
t.add("ali.mediaMessageboard", "Forums", "messages", "en");
t.add("ali.mediaVerbraucherportale", "Consumer portals", "messages", "en");
t.add("ali.mediaSonstigeSMFormate", "Miscellaneous social media formats", "messages", "en");
t.add("ali.mediaPresseportale", "Press portals", "messages", "en");
t.add("ali.mediaIndiPanel", "Individual panel", "messages", "en");
t.add("ali.confirmKampagneDeletion", "Do you really want to delete the campaign?", "messages", "en");
t.add("ali.confirmSuchprofilDeletion", "Do you really want to delete the search agent?", "messages", "en");
t.add("ali.confirmKampagneDelete", "Delete campaign", "messages", "en");
t.add("ali.confirmSuchprofilDelete", "Delete search agent", "messages", "en");
t.add("ali.confirmKampagneDeleteText", "Do you really want to delete the campaign \"%name%\" and all its clippings?", "messages", "en");
t.add("ali.confirmSuchprofilDeleteText", "Do you really want to delete the search agent \"%name%\" and all its clippings?", "messages", "en");
t.add("ali.expertmode", "Enter expert mode", "messages", "en");
t.add("ali.normalmode", "Exit expert mode", "messages", "en");
t.add("ali.adhocButton", "Ad-Hoc", "messages", "en");
t.add("ali.unknownError", "An unknown error has occurred", "messages", "en");
t.add("ali.confirmEnterExpertmode", "Search, context and exclusion terms will be lost if expert mode is activated. Do you want to continue?", "messages", "en");
t.add("ali.confirmLeavExpertmode", "The expert search will be lost if expert mode is deactivated. Do you want to continue?", "messages", "en");
t.add("ali.maxCountSuchprofileReached", "The maximum number of  %count% searchagents for this order has been reached. It is not possible to add more search agents.", "messages", "en");
t.add("ali.maxCountTermsReached", "The maximum number of terms has been reached. Please reduce the number of used terms.", "messages", "en");
t.add("ali.errTimeRange", "Time range: \"From\" has to be earlier than \"Till\".", "messages", "en");
t.add("ali.errLanguage", "Please select at least one language.", "messages", "en");
t.add("ali.errSB", "Please type in your search term.", "messages", "en");
t.add("ali.errMedia", "Please select at least one medium.", "messages", "en");
t.add("ali.errSign", "Please remove invalid sign \"!\".", "messages", "en");
t.add("n.newsletter", "Newsletter", "messages", "en");
t.add("n.name", "Name", "messages", "en");
t.add("n.filter", "Filter", "messages", "en");
t.add("n.order", "Orders", "messages", "en");
t.add("n.FilterOrOrders", "Filters or Orders", "messages", "en");
t.add("n.medienarten", "Media types", "messages", "en");
t.add("n.suchbegriffe", "Search terms", "messages", "en");
t.add("n.tags", "Tags", "messages", "en");
t.add("n.tonalitaet", "Total rating", "messages", "en");
t.add("n.sendingFlavor", "When the newsletter should be sent", "messages", "en");
t.add("n.sendingFlavorImmediate", "Immediately upon arrival", "messages", "en");
t.add("n.sendingFlavorMinimalCount", "Minimal count", "messages", "en");
t.add("n.sendingFlavorFixedTime", "Fixed time", "messages", "en");
t.add("n.daily", "Daily", "messages", "en");
t.add("n.weekly", "Weekly", "messages", "en");
t.add("n.monthly", "Monthly", "messages", "en");
t.add("n.interval", "Interval", "messages", "en");
t.add("n.monday", "Monday", "messages", "en");
t.add("n.tuesday", "Tuesday", "messages", "en");
t.add("n.wednesday", "Wednesday", "messages", "en");
t.add("n.thursday", "Thursday", "messages", "en");
t.add("n.friday", "Friday", "messages", "en");
t.add("n.saturday", "Saturday", "messages", "en");
t.add("n.sunday", "Sunday", "messages", "en");
t.add("n.weekday", "Weekday", "messages", "en");
t.add("n.monthlyDay", "Day of month", "messages", "en");
t.add("n.intervalHourEveryHour", "Every hour", "messages", "en");
t.add("n.intervalHourEvery2Hours", "Every 2 hours", "messages", "en");
t.add("n.intervalHourEvery4Hours", "Every 4 hours", "messages", "en");
t.add("n.intervalHourEvery6Hours", "Every 6 hours", "messages", "en");
t.add("n.intervalHourEvery8Hours", "Every 8 hours", "messages", "en");
t.add("n.intervalHourEvery12Hours", "Every 12 hours", "messages", "en");
t.add("n.html", "HTML", "messages", "en");
t.add("n.htmlSimple", "simple HTML", "messages", "en");
t.add("n.pdf", "PDF", "messages", "en");
t.add("n.newsletterFormat", "Newsletter format", "messages", "en");
t.add("n.recipientGroup", "Recipient group", "messages", "en");
t.add("n.recipient", "Recipient", "messages", "en");
t.add("n.sender", "Sender", "messages", "en");
t.add("n.contentFlavorStandart", "Standard", "messages", "en");
t.add("n.contentFlavorIndividually", "Individually", "messages", "en");
t.add("n.newsletterContent", "Newsletter content", "messages", "en");
t.add("n.contentPropertiesTrefferliste", "Clipping list", "messages", "en");
t.add("n.contentPropertiesTrefferanzahl", "Number of clippings", "messages", "en");
t.add("n.contentPropertiesTreffer", "Clippings", "messages", "en");
t.add("n.contentPropertiesMedientitel", "Media title", "messages", "en");
t.add("n.contentPropertiesAusgabedatum", "Issue date", "messages", "en");
t.add("n.contentPropertiesAusgabeuhrzeit", "Issue time", "messages", "en");
t.add("n.contentPropertiesHeadline", "Headline", "messages", "en");
t.add("n.contentPropertiesURL", "URL", "messages", "en");
t.add("n.contentPropertiesSeite", "Page", "messages", "en");
t.add("n.contentPropertiesSeiteShort", "Page", "messages", "en");
t.add("n.contentPropertiesSuchwortumgebung", "Search term context", "messages", "en");
t.add("n.contentPropertiesTvHinweis", "TV abstract", "messages", "en");
t.add("n.contentPropertiesTeaser", "Teaser", "messages", "en");
t.add("n.contentPropertiesTeaserSuchwortumgebung", "Search term context \/ teaser", "messages", "en");
t.add("n.contentPropertiesSuchbegriffe", "Search terms", "messages", "en");
t.add("n.contentPropertiesKommentar", "Comment", "messages", "en");
t.add("n.contentPropertiesTags", "Tags", "messages", "en");
t.add("n.contentPropertiesGesamtBewertung", "Total rating", "messages", "en");
t.add("n.contentPropertiesAuthor", "Author", "messages", "en");
t.add("n.contentPropertiesLand", "Country", "messages", "en");
t.add("n.contentPropertiesReichweite", "Range", "messages", "en");
t.add("n.contentPropertiesVerbreiteteAuflage", "Circulation", "messages", "en");
t.add("n.contentPropertiesUniqueUser", "Unique user", "messages", "en");
t.add("n.contentPropertiesClippingLength", "Clipping length", "messages", "en");
t.add("n.contentPropertiesAve", "AVE Total", "messages", "en");
t.add("n.contentPropertiesClippingSender", "Broadcast station", "messages", "en");
t.add("n.contentPropertiesMedientitelSender", "Media title \/ Broadcast", "messages", "en");
t.add("n.contentPropertiesDokument", "Documents", "messages", "en");
t.add("n.contentPropertiesMeldung", "Note \/ RTV-Abstract", "messages", "en");
t.add("n.saved", "Saved", "messages", "en");
t.add("n.successSendImmediately", "Mailing activated", "messages", "en");
t.add("n.sendImmediatelyConfirm", "Do you want to sent the newsletter immediately? <br \/>Unsaved changes will be lost.", "messages", "en");
t.add("n.error", "Error", "messages", "en");
t.add("n.errorMessageValidation", "The data could not be saved, please check the input fields.", "messages", "en");
t.add("n.content", "Content", "messages", "en");
t.add("n.delivery", "Delivery", "messages", "en");
t.add("n.representation", "Representation", "messages", "en");
t.add("n.saveNewsletter", "Save newsletter", "messages", "en");
t.add("n.noNewsletterAvailable", "No newsletter available", "messages", "en");
t.add("n.add", "Add", "messages", "en");
t.add("n.newsletterHtmlSimpleTitle", "Newsletter", "messages", "en");
t.add("n.newsletterHtmlSimpleFooterProfile", "Media-Monitoring", "messages", "en");
t.add("n.newsletterHtmlSimpleFooterAddress", "ARGUS DATA INSIGHTS\u00ae Deutschland GmbH, Gneisenaustra\u00dfe 66, 10961 Berlin - Germany,  +49 30 203987 0", "messages", "en");
t.add("n.newsletterHtmlExtendedFooterProfile", "Media-Monitoring", "messages", "en");
t.add("n.newsletterHtmlExtendedFooterAddress", "ARGUS DATA INSIGHTS\u00ae Deutschland GmbH, Gneisenaustra\u00dfe 66, 10961 Berlin - Germany,  +49 30 203987 0", "messages", "en");
t.add("n.newsletterPdfClippings", "Clippings", "messages", "en");
t.add("n.newsletterPdfFilename", "ARGUS DATA INSIGHTS Newsletter.pdf", "messages", "en");
t.add("n.mailSubject", "ARGUS DATA INSIGHTS Newsletter", "messages", "en");
t.add("n.mailPersonalAddress", "Dear Sir or Madam,", "messages", "en");
t.add("n.mailCoverLetter", "please find attached the newsletter.", "messages", "en");
t.add("n.salutation_1", "Kind regards,", "messages", "en");
t.add("n.salutation_2", "Your ARGUS DATA INSIGHTS Team", "messages", "en");
t.add("n.from", "from", "messages", "en");
t.add("n.newsletterDelete", "Delete newsletter", "messages", "en");
t.add("n.newsletterDeleteText", "Do you really want to delete this newsletter?", "messages", "en");
t.add("n.requiredField", "Required field", "messages", "en");
t.add("n.requiredChoiceTooltip", "This field is required to display in newsletter", "messages", "en");
t.add("n.emailKonfigForFooter", "Use signature from", "messages", "en");
t.add("n.quelle", "Source", "messages", "en");
t.add("n.errNameAlreadyUsed", "Name is already used", "messages", "en");
t.add("n.copy", "Copy", "messages", "en");
t.add("n.delete", "Delete", "messages", "en");
t.add("n.firstDayOfMonth", "1st day of month", "messages", "en");
t.add("n.middleOfMonth", "15th day of month", "messages", "en");
t.add("n.errorMailTitle", "Fehler im NewsletterService", "messages", "en");
t.add("n.errorMailContent", "Folgende Newsletter konnten aufgrund eines Fehlers nicht generiert werden. Dies ist unbedingt zu pr\u00fcfen! F\u00fcr diese Newsletter erfolgt keine weitere Benachrichtigung.", "messages", "en");
t.add("n.last_attempt", "Last attempt (not sent)", "messages", "en");
t.add("n.last_sent", "Last shipped", "messages", "en");
t.add("n.not_sent_yet", "Newsletter not yet sent", "messages", "en");
t.add("n.article", "Article", "messages", "en");
t.add("n.emptyShippingMessage", "We didn\u2019t identify any relevant new articles for you since our last delivery.", "messages", "en");
t.add("n.emptyShippingLabel", "Non-Shipping Info Mail", "messages", "en");
t.add("n.sendAsAlert", "Alert", "messages", "en");
t.add("exp.headerartikel", "Clipping", "messages", "en");
t.add("exp.headermkz", "Range", "messages", "en");
t.add("exp.headermedium", "Media", "messages", "en");
t.add("exp.headerthema", "Analysis", "messages", "en");
t.add("exp.headersonstige", "Others", "messages", "en");
t.add("exp.artikel", "Clipping data", "messages", "en");
t.add("exp.mkz", "Types of range", "messages", "en");
t.add("exp.medium", "Media data", "messages", "en");
t.add("exp.thema", "Analysis data", "messages", "en");
t.add("exp.sonstige", "Other data", "messages", "en");
t.add("exp.openFieldDetails", "Click to open field list", "messages", "en");
t.add("exp.hlChooseExportType", "Choose export type", "messages", "en");
t.add("exp.hlChooseExportFormat", "Choose file format", "messages", "en");
t.add("exp.hlExportConfig", "Export configuration", "messages", "en");
t.add("exp.hlChooseExportConfig", "Choose export configuration", "messages", "en");
t.add("exp.hlNameExportConfig", "Save with new name", "messages", "en");
t.add("exp.groupList", "Field groups", "messages", "en");
t.add("exp.configForAll", "Should configuration be used by all users?", "messages", "en");
t.add("exp.errorNoGroupSelected", "Please choose at least one field group.", "messages", "en");
t.add("exp.pleaseChooseConfig", "Please choose a export configuration or set name for a new one.", "messages", "en");
t.add("exp.exportTypeArtikel", "clipping based", "messages", "en");
t.add("exp.exportTypeThema", "topic based", "messages", "en");
t.add("exp.defaultExportConfig", "Default export", "messages", "en");
t.add("exp.lastExportConfig", "Last export", "messages", "en");
t.add("exp.noPremiumHint1", "No extended data export possible as none of the orders of the selected results included rights.", "messages", "en");
t.add("exp.noPremiumHint2", "No extended data export possible as one or more orders of the selected results did not include rights.", "messages", "en");
t.add("exp.enterExportTemplateTitle", "Save current selection as Template", "messages", "en");
t.add("exp.saveExport", "Save", "messages", "en");
t.add("exp.errorEmptyTemplateName", "Template's name cannot be empty", "messages", "en");
t.add("ntf.formBlockDe", "German", "messages", "en");
t.add("ntf.formBlockEn", "English", "messages", "en");
t.add("ntf.headline", "Headline", "messages", "en");
t.add("ntf.content", "Content", "messages", "en");
t.add("ntf.notificationType", "Notification type", "messages", "en");
t.add("ntf.portalKundeGroups", "ARGUS Portal customer group(s)", "messages", "en");
t.add("ntf.additionalData", "Additional data (optional)", "messages", "en");
t.add("ntf.validFrom", "Valid from", "messages", "en");
t.add("ntf.validUntil", "Valid until", "messages", "en");
t.add("ntf.created", "Created", "messages", "en");
t.add("ntf.readCount", "read %counter% times", "messages", "en");
t.add("ntf.isCrucial", "Show after login", "messages", "en");
t.add("ntf.useGermanContentForEnglish", "Use german headline and content for english", "messages", "en");
t.add("ntf.preview", "Preview", "messages", "en");
t.add("ntf.active", "Active", "messages", "en");
t.add("ntf.availability", "Availability", "messages", "en");
t.add("ntf.showBigPicture", "Click to enlarge image", "messages", "en");
t.add("ntf.mobileClickToShow", "Click to open image", "messages", "en");
t.add("registration.confirmed", "Congratulations \"%username%\", your account has been activated.", "messages", "en");
t.add("newsletter2go.registration.success", "Your newsletter registration was successful.", "messages", "en");
t.add("newsletter2go.registration.error.part1", "Unfortunately your newsletter registration was unsuccessful. Please subscribe to the newsletter", "messages", "en");
t.add("newsletter2go.registration.error.part2", "here", "messages", "en");
t.add("newsletter2go.registration.error.emailDuplicate", "You have already registered for newsletter", "messages", "en");
t.add("newsletter2go.registration.error.emailInvalid", "Your email is invalid", "messages", "en");
t.add("newsletter2go.registration.error.placeholder", "Registration unsuccessful", "messages", "en");
t.add("ROLE_ADMIN", "Symfony-Admin", "messages", "en");
t.add("ROLE_SUPER_ADMIN", "Super-Admin", "messages", "en");
t.add("ROLE_MRA_ADMIN", "MRA-Configuration-Admin", "messages", "en");
t.add("ROLE_NEWS_ADMIN", "ARGUS Portal News Admin", "messages", "en");
t.add("ROLE_COMMENT", "Comment clippings", "messages", "en");
t.add("ROLE_A_COMMENT_GLOBAL", "Comment clippings global visible", "messages", "en");
t.add("ROLE_ADD_FOLDER", "Use folders", "messages", "en");
t.add("ROLE_ADD_GLOBAL_FOLDER", "Create global tags and folders", "messages", "en");
t.add("ROLE_TAGS", "Tagging of articles and media reviews", "messages", "en");
t.add("ROLE_A_TONALITY", "Set tonality", "messages", "en");
t.add("ROLE_A_AAEW", "Set AVE", "messages", "en");
t.add("ROLE_A_NO_KACHELDESIGN", "Media review magazine view active", "messages", "en");
t.add("ROLE_A_RUBRIC", "Set own categories", "messages", "en");
t.add("ROLE_A_RECYCLE_REVIEWS", "Recycle media reviews", "messages", "en");
t.add("ROLE_A_EDIT_CONTENT", "Change clippings headline and content", "messages", "en");
t.add("ROLE_A_ORDER_RECORDING", "Order (clips)", "messages", "en");
t.add("ROLE_A_ORDER_DIG", "Order (digital clips)", "messages", "en");
t.add("ROLE_A_FEED", "Create feed", "messages", "en");
t.add("ROLE_FEED_SORT", "Sort feed", "messages", "en");
t.add("ROLE_A_ALERT", "Create alert and newsletter", "messages", "en");
t.add("ROLE_EXPORT", "Premium export (data selection)", "messages", "en");
t.add("ROLE_EXPORT_MAIL", "Email posting of selected clippings", "messages", "en");
t.add("ROLE_A_PSP_CREATE", "Create media review (default)", "messages", "en");
t.add("ROLE_A_PSP_ACTIVE", "Active media review order", "messages", "en");
t.add("ROLE_A_PSP_CMS", "Media review redaction system", "messages", "en");
t.add("ROLE_A_DOCUMENT_PMG", "View licensed data", "messages", "en");
t.add("ROLE_A_DOCUMENT_NON_PMG", "View licensed data (except PMG)", "messages", "en");
t.add("ROLE_A_DOCUMENT_OPEN", "Document links without login check", "messages", "en");
t.add("ROLE_DOCUMENT_ARCHIVE_VIEW", "View digital document copy", "messages", "en");
t.add("ROLE_UPLOAD", "Edit\/upload clippings", "messages", "en");
t.add("ROLE_HIDE_TREFFER", "Hide clippings", "messages", "en");
t.add("ROLE_A_APPROVAL_ARTICLE", "Release editor for clippings", "messages", "en");
t.add("ROLE_A_APPROVAL_PSP", "Release editor for media reviews", "messages", "en");
t.add("ROLE_A_APPROVAL_VIEW", "View of non-released clippings and reviews", "messages", "en");
t.add("ROLE_ADMINISTRATOR", "Administrator", "messages", "en");
t.add("ROLE_LAYOUT_LOGO", "Upload customer logo", "messages", "en");
t.add("ROLE_LAYOUT", "Allowed to change layout and colors", "messages", "en");
t.add("ROLE_EMAIL_MANAGE", "Manage  email lists", "messages", "en");
t.add("ROLE_A_EDIT_FIELDS", "Manage clippings list (output fields)", "messages", "en");
t.add("ROLE_FILTER_CONTRACT", "Order filter", "messages", "en");
t.add("ROLE_EMAIL_ACCESS", "Access email lists", "messages", "en");
t.add("ROLE_A_DASHBOARD_VIEW", "Access dashboard", "messages", "en");
t.add("ROLE_A_DASHBOARD_ADMIN", "Dashboard admin", "messages", "en");
t.add("ROLE_A_DB", "Dashboard premium view", "messages", "en");
t.add("ROLE_A_DB_VIEWER", "Dashboard premium view (Read only)", "messages", "en");
t.add("ROLE_A_DB_IFRAME", "Dashboard iFrame Widget", "messages", "en");
t.add("ROLE_A_DB_TEMPLATE", "Dashboard templates", "messages", "en");
t.add("ROLE_A_TEASER", "Teaser admin", "messages", "en");
t.add("ROLE_DMA_ADMIN", "Administrator (Sales)", "messages", "en");
t.add("ROLE_A_REPOSITORY_PERIOD", "individual store term", "messages", "en");
t.add("ROLE_MASK", "hide", "messages", "en");
t.add("ROLE_EDIT_SUMMARY", "Edit summaries", "messages", "en");
t.add("ROLE_A_MRA", "View analysis documents", "messages", "en");
t.add("ROLE_A_MRA_EDIT", "Edit analysis data", "messages", "en");
t.add("ROLE_A_PS", "View Media Summaries", "messages", "en");
t.add("ROLE_A_PL", "View reports", "messages", "en");
t.add("ROLE_A_ALI", "Configutation of automatic search agents", "messages", "en");
t.add("ROLE_FEED_EDITOR_ARGUS", "Feed-Editor ARGUS (ext. content)", "messages", "en");
t.add("ROLE_FEED_EDITOR", "Feed-Editor (ext. content)", "messages", "en");
t.add("ROLE_FEED_READER", "Feed-Reader (ext. content)", "messages", "en");
t.add("ROLE_EMBEDDING_FEATURES", "Embedding features", "messages", "en");
t.add("ROLE_TALKWALKER_SSO", "Talkwalker SSO", "messages", "en");
t.add("ROLE_A_MS_TEAMS", "MS Teams integration", "messages", "en");
t.add("ROLE_A_FULL_VIDEO", "View full video (Spot)", "messages", "en");
t.add("l.cl_clippings", "Clipping list", "messages", "en");
t.add("l.cl_clippings_tiles", "Clipping tiles", "messages", "en");
t.add("l.db_load", "Dashboard", "messages", "en");
t.add("l.product_reports", "Report list", "messages", "en");
t.add("l.product_summaries", "Media summary list", "messages", "en");
t.add("l.pressespiegelListManage", "Media review list", "messages", "en");
t.add("l.psp_current", "Current media review (tiles)", "messages", "en");
t.add("l.msp_current_html", "Current Html media review (ARGUS-Admin)", "messages", "en");
t.add("l.msp_current_pdf", "Current PDF media review (ARGUS-Admin)", "messages", "en");
t.add("l.work_full_page", "Work", "messages", "en");
t.add("l.customer_admin", "User administration", "messages", "en");
t.add("l.ext_newsboard", "Newsboard", "messages", "en");
t.add("l.ext_media_review_archive", "Media review archive", "messages", "en");
t.add("l.adEnabled", "AD", "messages", "en");
t.add("db.title", "Title", "messages", "en");
t.add("db.defaultTitle", "Default", "messages", "en");
t.add("db.titleTypeTemplate", "Default title", "messages", "en");
t.add("db.titleTypeDefault", "Default title", "messages", "en");
t.add("db.titleTypeCustom", "Custom title", "messages", "en");
t.add("db.titleTypeNone", "No title", "messages", "en");
t.add("db.subtitle", "Subtitle", "messages", "en");
t.add("db.template", "Template", "messages", "en");
t.add("db.type", "Diagram type", "messages", "en");
t.add("db.noTemplate", "none", "messages", "en");
t.add("db.choose", "Please choose...", "messages", "en");
t.add("db.all", "all", "messages", "en");
t.add("db.allOrders", "All orders", "messages", "en");
t.add("db.benchmark", "Define benchmark", "messages", "en");
t.add("db.benchmarkBez", "Name", "messages", "en");
t.add("db.benchmarkFrom", "From", "messages", "en");
t.add("db.benchmarkTo", "To", "messages", "en");
t.add("db.groupMisc", "Group miscellaneous", "messages", "en");
t.add("db.groupMiscValue", "Value less than", "messages", "en");
t.add("db.threshold", "Define threshold", "messages", "en");
t.add("db.thresholdFrom", "From", "messages", "en");
t.add("db.thresholdTo", "To", "messages", "en");
t.add("db.topsources", "Define number of results", "messages", "en");
t.add("db.topsourcesFrom", "Maximum", "messages", "en");
t.add("db.ownWidget", "Define own widget", "messages", "en");
t.add("db.splined", "splined", "messages", "en");
t.add("db.stacked", "stacked", "messages", "en");
t.add("db.partitioned", "partitioned by media type", "messages", "en");
t.add("db.subeditionsExcluded", "Exclude subeditions", "messages", "en");
t.add("db.inverted", "inverted", "messages", "en");
t.add("db.medienart", "Media genre", "messages", "en");
t.add("db.xaxis", "x-axis", "messages", "en");
t.add("db.yaxis", "y-axis", "messages", "en");
t.add("db.level", "Choose level", "messages", "en");
t.add("db.timeGroup", "Group timeline", "messages", "en");
t.add("db.timeGroup0", "No grouping", "messages", "en");
t.add("db.timeGroup1", "By days", "messages", "en");
t.add("db.timeGroup2", "By months", "messages", "en");
t.add("db.timeGroup3", "By years", "messages", "en");
t.add("db.timeGroup4", "By weeks", "messages", "en");
t.add("db.calendarWeek", "CW", "messages", "en");
t.add("db.kennzahlen", "Reach", "messages", "en");
t.add("db.kennzahlenInTsd", "in k", "messages", "en");
t.add("db.kennzahlenTsd", "k", "messages", "en");
t.add("db.aaewInTsd", "in k \u20ac", "messages", "en");
t.add("db.aaewTsd", "k \u20ac", "messages", "en");
t.add("db.euroSign", "\u20ac", "messages", "en");
t.add("db.tsd", "k", "messages", "en");
t.add("db.mio", "M", "messages", "en");
t.add("db.mrd", "G", "messages", "en");
t.add("db.size", "Size", "messages", "en");
t.add("db.country", "Countries", "messages", "en");
t.add("db.germany", "Germany", "messages", "en");
t.add("db.dach", "DACH", "messages", "en");
t.add("db.europe", "Europe", "messages", "en");
t.add("db.world", "World", "messages", "en");
t.add("db.tagcloudTooltip", "Word occurence", "messages", "en");
t.add("db.defaultTemplate", "Default", "messages", "en");
t.add("db.lineTemplate", "Line chart", "messages", "en");
t.add("db.areaTemplate", "Area chart", "messages", "en");
t.add("db.barTemplate", "Bar chart", "messages", "en");
t.add("db.columnTemplate", "Column chart", "messages", "en");
t.add("db.pieTemplate", "Pie chart", "messages", "en");
t.add("db.donutTemplate", "Donut chart", "messages", "en");
t.add("db.listTemplate", "Top 10 list", "messages", "en");
t.add("db.tagcloudTemplate", "Tag cloud", "messages", "en");
t.add("db.countriesTemplate", "Countries", "messages", "en");
t.add("db.topSourcesTemplate", "Top sources", "messages", "en");
t.add("db.lastHeadlinesTemplate", "Last headlines", "messages", "en");
t.add("db.averageTonalityTemplate", "Average tonality", "messages", "en");
t.add("db.zeitMedienArtTemplate", "Media type over time", "messages", "en");
t.add("db.anzahlMedienArtTemplate", "Number of clippings per media type", "messages", "en");
t.add("db.verteilungMedienArtTemplate", "Distribution clippings per media type", "messages", "en");
t.add("db.iframeTemplate", "IFrame", "messages", "en");
t.add("db.imgTemplate", "IFrame-alternative external image", "messages", "en");
t.add("db.bewertungsart", "Valuation type", "messages", "en");
t.add("db.stopwords", "Exclude words (separate with space)", "messages", "en");
t.add("db.titleStandardEzmDb", "Real-time monitoring", "messages", "en");
t.add("db.branche", "Media sector", "messages", "en");
t.add("db.bundesland", "Federal state", "messages", "en");
t.add("db.erscheinungsweise", "Frequency", "messages", "en");
t.add("db.expressRubrik", "Sector", "messages", "en");
t.add("db.international", "International", "messages", "en");
t.add("db.kategorie", "Category", "messages", "en");
t.add("db.landescode", "Country", "messages", "en");
t.add("db.medienArt", "Media genre", "messages", "en");
t.add("db.medienTyp", "Media type", "messages", "en");
t.add("db.medium", "Media", "messages", "en");
t.add("db.nielsen", "Nielsen", "messages", "en");
t.add("db.sprache", "Language", "messages", "en");
t.add("db.sender", "Sender", "messages", "en");
t.add("db.alisuchprofil", "Search agent", "messages", "en");
t.add("db.feedSource", "Feed source", "messages", "en");
t.add("db.tonalitaet", "Rating", "messages", "en");
t.add("db.autor", "Author", "messages", "en");
t.add("db.aaew_gesamt", "AVE Total", "messages", "en");
t.add("db.aaew_relevant", "AVE Relevant", "messages", "en");
t.add("db.aaew", "AVE", "messages", "en");
t.add("db.medienrubrik", "Media category", "messages", "en");
t.add("db.zeit", "Time", "messages", "en");
t.add("db.mkzQuelle", "Source of range", "messages", "en");
t.add("db.mkzTyp", "Type of range", "messages", "en");
t.add("db.mraAufmerksamkeit", "Attention", "messages", "en");
t.add("db.mraDarstellungsform", "Display format", "messages", "en");
t.add("db.mraUrheber", "Originator", "messages", "en");
t.add("db.tags", "Tags", "messages", "en");
t.add("db.ordner", "Folder", "messages", "en");
t.add("db.suchbegriff", "Search terms", "messages", "en");
t.add("db.thema", "Topics", "messages", "en");
t.add("db.mraTop", "MRA Addon1", "messages", "en");
t.add("db.mraText1", "MRA Addon2", "messages", "en");
t.add("db.mraText2", "MRA Addon3", "messages", "en");
t.add("db.sonstige", "Miscellaneous", "messages", "en");
t.add("db.chooseMedienTyp", "Choose media type", "messages", "en");
t.add("db.chooseThema", "Choose MRA-Subject", "messages", "en");
t.add("db.chooseTemplate", "Select template", "messages", "en");
t.add("db.anzahl", "Number of", "messages", "en");
t.add("db.bewertung", "Number of evaluations", "messages", "en");
t.add("db.meldungen", "Number of reports", "messages", "en");
t.add("db.nennungen", "Number of mentions", "messages", "en");
t.add("db.reichweiten", "Reach", "messages", "en");
t.add("db.averageBewertung", "Average evaluation", "messages", "en");
t.add("db.optionsDb", "Menu", "messages", "en");
t.add("db.addDb", "Add new dashboard", "messages", "en");
t.add("db.reloadDb", "Reload", "messages", "en");
t.add("db.editDb", "Edit dashboard", "messages", "en");
t.add("db.cloneDb", "Duplicate dashboard", "messages", "en");
t.add("db.addWidget", "Add new widget", "messages", "en");
t.add("db.resortDbWidgets", "Resort widgets", "messages", "en");
t.add("db.resortDbTabs", "Resort dashboards", "messages", "en");
t.add("db.stopResortDb", "Save", "messages", "en");
t.add("db.exportDb", "Export dashboard", "messages", "en");
t.add("db.deleteDb", "Remove dashboard", "messages", "en");
t.add("db.addDbHeader", "Dashboard configuration", "messages", "en");
t.add("db.addDbTitle", "Title", "messages", "en");
t.add("db.addDbReference", "Reference", "messages", "en");
t.add("db.addDbVisible", "Visibility", "messages", "en");
t.add("db.addDbAuftrag", "Order", "messages", "en");
t.add("db.addDbSuchfilter", "Filter", "messages", "en");
t.add("db.addDbNewFilter", "Add new filter with current options", "messages", "en");
t.add("db.addDbTemplate", "Template", "messages", "en");
t.add("db.addDbVisibleCheckbox", "Visible for all", "messages", "en");
t.add("db.addDbVisibleCheckboxHint", "Hint: Dashboard wil be visible to other users if: a) at filter reference the filter is set to global in filter menu b) at contract reference the corespondent contract is visible to the user\n", "messages", "en");
t.add("db.resizeWidget", "Resize widget", "messages", "en");
t.add("db.resetZoomWidget", "Reset zoom", "messages", "en");
t.add("db.togglePointMarkersWidget", "Point markers on\/off", "messages", "en");
t.add("db.toggleDataLabelsWidget", "Data labels on\/off", "messages", "en");
t.add("db.toggleLegendWidget", "Legend on\/off", "messages", "en");
t.add("db.changeTypeWidget", "Change widget type", "messages", "en");
t.add("db.editWidget", "Edit widget", "messages", "en");
t.add("db.cloneWidget", "Duplicate widget", "messages", "en");
t.add("db.reloadWidget", "Reload", "messages", "en");
t.add("db.exportWidget", "Export widget", "messages", "en");
t.add("db.exportWidgetEmbed", "Embed into other platform", "messages", "en");
t.add("db.deleteWidget", "Delete widget", "messages", "en");
t.add("db.optionsWidget", "Options", "messages", "en");
t.add("db.noWidgetContent", "Your query did not return any results.", "messages", "en");
t.add("db.tooMuchResults", "Your query returns too much results for a clean view.", "messages", "en");
t.add("db.loadingInterrupted", "Loading data was interrupted, please reload!", "messages", "en");
t.add("db.errorLoadingTab", "An error occurred.", "messages", "en");
t.add("db.errorLoadingWidget", "An error occurred.", "messages", "en");
t.add("db.errorLoadingWidgetNoPermission", "No permissions", "messages", "en");
t.add("db.loadingWidget", "Result is loading...", "messages", "en");
t.add("db.errorLoadingDashboardNoPermission", "No permissions", "messages", "en");
t.add("db.emptyDataName", "NaN", "messages", "en");
t.add("db.dbDelete", "Delete dashboard", "messages", "en");
t.add("db.modalDelete", "Delete Widget", "messages", "en");
t.add("db.dbDeleteText", "Delete dashboard?", "messages", "en");
t.add("db.modalDeleteText", "Delete widget?", "messages", "en");
t.add("db.size0", "tiny", "messages", "en");
t.add("db.size1", "small", "messages", "en");
t.add("db.size2", "medium", "messages", "en");
t.add("db.size3", "big", "messages", "en");
t.add("db.valDbTitle", "Title cannot be blank", "messages", "en");
t.add("db.valDbLetters", "Allowed signs: letters, numbers, space, +, -", "messages", "en");
t.add("db.valDbLettersNumbersSigns", "Allowed signs: letters, numbers, signs", "messages", "en");
t.add("db.valDbLettersBench", "Allowed signs: numbers", "messages", "en");
t.add("db.valAuftrag", "Please choose an order", "messages", "en");
t.add("db.valSuchfilter", "Please choose a filter", "messages", "en");
t.add("db.valNewFilter", "New filter name must be filled", "messages", "en");
t.add("db.valDbTypeTemplate", "Please choose a template or define your own widget and choose a type", "messages", "en");
t.add("db.valDbTemplate", "Please choose a template", "messages", "en");
t.add("db.valDbType", "Please choose a type", "messages", "en");
t.add("db.valDbReference", "Set reference", "messages", "en");
t.add("db.valDbTimeGroup", "Set grouping of timeline", "messages", "en");
t.add("db.valDbGroupMisc", "Set value for grouping", "messages", "en");
t.add("db.valDbNoMkz", "Choose types of ratio", "messages", "en");
t.add("db.valDbNoTonalitaet", "Choose types of rating", "messages", "en");
t.add("db.valDbChooseLevel", "Choose Level", "messages", "en");
t.add("db.art", "Type", "messages", "en");
t.add("db.results", "Results", "messages", "en");
t.add("db.totalResults", "Total", "messages", "en");
t.add("db.exportEmbedHtml", "Share", "messages", "en");
t.add("db.updateInverval", "Update interval", "messages", "en");
t.add("db.validUntil", "Valid until", "messages", "en");
t.add("db.link", "Link", "messages", "en");
t.add("db.exportEmbedEditHtml", "Edit embeded links config", "messages", "en");
t.add("db.accessToken", "Access Token", "messages", "en");
t.add("db.defaultHeadlineForTemplateTagcloud", "Tagcloud", "messages", "en");
t.add("db.defaultHeadlineForTemplateCountries", "Geographic coverage", "messages", "en");
t.add("db.defaultHeadlineForTemplateTopSources", "Top sources", "messages", "en");
t.add("db.defaultHeadlineForTemplateLastHeadlines", "Last headlines", "messages", "en");
t.add("db.defaultHeadlineForTemplateZeitMart", "Media type over time", "messages", "en");
t.add("db.defaultHeadlineForTemplateAvgTonalitaet", "Sentiment", "messages", "en");
t.add("db.defaultHeadlineForTemplateVerteilungMart", "Share of clippings by media types", "messages", "en");
t.add("db.defaultHeadlineForTemplateAnzahlMart", "Number of clippings by media types over time", "messages", "en");
t.add("db.defaultHeadlineForTemplateIframe", "External Data", "messages", "en");
t.add("db.defaultHeadlineForTemplateImg", "External Picture", "messages", "en");
t.add("p.savePSP", "Save as draft", "messages", "en");
t.add("p.savePSPAndGenerate", "Generate", "messages", "en");
t.add("p.pspEdit", "Edit Media Review", "messages", "en");
t.add("p.selectFormat", "Select format(s)", "messages", "en");
t.add("p.selectConfig", "Select configuration", "messages", "en");
t.add("p.selectSelectionOrder", "Please choose ordering of categories", "messages", "en");
t.add("p.pleaseChooseConfig", "Please choose configuration", "messages", "en");
t.add("p.loadingConfigs", "Loading configuration(s)", "messages", "en");
t.add("p.loadingSectionOrders", "Loading category orders", "messages", "en");
t.add("p.pressReviews", "Media Reviews", "messages", "en");
t.add("p.pspziptitle", "Media_Reviews", "messages", "en");
t.add("p.createPSP", "Create media review", "messages", "en");
t.add("p.managementSummary", "Management Summary", "messages", "en");
t.add("p.enterManagementSummary", "Enter management summary", "messages", "en");
t.add("p.enterPspTitle", "Enter a media review title", "messages", "en");
t.add("p.pspVisibleAll", "Media review visible to all users?", "messages", "en");
t.add("p.PSP", "Media review", "messages", "en");
t.add("p.pspCategories", "Categories", "messages", "en");
t.add("p.pspArticles", "Articles", "messages", "en");
t.add("p.pspAllArticles", "All articles", "messages", "en");
t.add("p.pspDeletedArticles", "Deleted", "messages", "en");
t.add("p.noCategory", "no category", "messages", "en");
t.add("p.addClippings", "Add clippings", "messages", "en");
t.add("p.showAllSections", "Show all categories", "messages", "en");
t.add("p.hideEmptySections", "Hide empty categories", "messages", "en");
t.add("p.image", "Image", "messages", "en");
t.add("p.copyPsp2Folder", "Set media reviews to folder", "messages", "en");
t.add("p.copyPsp2Tag", "Tag media reviews", "messages", "en");
t.add("p.copyPsp2NewTag", "Tag media review with the new tag", "messages", "en");
t.add("p.delTagFromPsp", "Delete tag from media reviews", "messages", "en");
t.add("p.tilesView", "Tile view", "messages", "en");
t.add("p.tilesViewClose", "Close tile view", "messages", "en");
t.add("p.noteConfirm", "Confirm the note", "messages", "en");
t.add("p.mediaReviewGenerateDisabled", "Media review generation disabled from ", "messages", "en");
t.add("p.mediaReviewGenerateDisabledTo", " to", "messages", "en");
t.add("p.mediaReviewDisabledTypeOfValueNotValid", "The type of value is not valid,must be a number.", "messages", "en");
t.add("p.mediaReviewDisabledTimeNotValid", "The time is not valid.", "messages", "en");
t.add("p.mediaReviewDisabledTimeFromToNotValid", "The \"from\" time must be before \"to\" time.", "messages", "en");
t.add("p.mediaReviewDisabledTimeSaved", "Media review disabled time saved.", "messages", "en");
t.add("p.mediaReviewDisabledTimeNotSaved", "Media review disabled time not saved.", "messages", "en");
t.add("p.choosePDFConfig", "Select a PDF configuration", "messages", "en");
t.add("p.chooseHTMLConfig", "Select a HTML configuration", "messages", "en");
t.add("p.done", "Done", "messages", "en");
t.add("p.to", "Recipients", "messages", "en");
t.add("p.emailSubject", "Subject", "messages", "en");
t.add("p.emailMessage", "Message", "messages", "en");
t.add("p.download", "Download", "messages", "en");
t.add("p.emailSend", "send email", "messages", "en");
t.add("p.fileNotCreated", "The file could not be created.", "messages", "en");
t.add("p.pspNameEmpty", "Please enter a name for the media review", "messages", "en");
t.add("p.errorNoteConfirm", "Please confirm the note.", "messages", "en");
t.add("p.errorSelectPspFormat", "Please choose format.", "messages", "en");
t.add("p.errorNoArticleSelected", "Please select an article.", "messages", "en");
t.add("p.errorNoConfigurationSelected", "Please select a configuration", "messages", "en");
t.add("p.chooseConfiguration", "-- Please select a configuration --", "messages", "en");
t.add("p.errorCouldNotLoadConfigs", "Could not load configuration(s)", "messages", "en");
t.add("p.isCreated", "creating...", "messages", "en");
t.add("p.isGenerated", "creating files...", "messages", "en");
t.add("p.isImportingStart", "preparing articles for media review...", "messages", "en");
t.add("p.isImporting", "processing article %imported% of %total%...", "messages", "en");
t.add("p.isImportingFinished", "all articles has been processed...", "messages", "en");
t.add("p.isImportingError", "Error while processing articles", "messages", "en");
t.add("p.createdOn", "Created:", "messages", "en");
t.add("p.mspSaved", "Drafted:", "messages", "en");
t.add("p.lastModifiedOn", "Last update:", "messages", "en");
t.add("p.errorOnCreating", "Error while creating", "messages", "en");
t.add("p.errorRedirectPsp", "Could not redirect to the pressreview list", "messages", "en");
t.add("p.errorSavePsp", "The media review could not be saved.", "messages", "en");
t.add("p.errorGeneratePsp", "The media review could not be generated.", "messages", "en");
t.add("p.selectedPspAction", "Possible actions for the selected media reviews", "messages", "en");
t.add("p.headerImagePostionSelect", "Image position in Header", "messages", "en");
t.add("p.htmlPressReview", "HTML media review", "messages", "en");
t.add("p.recreate", "Recreate", "messages", "en");
t.add("p.validUntilArchivist", "Digital document copy", "messages", "en");
t.add("p.validUntil", "visible until", "messages", "en");
t.add("p.validUntilEndless", "no constraint", "messages", "en");
t.add("p.viewPdf", "view PDF", "messages", "en");
t.add("p.viewHtml", "view HTML", "messages", "en");
t.add("p.noPsp", "No media reviews", "messages", "en");
t.add("p.deleteConfirm", "Do you really want to delete this element?", "messages", "en");
t.add("p.errorDeleteConfig", "Could not delete this configuration. It is being used in the following active media reviews:", "messages", "en");
t.add("p.errorDeleteCoverpage", "The cover page configuration %configName% could not be deleted. It is being used in the following active configurations:", "messages", "en");
t.add("p.errorDeleteToc", "Could not delete the table of contents configuration. It is being used in the following active configurations:", "messages", "en");
t.add("p.errorDeleteUnknown", "An error occurred while deleting the configuration.", "messages", "en");
t.add("p.deleteSuccess", "the configuration \"%configName%\" has been successfully deleted", "messages", "en");
t.add("p.pspSaveSuccess", "Media review saved.", "messages", "en");
t.add("p.pspGenerateSuccess", "The media review will be generated.", "messages", "en");
t.add("p.articlePage", "Article detail page", "messages", "en");
t.add("p.alertMaxClippings", "You have selected too many clippings. The maximum for a Media Review is %maxPspClippings%.The first %maxPspClippings% clippings will be loaded.", "messages", "en");
t.add("p.noPspSelected", "No media reviews selected.", "messages", "en");
t.add("p.emailSendSuccess", "The email has been sent successfully.", "messages", "en");
t.add("p.emailChunkMessage", "The email has been sent in chunks with %maxEmailRecipients% recipients.", "messages", "en");
t.add("p.emailSendFail", "The email could not be sent.", "messages", "en");
t.add("p.articlesCut", "The number of clippings were cut to %articleNum%.", "messages", "en");
t.add("p.sortByDateModification", "modify date", "messages", "en");
t.add("p.sortByPspCreator", "creator", "messages", "en");
t.add("p.sortByPspTitle", "title", "messages", "en");
t.add("p.sortByPspState", "Status", "messages", "en");
t.add("p.pspCreator", "Creator", "messages", "en");
t.add("p.pspUsedAs", "used as", "messages", "en");
t.add("p.pspPrimarySource", "primary source", "messages", "en");
t.add("p.pspSecondarySource", "secondary source", "messages", "en");
t.add("p.of", "of", "messages", "en");
t.add("p.resetPspList", "Reload list", "messages", "en");
t.add("p.zipPsp", "Archive attachement (Zip - File)", "messages", "en");
t.add("p.showInClipList", "View clippings", "messages", "en");
t.add("p.pspDeletedSuccess", "The media review has been deleted successfully.", "messages", "en");
t.add("p.pspDeletedFail", "The media review could not be deleted.", "messages", "en");
t.add("p.dmaRecreatePsp", "Create new media review with these contents", "messages", "en");
t.add("p.standardEmailSubject", "Specify the default email subject for the media review emails for this configuration (optional)", "messages", "en");
t.add("p.standardEmailMessage", "Specify the default message for the media review emails for this configuration (optional)", "messages", "en");
t.add("p.emailRecipientsVisible", "Show all recipients", "messages", "en");
t.add("p.emailRecipientsVisibleLimitHint", "Function is limited to %count% recipients", "messages", "en");
t.add("p.attachement", "Attachement", "messages", "en");
t.add("p.emailKonfig", "Template", "messages", "en");
t.add("p.pspEmailKonfig", "Template", "messages", "en");
t.add("p.includeDateInSubject", "Include the current date in the email subject ( ...,%currDate%)", "messages", "en");
t.add("p.selectEmail", "Select an email", "messages", "en");
t.add("p.maxDragDrop", "The maximum of 250 articles for drag&drop reached. Drag&drop is disabled.", "messages", "en");
t.add("p.maxArticles", "The maximum of 800 articles reached.", "messages", "en");
t.add("p.pspEmailKonfigHint", "Uses subject, text and footer settings from template", "messages", "en");
t.add("p.Autor", "Author", "messages", "en");
t.add("p.Reichweite", "Range", "messages", "en");
t.add("p.Seite", "Page", "messages", "en");
t.add("p.Anriss", "Teaser", "messages", "en");
t.add("p.Tag", "Tag", "messages", "en");
t.add("p.Kommentar", "Comment", "messages", "en");
t.add("p.Gesamtbewertung", "Total rating", "messages", "en");
t.add("p.mq", "Multiple sources", "messages", "en");
t.add("p.mqClippingListHint", "Move clipping to the right, to set this as additional source.", "messages", "en");
t.add("p.statusDraft", "Draft", "messages", "en");
t.add("p.statusError", "Error", "messages", "en");
t.add("p.sectionOrder", "Order", "messages", "en");
t.add("p.alphabeticalOrder", "alphabetical order", "messages", "en");
t.add("p.individualOrder", "individual order", "messages", "en");
t.add("p.individual", "Individual", "messages", "en");
t.add("p.bySubscription", "by subscription", "messages", "en");
t.add("p.msgLoadConfigFailed", "Could not load configurations", "messages", "en");
t.add("p.msgLoadSectionOrderFailed", "Could not load category order", "messages", "en");
t.add("p.config", "Configuration", "messages", "en");
t.add("p.deleteConfirmObject", "Do you really want to delete the %delObject%?", "messages", "en");
t.add("p.deleteObject", "delete %delObject%", "messages", "en");
t.add("p.insertAfterSection", "insert selected category(s) after this one", "messages", "en");
t.add("p.insertAfterArticle", "insert after the clipping", "messages", "en");
t.add("p.insertAfterSubarticle", "insert after the source", "messages", "en");
t.add("p.insertAsSectionArticle", "add to category", "messages", "en");
t.add("p.insertAsSubarticle", "add as source", "messages", "en");
t.add("p.insertBeforeSection", "add category here", "messages", "en");
t.add("p.toggleObjectList", "expand\/collapse %object%", "messages", "en");
t.add("p.expandObjectList", "expand %object%", "messages", "en");
t.add("p.collapseObjectList", "collapse %object%", "messages", "en");
t.add("p.toggleSelectSection", "(un)select to move category", "messages", "en");
t.add("p.setSubarticleState", "{2} not activatable source | {1} set source passive | {0} set source active", "messages", "en");
t.add("p.functionIsDisabled", "This functionality is disabled for the current selection.", "messages", "en");
t.add("p.insertIntoAssignedCategories", "insert into assigned categories", "messages", "en");
t.add("p.insertIntoAssignedCategoriesFullText", "insert selected clippings into the categories defined for each clipping, or \"no category\" if not defined", "messages", "en");
t.add("p.alreadyContainsClippings", "The following clippings are already included", "messages", "en");
t.add("p.section", "category", "messages", "en");
t.add("p.article", "clipping", "messages", "en");
t.add("p.source", "source", "messages", "en");
t.add("p.moreSources", "additional sources", "messages", "en");
t.add("p.passiveOnly", "not activatable", "messages", "en");
t.add("upload", "Upload", "messages", "en");
t.add("f.fileUploaded", "Done", "messages", "en");
t.add("f.UploadedSave", "Please save", "messages", "en");
t.add("fileUploadValue", "File search...", "messages", "en");
t.add("fontfamily", "Font", "messages", "en");
t.add("fontsize", "Font size", "messages", "en");
t.add("fontcolor", "Font color", "messages", "en");
t.add("choose", "Choose", "messages", "en");
t.add("c.contact", "Contact", "messages", "en");
t.add("c.form", "Form", "messages", "en");
t.add("c.sender", "Sender", "messages", "en");
t.add("c.name", "Name:", "messages", "en");
t.add("c.emailAdr", "Email:", "messages", "en");
t.add("c.telephone", "Telephone:", "messages", "en");
t.add("c.content", "Content", "messages", "en");
t.add("c.request", "Please contact me regarding:", "messages", "en");
t.add("c.message", "Your message:", "messages", "en");
t.add("c.account", "my account", "messages", "en");
t.add("c.order", "my order", "messages", "en");
t.add("c.portal", "the ARGUS Portal", "messages", "en");
t.add("c.advice", "advice", "messages", "en");
t.add("js.loadingMessage", "Loading data, please wait...", "messages", "en");
t.add("js.savingMessage", "Saving data, please wait...", "messages", "en");
t.add("js.loadingErrorMessage", "Error loading data", "messages", "en");
t.add("js.unkownErrorMessage", "Unknown error", "messages", "en");
t.add("js.loadingMediumMessage", "Loading media data, please wait...", "messages", "en");
t.add("js.loadingAdresseMessage", "Loading address data, please wait...", "messages", "en");
t.add("select2.formatNoMatches", "Found no results", "messages", "en");
t.add("select2.formatInputTooShort", "Please enter at least %cnt% characters", "messages", "en");
t.add("select2.formatInputTooLong", "{1} Please enter %cnt% less character |]1,Inf] Please enter %cnt% less characters", "messages", "en");
t.add("select2.formatSelectionTooBig", "{1} You can only select %cnt% item |]1,Inf] You can only select %cnt% items", "messages", "en");
t.add("select2.formatLoadMore", "Loading more results...", "messages", "en");
t.add("select2.formatSearching", "Searching...", "messages", "en");
t.add("cd.headline", "Headline", "messages", "en");
t.add("cd.www", "Link", "messages", "en");
t.add("cd.datum", "Date of publication", "messages", "en");
t.add("cd.auftrag", "Order", "messages", "en");
t.add("cd.abo", "Subject", "messages", "en");
t.add("cd.teaser", "Teaser", "messages", "en");
t.add("cd.meldung", "Note", "messages", "en");
t.add("cd.suchbegriff", "Search terms", "messages", "en");
t.add("cd.beitragslaenge", "Clipping length", "messages", "en");
t.add("cd.gesamtTonalitaet", "Total rating", "messages", "en");
t.add("cd.tag", "Tags", "messages", "en");
t.add("cd.ordner", "Folders", "messages", "en");
t.add("cd.kommentar", "Comments", "messages", "en");
t.add("cd.expressrubrik", "Sector", "messages", "en");
t.add("cd.titel", "Media title", "messages", "en");
t.add("cd.newMedium", "Define own media", "messages", "en");
t.add("cd.untertitel", "Subtitle", "messages", "en");
t.add("cd.medienarttyp", "Media genre and type", "messages", "en");
t.add("cd.branche", "Media sector", "messages", "en");
t.add("cd.bundesland", "Federal state", "messages", "en");
t.add("cd.landescode", "Country", "messages", "en");
t.add("cd.erscheinungsweise", "Frequency", "messages", "en");
t.add("cd.international", "International", "messages", "en");
t.add("cd.sprache", "Language", "messages", "en");
t.add("cd.verlag", "Publisher", "messages", "en");
t.add("cd.redaktion", "Editorial office", "messages", "en");
t.add("cd.sender", "Broadcast station", "messages", "en");
t.add("cd.nielsen", "Nielsen", "messages", "en");
t.add("cd.kategorie", "Category", "messages", "en");
t.add("cd.dimension", "Dimension", "messages", "en");
t.add("cd.seite", "Page", "messages", "en");
t.add("cd.reichweite", "Range", "messages", "en");
t.add("cd.aaew", "AVE Total", "messages", "en");
t.add("cd.dokument", "Documents", "messages", "en");
t.add("cd.noEditPermission", "You dont have permission to edit.", "messages", "en");
t.add("cd.artikel", "Article", "messages", "en");
t.add("cd.medium", "Media", "messages", "en");
t.add("cd.dokumente", "Documents", "messages", "en");
t.add("cd.add", "add", "messages", "en");
t.add("cd.createItem", "create %item%", "messages", "en");
t.add("cd.addItem", "add %item%", "messages", "en");
t.add("cd.rubrikAdd", "Add sector", "messages", "en");
t.add("cd.tagAdd", "Create and add tag", "messages", "en");
t.add("cd.ordnerAdd", "Create and add folder", "messages", "en");
t.add("cd.kommentarAdd", "Add comment", "messages", "en");
t.add("cd.dokumentUpload", "Add document", "messages", "en");
t.add("cd.mkzTypAdd", "Add range", "messages", "en");
t.add("cd.viewDokument", "view", "messages", "en");
t.add("cd.delete", "delete", "messages", "en");
t.add("cd.choose", "Choose...", "messages", "en");
t.add("cd.editThema", "Edit subjects", "messages", "en");
t.add("cd.editThemaTonalitaet", "Ratings", "messages", "en");
t.add("cd.allowedFormats", "Allowed file formats", "messages", "en");
t.add("cd.mspHint", "Media Review: Only PDF and JPEG\/JPG will be taken into account. Display order within the media review matches upload order.", "messages", "en");
t.add("cd.hint", "Hint:", "messages", "en");
t.add("cd.trefferDelete", "Delete clipping", "messages", "en");
t.add("cd.trefferDeleteText", "Do you really want to delete this clipping?", "messages", "en");
t.add("cd.emailFileTip", " (each line one e-mail)", "messages", "en");
t.add("cd.emailUploadSuccess", "All e-mails has been added.", "messages", "en");
t.add("cd.emailUploadFileError", "The file could not be read. Please check the format.", "messages", "en");
t.add("cd.emailUploadDataError", "The following e-mails are not valid:", "messages", "en");
t.add("cd.file_upload", "File upload \"%file%\": ", "messages", "en");
t.add("cd.valCdLettersNumbersSigns", "Allowed signs: letters, digits, space, +, -, \/", "messages", "en");
t.add("cd.valCdUrl", "Invalid URL", "messages", "en");
t.add("cd.valCdDate", "Invalid date", "messages", "en");
t.add("cd.valCdTime", "Invalid time", "messages", "en");
t.add("cd.valCdIllegalKey", "Invalid key", "messages", "en");
t.add("cd.valCdInvalidSigns", "Invalid signs", "messages", "en");
t.add("cd.valCdInvalidNumbers", "Only numbers allowed", "messages", "en");
t.add("cd.valCdChooseSubject", "Please choose subject", "messages", "en");
t.add("cd.valCdChooseTitle", "Choose media title", "messages", "en");
t.add("cd.valCdChooseMedienArtTyp", "Choose media genre", "messages", "en");
t.add("cd.valCdChooseAdrBez", "Choose name", "messages", "en");
t.add("cd.valCdChooseAbo", "Choose subject", "messages", "en");
t.add("cd.valNoAuftragSelected", "Choose order", "messages", "en");
t.add("cd.valCdFloat", "Invalid number", "messages", "en");
t.add("cd.valCdLanguage", "Please select the language", "messages", "en");
t.add("cd.valCdEmpty", "Please fill in", "messages", "en");
t.add("cd.logoHeightHint", "The logo will be scaled to 40 pixel.", "messages", "en");
t.add("cd.validHeadlineLength", "The length of the Headline field is limited to %limit% characters", "messages", "en");
t.add("cd.validSubtitleLength", "The length of the Subtitle field is limited to %limit% characters", "messages", "en");
t.add("cd.validTeaserLength", "The length of the Teaser field is limited to %limit% characters", "messages", "en");
t.add("cd.validSectionLength", "The length of the Category field is limited to %limit% characters", "messages", "en");
t.add("cd.validPageLength", "The length of the page field is limited to %limit% characters", "messages", "en");
t.add("cd.error415", "This format is not supported.", "messages", "en");
t.add("cd.error406", "File is too big. Size is limited to %maxval% MB.", "messages", "en");
t.add("cd.error406_imgheight", "The image height is not correct. This must be exact %imgheight% pixel.", "messages", "en");
t.add("cd.error500", "An error occurred.", "messages", "en");
t.add("cd.error002", "Currently it is restricted to upload only one additional file per file type.", "messages", "en");
t.add("cd.uploadError", "Error while file upload.", "messages", "en");
t.add("rss.admin", "Feeds", "messages", "en");
t.add("rss.name", "Name", "messages", "en");
t.add("rss.database", "Database", "messages", "en");
t.add("rss.suchfilter", "Filter", "messages", "en");
t.add("rss.auftrag", "Orders", "messages", "en");
t.add("rss.abo", "Subjects", "messages", "en");
t.add("rss.suchbegriff", "Search terms", "messages", "en");
t.add("rss.medienart", "Media genres", "messages", "en");
t.add("rss.lengthheadline", "Length of headline", "messages", "en");
t.add("rss.signs", "Signs", "messages", "en");
t.add("rss.komplett", "full", "messages", "en");
t.add("rss.leer", "empty", "messages", "en");
t.add("rss.lengthmeldung", "Length of note", "messages", "en");
t.add("rss.results", "Results", "messages", "en");
t.add("rss.countresults", "Number of results", "messages", "en");
t.add("rss.heute", "today", "messages", "en");
t.add("rss.heuteundgestern", "yesterday and today", "messages", "en");
t.add("rss.aktuelleWoche", "current week", "messages", "en");
t.add("rss.ausgabe", "Display", "messages", "en");
t.add("rss.showvolltext", "full abstracts", "messages", "en");
t.add("rss.showtonalitaet", "Show total rating", "messages", "en");
t.add("rss.showSubEditions", "Show sub editions", "messages", "en");
t.add("rss.ipconstraint", "IP constraint", "messages", "en");
t.add("rss.intranetDokument", "show documents only in intranet", "messages", "en");
t.add("rss.intranetall", "show full feed only in intranet", "messages", "en");
t.add("rss.noconstraint", "show all", "messages", "en");
t.add("rss.ipaddress", "IP Addresses", "messages", "en");
t.add("rss.ipaddressFormat", "IP Addresses and ranges must be devided by semikolon \";\". Following formats are allowed:", "messages", "en");
t.add("rss.ipaddressFormat1", "IP Address: 123.234.123.234", "messages", "en");
t.add("rss.ipaddressFormat2", "Wildcards: 123.234.123.*", "messages", "en");
t.add("rss.ipaddressFormat3", "CIDR: 123.234.1.0\/24", "messages", "en");
t.add("rss.ipaddressFormat4", "Start-end range: 123.234.123.0-123.234.123.255", "messages", "en");
t.add("rss.language", "Language", "messages", "en");
t.add("rss.de", "German", "messages", "en");
t.add("rss.en", "English", "messages", "en");
t.add("rss.active", "Active", "messages", "en");
t.add("rss.feedType", "Feed type", "messages", "en");
t.add("rss.JsonFeed", "JSON Feed", "messages", "en");
t.add("rss.XmlFeed", "XML Feed", "messages", "en");
t.add("rss.addRss", "RSS feed", "messages", "en");
t.add("rss.rssDelete", "Delete RSS feed", "messages", "en");
t.add("rss.rssDeleteText", "Do you really want to delete this feed?", "messages", "en");
t.add("rss.linkTagAsDocument", "Link tag as Document", "messages", "en");
t.add("rss.valRssInvalidKey", "Invalid choice.", "messages", "en");
t.add("rss.valRssError", "An error occurred.", "messages", "en");
t.add("rss.valRssInvalidName", "Please enter a name. Valid signs: Letters, spaces and numbers.", "messages", "en");
t.add("rss.valRssSetValidIp", "Please enter a valid IP Address.", "messages", "en");
t.add("rss.valRssChooseFilter", "Please choose a filter.", "messages", "en");
t.add("rss.valRssChooseAuftrag", "Please choose at least one order.", "messages", "en");
t.add("rss.maxReachedCount", "The maximum number of %count% RSS-Feeds has been reached.", "messages", "en");
t.add("rss.maxReached", "The maximum number of RSS-Feeds has been reached.", "messages", "en");
t.add("rss.maxReachedModalHeader", "Hint", "messages", "en");
t.add("rss.noTitle", "No title", "messages", "en");
t.add("rss.noDescription", "No content available", "messages", "en");
t.add("rss.tonalitaet", "Rating", "messages", "en");
t.add("rss.empty", "", "messages", "en");
t.add("api.feedLimit", "(%currentNumber%\/%maxNumber%)", "messages", "en");
t.add("api.clipping", "Clipping data", "messages", "en");
t.add("api.mediaData", "Media data", "messages", "en");
t.add("api.analysis", "Analysis data", "messages", "en");
t.add("api.typeOfRange", "Type of range", "messages", "en");
t.add("api.mediaFiles", "Media files", "messages", "en");
t.add("api.updateInterval", "Update Interval", "messages", "en");
t.add("api.feedDuration", "Feed Duration", "messages", "en");
t.add("api.sortBy", "Sort By", "messages", "en");
t.add("api.includeHtmlTags", "Include HTML tags", "messages", "en");
t.add("api.excludeSubedition", "Exclude subeditions", "messages", "en");
t.add("api.exportFieldNaming", "Export Field naming", "messages", "en");
t.add("api.projectId", "Project ID", "messages", "en");
t.add("api.accessToken", "Access token", "messages", "en");
t.add("api.exportFieldNamingDefault", "Default", "messages", "en");
t.add("api.exportFieldNamingEnBW", "EnBW", "messages", "en");
t.add("api.exportFieldNamingTalkwalker", "Talkwalker", "messages", "en");
t.add("api.selectAll", "Select all", "messages", "en");
t.add("api.deselectAll", "Deselect all", "messages", "en");
t.add("api.settings", "Settings", "messages", "en");
t.add("api.id", "Id", "messages", "en");
t.add("api.headline", "Headline", "messages", "en");
t.add("api.subtitle", "Subtitle", "messages", "en");
t.add("api.issueNumber", "Issue Number", "messages", "en");
t.add("api.publicationDate", "Publication Date", "messages", "en");
t.add("api.searchTerms", "Search Terms", "messages", "en");
t.add("api.category", "Category", "messages", "en");
t.add("api.pressSummary", "Press Summary", "messages", "en");
t.add("api.abstract", "Note", "messages", "en");
t.add("api.teaser", "Teaser", "messages", "en");
t.add("api.searchTermContext", "Search Term Context", "messages", "en");
t.add("api.AVEtotal", "AVE total", "messages", "en");
t.add("api.totalRating", "Total Rating", "messages", "en");
t.add("api.deeplink", "Deeplink", "messages", "en");
t.add("api.clippingLength", "Clipping length", "messages", "en");
t.add("api.numberOfCharacters", "Number of characters (clipping)", "messages", "en");
t.add("api.order", "Order", "messages", "en");
t.add("api.subject", "Subject", "messages", "en");
t.add("api.formatRTV", "Format (RTV)", "messages", "en");
t.add("api.author", "Author", "messages", "en");
t.add("api.language", "Language", "messages", "en");
t.add("api.page", "Page", "messages", "en");
t.add("api.mediaTitle", "Media title", "messages", "en");
t.add("api.editorialOffice", "Editorial office", "messages", "en");
t.add("api.publisher", "Publisher ", "messages", "en");
t.add("api.broadcastStation", "Broadcast station", "messages", "en");
t.add("api.mediaGenre", "Media genre", "messages", "en");
t.add("api.mediaType", "Media type", "messages", "en");
t.add("api.mediaSector", "Media sector", "messages", "en");
t.add("api.federalState", "Federal state", "messages", "en");
t.add("api.country", "Country", "messages", "en");
t.add("api.countryIso1", "ISO Country Code 1", "messages", "en");
t.add("api.countryIso2", "ISO Country Code 2", "messages", "en");
t.add("api.frequency", "Frequency", "messages", "en");
t.add("api.mediumID", "Medium ID", "messages", "en");
t.add("api.mediaUrl", "URL Medium", "messages", "en");
t.add("api.analysis.subject", "Subject", "messages", "en");
t.add("api.rating", "Rating", "messages", "en");
t.add("api.AVE", "AVE", "messages", "en");
t.add("api.text1", "Text 1", "messages", "en");
t.add("api.text2", "Text 2", "messages", "en");
t.add("api.text3", "Text 3", "messages", "en");
t.add("api.ARGUSPortal1", "ARGUS Portal 1", "messages", "en");
t.add("api.ARGUSPortal2", "ARGUS Portal 2", "messages", "en");
t.add("api.ARGUSPortal3", "ARGUS Portal 3", "messages", "en");
t.add("api.ARGUSPortal4", "ARGUS Portal 4", "messages", "en");
t.add("api.ARGUSPortal5", "ARGUS Portal 5", "messages", "en");
t.add("api.portal1", "ARGUS Portal 1", "messages", "en");
t.add("api.portal2", "ARGUS Portal 2", "messages", "en");
t.add("api.portal3", "ARGUS Portal 3", "messages", "en");
t.add("api.portal4", "ARGUS Portal 4", "messages", "en");
t.add("api.portal5", "ARGUS Portal 5", "messages", "en");
t.add("api.topSubject", "Top Subject", "messages", "en");
t.add("api.source", "Source", "messages", "en");
t.add("api.sourceCategory", "Source category", "messages", "en");
t.add("api.pressRelease", "Press release", "messages", "en");
t.add("api.keyMessage", "Key message", "messages", "en");
t.add("api.attention", "Attention", "messages", "en");
t.add("api.attentionType", "Attention type", "messages", "en");
t.add("api.imageFactor", "Image factor", "messages", "en");
t.add("api.ratingImageFactor", "Rating image factor", "messages", "en");
t.add("api.issues", "Issues", "messages", "en");
t.add("api.tonalityIssues", "Tonality issues", "messages", "en");
t.add("api.themaEbens1", "Thema Ebens 1", "messages", "en");
t.add("api.themaEbens2", "Thema Ebens 2", "messages", "en");
t.add("api.themaEbens3", "Thema Ebens 3", "messages", "en");
t.add("api.topicData", "Topic Data", "messages", "en");
t.add("api.grossReach", "Gross reach", "messages", "en");
t.add("api.printRun", "Print run", "messages", "en");
t.add("api.copiesSold", "Copies sold", "messages", "en");
t.add("api.copiesDistributed", "Copies distributed", "messages", "en");
t.add("api.visits", "Visits", "messages", "en");
t.add("api.pageViews", "Page views", "messages", "en");
t.add("api.uniqueUser", "Unique User", "messages", "en");
t.add("api.mediaLogo", "Media logo", "messages", "en");
t.add("api.mediaCategory", "Media category", "messages", "en");
t.add("api.videoThumbnail", "Video thumbnail", "messages", "en");
t.add("api.picture", "Picture", "messages", "en");
t.add("api.PDFlink", "PDF (link)", "messages", "en");
t.add("api.displayFormat", "Display format", "messages", "en");
t.add("api.linkToOriginalVideo", "Link to original video", "messages", "en");
t.add("api.fullTextPressRelease", "Full text press release", "messages", "en");
t.add("api.sort.dateAscending", "Date ascending", "messages", "en");
t.add("api.sort.dateDescending", "Date descending", "messages", "en");
t.add("api.sort.reachAscending", "Reach ascending", "messages", "en");
t.add("api.sort.reachDescending", "Reach descending", "messages", "en");
t.add("api.15min", "15 min", "messages", "en");
t.add("api.60min", "Every 60 min", "messages", "en");
t.add("api.4hour", "Every 4 hour", "messages", "en");
t.add("api.twiceADay", "Twice a day", "messages", "en");
t.add("api.onceADay", "Once a day", "messages", "en");
t.add("api.onceAWeek", "Once a week", "messages", "en");
t.add("api.never", "Never", "messages", "en");
t.add("api.1month", "1 month", "messages", "en");
t.add("api.6weeks", "6 weeks", "messages", "en");
t.add("api.6months", "6 months", "messages", "en");
t.add("api.12months", "12 months", "messages", "en");
t.add("api.24months", "24 months", "messages", "en");
t.add("api.forever", "Forever", "messages", "en");
t.add("api.copy", "Copy", "messages", "en");
t.add("api.last7days", "Last 7 days", "messages", "en");
t.add("api.feedId", "Custom Feed ID", "messages", "en");
t.add("api.expirationDate", "Expiration date:", "messages", "en");
t.add("api.addJSON", "JSON Feed", "messages", "en");
t.add("api.addXML", "XML Feed", "messages", "en");
t.add("api.feedIdValidation", "Please enter a different feed id, that one is currently being used by someone in your company", "messages", "en");
t.add("api.nameValidation", "Error: This name is already being used.", "messages", "en");
t.add("se.createNewMedium", "Create \"medium\" as a new media", "messages", "en");
t.add("se.createNewMediumAddress", "Create \"###\" as a new address", "messages", "en");
t.add("docart", "{10,80} PDF document |{20,65,82} XML document |{22,81} TXT document |{23} HTML document |{25,26,83,126} Word document |{30,31,32,34,87,132,133} TV clip |{33,130} TV thumbnail |{35,86,172,173} Radio clip |{-35,171} Radio clip preview |{36,37,131} TV clip preview |{60} MRA Sujet sheet |{84} PPT document |{85,88} Image", "messages", "en");
t.add("docartMobile", "{10,80} Download PDF document |{20,65,82} Download XML document |{22,81} Download TXT document |{23} Download HTML document |{25,26,83,126} Download Word document |{30,31,32,34,87,132,133} Play TV clip |{33,130} Show TV thumbnail |{35,86,172,173} Play Radio clip |{-35,171} Play Radio clip preview |{36,37,131} Play TV clip preview |{60} Download MRA Sujet sheet |{84} PPT document |{85,88} Image", "messages", "en");
t.add("ak.auftragKonfig", "MRA Auftrags-Konfiguration", "messages", "en");
t.add("ak.auftrag", "Auftrag", "messages", "en");
t.add("ak.abo", "Standard MRA-Abo", "messages", "en");
t.add("ak.vorlage", "Vorlage", "messages", "en");
t.add("ak.mraAufmerksamkeit", "Aufmerksamkeit", "messages", "en");
t.add("ak.mraAufmerksamkeitArt", "Aufmerksamkeit Art", "messages", "en");
t.add("ak.mraDarstellungsform", "Darstellungsform", "messages", "en");
t.add("ak.mraDarstellungsformArt", "DarstellungsformArt", "messages", "en");
t.add("ak.mraImagefaktor", "Imagefaktoren", "messages", "en");
t.add("ak.mraIssues", "Issues", "messages", "en");
t.add("ak.mraIssuesArt", "Issues Art", "messages", "en");
t.add("ak.mraKernbotschaft", "Kernbotschaft", "messages", "en");
t.add("ak.mraTop", "Top Thema", "messages", "en");
t.add("ak.mraText1", "Text 1", "messages", "en");
t.add("ak.mraText2", "Text 2", "messages", "en");
t.add("ak.mraText3", "Text 3", "messages", "en");
t.add("ak.mraPortal1", "ARGUS Portal 1", "messages", "en");
t.add("ak.mraPortal2", "ARGUS Portal 2", "messages", "en");
t.add("ak.mraPortal3", "ARGUS Portal 3", "messages", "en");
t.add("ak.mraPortal4", "ARGUS Portal 4", "messages", "en");
t.add("ak.mraPortal5", "ARGUS Portal 5", "messages", "en");
t.add("ak.mraPressemitteilung", "Pressemitteilung", "messages", "en");
t.add("ak.mraQuelle", "Quelle", "messages", "en");
t.add("ak.mraQuelleKategorie", "Quelle Kategorie", "messages", "en");
t.add("ak.mraUrheber", "Urheber", "messages", "en");
t.add("ak.mraUrheberKategorie", "Urheber Kategorie", "messages", "en");
t.add("ak.mraThema", "Thema", "messages", "en");
t.add("ak.mraWgk", "Wiedergabe korrekt", "messages", "en");
t.add("ak.mraWgv", "Wiedergabe vollst\u00e4ndig", "messages", "en");
t.add("ak.gesamtTonalitaet", "Gesamtbewertung", "messages", "en");
t.add("ak.themaTonalitaet", "Tonalit\u00e4t Thema", "messages", "en");
t.add("ak.issuesTonalitaet", "Tonalit\u00e4t Issues", "messages", "en");
t.add("ak.imagefaktorTonalitaet", "Tonalit\u00e4t Imagefaktoren", "messages", "en");
t.add("ak.themenebene", "Ebene", "messages", "en");
t.add("ak.themenebeneHeadline", "Themenebenen", "messages", "en");
t.add("ak.mraAufmerksamkeitDefault", "{1} erzielte Aufmerksamkeit |{2} Attention", "messages", "en");
t.add("ak.mraAufmerksamkeitArtDefault", "{1} erzielte Aufmerksamkeit Art |{2} Attention type", "messages", "en");
t.add("ak.mraDarstellungsformDefault", "{1} Darstellungsform |{2} Display format", "messages", "en");
t.add("ak.mraDarstellungsformArtDefault", "{1} Darstellungsform Art |{2} Display format type", "messages", "en");
t.add("ak.mraImagefaktorDefault", "{1} Imagefaktor |{2} Image factor", "messages", "en");
t.add("ak.imagefaktorTonalitaetDefault", "{1} Tonalit\u00e4t Imagefaktor |{2} Rating Image factor", "messages", "en");
t.add("ak.mraIssuesDefault", "{1} Issues |{2} Issues", "messages", "en");
t.add("ak.mraIssuesArtDefault", "{1} Issues Art |{2} Issues type", "messages", "en");
t.add("ak.mraKernbotschaftDefault", "{1} Kernbotschaft |{2} Key message", "messages", "en");
t.add("ak.mraPressemitteilungDefault", "{1} Pressemitteilung |{2} Press release", "messages", "en");
t.add("ak.mraQuelleDefault", "{1} Quelle |{2} Source", "messages", "en");
t.add("ak.mraQuelleKategorieDefault", "{1} Quelle Kategorie |{2} Source category", "messages", "en");
t.add("ak.mraPortalDefault", "{1} ARGUS Portal |{2} ARGUS Portal", "messages", "en");
t.add("ak.mraTextDefault", "{1} Text |{2} Text", "messages", "en");
t.add("ak.mraTopDefault", "{1} Top Thema |{2} Top Subject", "messages", "en");
t.add("ak.mraThemaDefault", "{1} Thema |{2} Subject", "messages", "en");
t.add("ak.mraUrheberDefault", "{1} Urheber |{2} Author", "messages", "en");
t.add("ak.mraUrheberKategorieDefault", "{1} Urheber Kategorie |{2} Author category", "messages", "en");
t.add("ak.mraWgkDefault", "{1} Wiedergabe korrekt |{2} Correct Repetition", "messages", "en");
t.add("ak.mraWgvDefault", "{1} Wiedergabe vollst\u00e4ndig |{2} Complete Repetition", "messages", "en");
t.add("ak.gesamtTonalitaetDefault", "{1} Gesamtbewertung |{2} Total rating ", "messages", "en");
t.add("ak.themaTonalitaetDefault", "{1} Tonalit\u00e4t |{2} Rating", "messages", "en");
t.add("ak.issuesTonalitaetDefault", "{1} Tonalit\u00e4t Issues |{2} Rating Issues", "messages", "en");
t.add("ak.ebeneDefault", "{1} Ebene |{2} Level ", "messages", "en");
t.add("ak.field", "Variable", "messages", "en");
t.add("ak.fieldHover", "Variablenbezeichnungen laut MRA-Einrichtung in Auftragskonfiguration in Erfassung", "messages", "en");
t.add("ak.showField", "Anzeigen Detailsicht", "messages", "en");
t.add("ak.showFieldHover", "Codierung dieser Variable wird bei \"Clippings\" rechts im Bereich Analyse angezeigt", "messages", "en");
t.add("ak.listField", "Anzeigen Kachelansicht", "messages", "en");
t.add("ak.listFieldHover", "Ausgabe der Auspr\u00e4gung in der Listendarstellung und Detailsicht der Kachelansicht", "messages", "en");
t.add("ak.editClipField", "Codierung im ARGUS Portal", "messages", "en");
t.add("ak.editClipFieldHover", "Variable kann im ARGUS Portal codiert werden", "messages", "en");
t.add("ak.editCoreField", "Neue Auspr\u00e4gung im ARGUS Portal", "messages", "en");
t.add("ak.editCoreFieldHover", "Beim Codieren im ARGUS Portal k\u00f6nnen neue Auspr\u00e4gungen hinzugef\u00fcgt werden", "messages", "en");
t.add("ak.filterField", "Filter", "messages", "en");
t.add("ak.filterFieldHover", "Variable wird als Filterm\u00f6glichkeit angezeigt", "messages", "en");
t.add("ak.dbField", "Dashboard", "messages", "en");
t.add("ak.dbFieldHover", "Variable kann im Dashboard angezeigt werden", "messages", "en");
t.add("ak.selectBoxField", "Filter: SelectBox \/ Volltext \/ Autocomplete", "messages", "en");
t.add("ak.selectBoxFieldHover", "Art, wie die Auspr\u00e4gungen der Variable im Filter ausgew\u00e4hlt werden", "messages", "en");
t.add("ak.tonalitaetField", "Dashboard Bezug Bewertung: Treffer \/ Thema \/ Keine \/ Eigene", "messages", "en");
t.add("ak.tonalitaetFieldHover", "W\u00e4hlt aus, welche bzw. wie die Bewertung im ARGUS Portal dargestellt wird: Treffer: Durchschnittsbewertung aller Bewertungen im Beitrag | Thema: \"normale\" Bewertung der Themennennungen | Keine: nicht darstellbar | Eigene: Bei IF\/Issues wird die dort erhobene Bewertung angezeigt, nicht diejenige auf Themenebene\n", "messages", "en");
t.add("ak.fieldBezDe", "Bezeichnung deutsch", "messages", "en");
t.add("ak.fieldBezDeHover", "Bezeichnung deutsch", "messages", "en");
t.add("ak.fieldBezEn", "Bezeichnung englisch", "messages", "en");
t.add("ak.fieldBezEnHover", "Bezeichnung englisch", "messages", "en");
t.add("ak.breakTrefferBez", "Treffer", "messages", "en");
t.add("ak.breakThemaBez", "Thema", "messages", "en");
t.add("ak.breakPmBez", "Pressemitteilung", "messages", "en");
t.add("ak.enableEbene", "Anzeigen", "messages", "en");
t.add("ak.enableEbeneHover", "Ausblenden kompletter Themenebenen. Diese werden dann nicht im Themenbaum angezeigt und werden nirgends ausgewertet.", "messages", "en");
t.add("ak.extendedMraPm", "erweiterte Ansicht PM", "messages", "en");
t.add("ak.extendedMraPmHover", "Zeigt auch den Volltext der Pressemitteilung an", "messages", "en");
t.add("ak.mraPortal1Hover", "Einfachauswahl, 255 Zeichen, reine ARGUS Portal-Variable, nicht in MRA-Einrichtung ausw\u00e4hlbar!", "messages", "en");
t.add("ak.mraPortal2Hover", "Einfachauswahl, Zeichenanzahl unbegrenzt, reine ARGUS Portal-Variable, nicht in MRA-Einrichtung ausw\u00e4hlbar!", "messages", "en");
t.add("ak.mraPortal3Hover", "Mehrfachauswahl, 255 Zeichen, reine ARGUS Portal-Variable, nicht in MRA-Einrichtung ausw\u00e4hlbar!", "messages", "en");
t.add("ak.mraPortal4Hover", "Mehrfachauswahl, 255 Zeichen, reine ARGUS Portal-Variable, nicht in MRA-Einrichtung ausw\u00e4hlbar!", "messages", "en");
t.add("ak.mraPortal5Hover", "Mehrfachauswahl, 255 Zeichen, reine ARGUS Portal-Variable, nicht in MRA-Einrichtung ausw\u00e4hlbar!", "messages", "en");
t.add("ak.saveKonfig", "Als neue Konfiguration anlegen", "messages", "en");
t.add("ak.updateKonfig", "Ausgew\u00e4hlte Konfiguration aktualisieren", "messages", "en");
t.add("ak.updateKonfigAuftrag", "und diesem Auftrag hinzuf\u00fcgen", "messages", "en");
t.add("ak.resetKonfig", "\u00c4nderungen verwerfen", "messages", "en");
t.add("ak.aaew", "A\u00c4W Relevant", "messages", "en");
t.add("ak.aaewDefault", "A\u00c4W Relevant", "messages", "en");
t.add("ak.errChangeDefaultConfig", "Sie d\u00fcrfen die Vorlage \"Default\" nicht \u00e4ndern. Bitte legen Sie eine neue Konfiguration an.", "messages", "en");
t.add("e.embedWidget", "this link", "messages", "en");
t.add("e.maxlinkCountError", "You've reached the maximum amount of embedded widgets. Please delete unnecessary links or contact your account manager.", "messages", "en");
t.add("e.linkPlaceHolder", "Please click \"%button%\" to generate the link.", "messages", "en");
t.add("ed.bold", "Bold", "messages", "en");
t.add("ed.italic", "Italic", "messages", "en");
t.add("ed.underline", "Underline", "messages", "en");
t.add("ed.removeFormatting", "Remove Formating", "messages", "en");
t.add("ed.fontSize", "Font size", "messages", "en");
t.add("ed.fontColor", "Font color", "messages", "en");
t.add("ed.backgroundColor", "Background color", "messages", "en");
t.add("ed.justifyLeft", "Left justify", "messages", "en");
t.add("ed.justifyCenter", "Center justify", "messages", "en");
t.add("ed.justifyRight", "Right justify", "messages", "en");
t.add("ed.link", "Insert link", "messages", "en");
t.add("ed.image", "Insert image", "messages", "en");
t.add("ed.orderedList", "Ordered list", "messages", "en");
t.add("ed.unorderedList", "Unordered list", "messages", "en");
t.add("ed.heading", "Heading", "messages", "en");
t.add("ed.text", "Text", "messages", "en");
t.add("ed.url", "URL", "messages", "en");
t.add("ed.urlTitle", "Name", "messages", "en");
t.add("ed.invalidUrl", "Invalid URL", "messages", "en");
t.add("ed.invalidUrlTitle", "Invalid name", "messages", "en");
t.add("ed.insertImage", "Insert image", "messages", "en");
t.add("ed.galleryLoadingPlaceholder", "Loading image management ...", "messages", "en");
t.add("nv.color", "Color", "messages", "en");
t.add("nv.dashboardColor", "Dashboard color", "messages", "en");
t.add("nv.colorDescription0", "Background: dropdown boxes in media review configuration", "messages", "en");
t.add("nv.colorDescription1", "Background: filter, buttons, blackboard, accordion button", "messages", "en");
t.add("nv.colorDescription2", "Text: all elements with color 3 as background", "messages", "en");
t.add("nv.colorDescription3", "Background: selection, hover on buttons with color 1, hover in menus, active state in menu and dashboard tabs, Hervorgehobene Buttons, Neue Clippings, Textfarbe: Hervorgehobene Hinweistexte", "messages", "en");
t.add("nv.colorDescription4", "Text: input fields in filter, hover on dashboard tabs", "messages", "en");
t.add("nv.colorDescription5", "Background: input fields in filter, hover on dashboard tabs", "messages", "en");
t.add("nv.colorDescription6", "Text: default text color", "messages", "en");
t.add("nv.colorDescription7", "Background: blocks to edit data", "messages", "en");
t.add("nv.colorDescription8", "Text: filter (texts and symbols between input fields)", "messages", "en");
t.add("nv.colorDescription9", "Text: links", "messages", "en");
t.add("nv.colorDescription10", "Text: blackboard, buttons with color 1 as background color", "messages", "en");
t.add("nv.colorDescription11", "Background: categories in HTML media reviews", "messages", "en");
t.add("nv.colorDescription12", "Text: categories in HTML media reviews", "messages", "en");
t.add("nv.colorDescription13", "Text: links in HTML media reviews", "messages", "en");
t.add("nv.dbColorMain", "Main color", "messages", "en");
t.add("nv.dbColorMainDescription", "Color for example used as base color for country widget", "messages", "en");
t.add("nv.dbColorPlotLine", "Benchmark line", "messages", "en");
t.add("nv.dbColorPlotLineDescription", "line with a comparison value in line or column graphs", "messages", "en");
t.add("nv.dbColorPlotBand", "Benchmark band", "messages", "en");
t.add("nv.dbColorPlotBandDescription", "Background: band in line or column graphs", "messages", "en");
t.add("nv.screenshotTooltipClippings", "Clipping list", "messages", "en");
t.add("nv.screenshotTooltipClippingsEdit", "Edit clippings", "messages", "en");
t.add("nv.screenshotTooltipPspTiles", "Media review tiles", "messages", "en");
t.add("nv.screenshotTooltipDashboard", "Colors in dashboard", "messages", "en");
t.add("nv.screenshotTooltipHtmlPsp", "HTML media review", "messages", "en");
t.add("e.sendToEmails", "send to the following email addresses:", "messages", "en");
t.add("e.sendToEmailDistributionList", "send to the following email distribution lists:", "messages", "en");
t.add("e.displayEmailsInEmail", "Show email addresses in email", "messages", "en");
t.add("e.displayEmailDistributionListsInEmail", "Show email distribution lists in email", "messages", "en");
t.add("ceconomy.source", "Source", "messages", "en");
t.add("ceconomy.published", "Published", "messages", "en");
t.add("ceconomy.sentBy", "Sent by", "messages", "en");
t.add("ceconomy.sentTo", "Sent to", "messages", "en");
t.add("ceconomy.footerText", "If you have any questions regarding the Monitoring Service, please contact CECONOMY Communications", "messages", "en");
t.add("label.newsboard", "Newsboard", "messages", "en");
t.add("label.pressreviewviewer", "Media review archive", "messages", "en");
t.add("nv.mrView.customer.config.label", "Media Review Visibility", "messages", "en");
t.add("nv.mrView.customer.config.button.label", "Configure visibility", "messages", "en");
t.add("nv.mrView.customer.buttonChoice.config.label", "Configuration of editing mode", "messages", "en");
t.add("nv.mrView.customer.buttonChoice.config.description", "This configuration reflects how the visibility of media reviews within the list of media reviews could be edited. The quick choice makes it possible to quickly change between the state \"show\", \"hide\" and \"default\". The single choices enables the possibility to choose the visibility for each view.", "messages", "en");
t.add("nv.mrView.customer.buttonChoice.label.noButton", "not editable", "messages", "en");
t.add("nv.mrView.customer.buttonChoice.label.simpleChoiceButton", "quick choice", "messages", "en");
t.add("nv.mrView.customer.buttonChoice.label.complexChoiceButton", "single choices", "messages", "en");
t.add("nv.mrView.customer.viewRestriction.config.label", "Restrictions of views", "messages", "en");
t.add("nv.mrView.customer.viewRestriction.config.description", "This configuration reflects which view will be considered for managing the visibility of media reviews.", "messages", "en");
t.add("nv.mrView.customer.defaults.config.label", "Default values for the Media Review Visibility", "messages", "en");
t.add("nv.mrView.customer.defaults.config.description", "This configuration reflects in which view media reviews will be shown if there was yet no editing of the visibility within the media review list. This settings will be overwritten ba the users settings.", "messages", "en");
t.add("nv.mrView.user.defaults.config.description", "This configuration reflects in which view media reviews will be shown if there was yet no editing of the visibility within the media review list.", "messages", "en");
t.add("nv.mrView.visibility.customer", "use customer settings", "messages", "en");
t.add("nv.mrView.visibility.true", "show", "messages", "en");
t.add("nv.mrView.visibility.false", "hide", "messages", "en");
t.add("mrView.visibility.visible", "show", "messages", "en");
t.add("mrView.visibility.default", "default", "messages", "en");
t.add("mrView.visibility.notVisible", "hide", "messages", "en");
t.add("mrView.visibility.individual", "individual", "messages", "en");
t.add("mrView.visibility.tooltip.prefix", "Visibility: ", "messages", "en");
t.add("mrView.visibility.headline", "Set visibility", "messages", "en");
t.add("nv.mrArchiveConfig.customer.config.label", "Media review archive configuration", "messages", "en");
t.add("nv.mrDisabled.label", "Media review disabled (From-To)", "messages", "en");
t.add("nv.mrArchiveConfig.customer.config.shortLabel", "Media review archive config", "messages", "en");
t.add("nv.mrArchiveConfig.customer.config.button.label", "Configure media review archive", "messages", "en");
t.add("nv.mrArchiveConfig.customer.configure.mediareviewlist", "Configure media review list", "messages", "en");
t.add("nv.mrArchiveConfig.customer.configure.mediareviewview", "Configure media review view", "messages", "en");
t.add("nv.mrArchiveConfig.customer.configure.showLicencedDocuments", "Show licenced documents", "messages", "en");
t.add("nv.mrArchiveConfig.customer.configure.showLastDays.part1", "Show media reviews of last ", "messages", "en");
t.add("nv.mrArchiveConfig.customer.configure.showLastDays.part2", " days.", "messages", "en");
t.add("nv.mrArchiveConfig.error.errors", "The following errors occurred:", "messages", "en");
t.add("nv.mrArchiveConfig.error.blankName", "Name has to be filled.", "messages", "en");
t.add("nv.mrArchiveConfig.error.blankDaysToShow", "Amount of days has to be filled.", "messages", "en");
t.add("nv.mrArchiveConfig.error.notNumericDaysToShow", "Only digits for the amount of days are allowed.", "messages", "en");
t.add("nv.mrArchiveConfig.user.useCustomerSettings", "use customer settings", "messages", "en");
t.add("work.mraOrderConfigAdmin", "MRA Order Configuration", "messages", "en");
t.add("work.newsAdmin", "News Administration", "messages", "en");
t.add("profile.show.username", "Username", "messages", "en");
t.add("profile.show.email", "Email", "messages", "en");
t.add("change_password.submit", "Change password", "messages", "en");
t.add("security.login.username", "Username", "messages", "en");
t.add("security.login.password", "Password", "messages", "en");
t.add("security.login.remember_me", "Remember me", "messages", "en");
t.add("security.login.submit", "Log in", "messages", "en");
t.add("form.group_name", "Group name", "messages", "en");
t.add("form.username", "Username", "messages", "en");
t.add("form.email", "Email", "messages", "en");
t.add("form.current_password", "Current password", "messages", "en");
t.add("form.password", "Password", "messages", "en");
t.add("form.password_confirmation", "Repeat password", "messages", "en");
t.add("form.new_password", "New password", "messages", "en");
t.add("form.new_password_confirmation", "Repeat new password", "messages", "en");
t.add("n.includeHitsLabel", "Hits", "messages", "en");
t.add("n.includeAllWithSubClippings", "ALL media\/passive hit", "messages", "en");
t.add("n.includeOnlyMainClippings", "ONLY main issue\/first hit", "messages", "en");
t.add("map.HR", "Croatia", "messages", "en");
t.add("map.CV", "Cape Verde", "messages", "en");
t.add("map.DJ", "Djibouti", "messages", "en");
t.add("map.DK", "Denmark", "messages", "en");
t.add("map.DM", "Dominica", "messages", "en");
t.add("map.GY", "Guyana", "messages", "en");
t.add("map.SV", "El Salvador", "messages", "en");
t.add("map.ET", "Ethiopia", "messages", "en");
t.add("map.FR", "France", "messages", "en");
t.add("map.FI", "Finland", "messages", "en");
t.add("map.FJ", "Fiji", "messages", "en");
t.add("map.GA", "Gabon", "messages", "en");
t.add("map.IT", "Italy", "messages", "en");
t.add("map.ID", "Indonesia", "messages", "en");
t.add("map.IE", "Ireland", "messages", "en");
t.add("map.IM", "Isle of Man", "messages", "en");
t.add("map.IN", "India", "messages", "en");
t.add("map.IO", "British Indian Ocean Territory", "messages", "en");
t.add("map.VU", "Vanuatu", "messages", "en");
t.add("map.WS", "Samoa", "messages", "en");
t.add("map.CM", "Cameroon", "messages", "en");
t.add("map.CC", "Cocos Islands", "messages", "en");
t.add("map.CD", "Dem. Republic of the Congo", "messages", "en");
t.add("map.CH", "Switzerland", "messages", "en");
t.add("map.CI", "Ivory Coast", "messages", "en");
t.add("map.CK", "Cook Islands", "messages", "en");
t.add("map.CL", "Chile", "messages", "en");
t.add("map.CN", "China", "messages", "en");
t.add("map.CX", "Christmas Island", "messages", "en");
t.add("map.CY", "Cyprus", "messages", "en");
t.add("map.CZ", "Czech Republic", "messages", "en");
t.add("map.DE", "Germany", "messages", "en");
t.add("map.HK", "Hong Kong", "messages", "en");
t.add("map.HU", "Hungary", "messages", "en");
t.add("map.IR", "Iran", "messages", "en");
t.add("map.IQ", "Iraq", "messages", "en");
t.add("map.DO", "Dominican Republic", "messages", "en");
t.add("map.EC", "Ecuador", "messages", "en");
t.add("map.EE", "Estonia", "messages", "en");
t.add("map.ER", "Eritrea", "messages", "en");
t.add("map.EU", "Europe", "messages", "en");
t.add("map.GB", "United Kingdom", "messages", "en");
t.add("map.GD", "Grenada", "messages", "en");
t.add("map.KR", "South Korea", "messages", "en");
t.add("map.KG", "Kyrgyzstan", "messages", "en");
t.add("map.KW", "Kuwait", "messages", "en");
t.add("map.KY", "Cayman Islands", "messages", "en");
t.add("map.KZ", "Kazakhstan", "messages", "en");
t.add("map.LU", "Luxembourg", "messages", "en");
t.add("map.LA", "Laos", "messages", "en");
t.add("map.LI", "Liechtenstein", "messages", "en");
t.add("map.LK", "Sri Lanka", "messages", "en");
t.add("map.LR", "Liberia", "messages", "en");
t.add("map.LS", "Lesotho", "messages", "en");
t.add("map.LT", "Lithuania", "messages", "en");
t.add("map.LV", "Latvia", "messages", "en");
t.add("map.LY", "Libya", "messages", "en");
t.add("map.MA", "Morocco", "messages", "en");
t.add("map.MC", "Monaco", "messages", "en");
t.add("map.MD", "Moldova", "messages", "en");
t.add("map.MG", "Madagascar", "messages", "en");
t.add("map.MN", "Mongolia", "messages", "en");
t.add("map.GE", "Georgia", "messages", "en");
t.add("map.GH", "Ghana", "messages", "en");
t.add("map.GI", "Gibraltar", "messages", "en");
t.add("map.GL", "Greenland", "messages", "en");
t.add("map.GM", "The Gambia", "messages", "en");
t.add("map.GN", "Guinea", "messages", "en");
t.add("map.GQ", "Equatorial Guinea", "messages", "en");
t.add("map.GT", "Guatemala", "messages", "en");
t.add("map.GU", "Guam", "messages", "en");
t.add("map.GW", "Guinea-Bissau", "messages", "en");
t.add("map.OM", "Oman", "messages", "en");
t.add("map.PA", "Panama", "messages", "en");
t.add("map.PE", "Peru", "messages", "en");
t.add("map.NE", "Niger", "messages", "en");
t.add("map.IS", "Iceland", "messages", "en");
t.add("map.IL", "Israel", "messages", "en");
t.add("map.JM", "Jamaica", "messages", "en");
t.add("map.RU", "Russia", "messages", "en");
t.add("map.RW", "Rwanda", "messages", "en");
t.add("map.SA", "Saudi Arabia", "messages", "en");
t.add("map.SC", "Seychelles", "messages", "en");
t.add("map.SD", "Sudan", "messages", "en");
t.add("map.SO", "Somalia", "messages", "en");
t.add("map.SB", "Solomon Islands", "messages", "en");
t.add("map.SR", "Suriname", "messages", "en");
t.add("map.RS", "Serbia", "messages", "en");
t.add("map.SS", "South Sudan", "messages", "en");
t.add("map.TN", "Tunisia", "messages", "en");
t.add("map.TO", "Tonga", "messages", "en");
t.add("map.TR", "Turkey", "messages", "en");
t.add("map.US", "United States", "messages", "en");
t.add("map.UY", "Uruguay", "messages", "en");
t.add("map.UZ", "Uzbekistan", "messages", "en");
t.add("map.VA", "State of the Vatican City", "messages", "en");
t.add("map.VE", "Venezuela", "messages", "en");
t.add("map.VG", "British Virgin Islands", "messages", "en");
t.add("map.VI", "U.S. Virgin Islands", "messages", "en");
t.add("map.VN", "Vietnam", "messages", "en");
t.add("map.VC", "Saint Vincent and the Grenadines", "messages", "en");
t.add("map.GF", "French Guiana", "messages", "en");
t.add("map.JE", "Jersey", "messages", "en");
t.add("map.JO", "Jordan", "messages", "en");
t.add("map.KP", "North Korea", "messages", "en");
t.add("map.CO", "Colombia", "messages", "en");
t.add("map.KM", "Comoros", "messages", "en");
t.add("map.CR", "Costa Rica", "messages", "en");
t.add("map.DZ", "Algeria", "messages", "en");
t.add("map.ES", "Spain", "messages", "en");
t.add("map.UG", "Uganda", "messages", "en");
t.add("map.FO", "Faroe Islands", "messages", "en");
t.add("map.FM", "Micronesia", "messages", "en");
t.add("map.MH", "Marshall Islands", "messages", "en");
t.add("map.MK", "Macedonia", "messages", "en");
t.add("map.ML", "Mali", "messages", "en");
t.add("map.ME", "Montenegro", "messages", "en");
t.add("map.MO", "Macau", "messages", "en");
t.add("map.MZ", "Mozambique", "messages", "en");
t.add("map.MP", "Northern Mariana Islands", "messages", "en");
t.add("map.MQ", "Martinique", "messages", "en");
t.add("map.MU", "Mauritius", "messages", "en");
t.add("map.MT", "Malta", "messages", "en");
t.add("map.MV", "Maldives", "messages", "en");
t.add("map.MW", "Malawi", "messages", "en");
t.add("map.MX", "Mexico", "messages", "en");
t.add("map.MY", "Malaysia", "messages", "en");
t.add("map.MM", "Burma", "messages", "en");
t.add("map.GR", "Greece", "messages", "en");
t.add("map.HN", "Honduras", "messages", "en");
t.add("map.PH", "Philippines", "messages", "en");
t.add("map.PL", "Poland", "messages", "en");
t.add("map.PG", "Papua New Guinea", "messages", "en");
t.add("map.JP", "Japan", "messages", "en");
t.add("map.KH", "Cambodia", "messages", "en");
t.add("map.KE", "Kenya", "messages", "en");
t.add("map.KN", "Saint Kitts And Nevis", "messages", "en");
t.add("map.NO", "Norway", "messages", "en");
t.add("map.NA", "Namibia", "messages", "en");
t.add("map.NC", "New Caledonia", "messages", "en");
t.add("map.NP", "Nepal", "messages", "en");
t.add("map.NG", "Nigeria", "messages", "en");
t.add("map.NI", "Nicaragua", "messages", "en");
t.add("map.NL", "Netherlands", "messages", "en");
t.add("map.NU", "Niue", "messages", "en");
t.add("map.NZ", "New Zealand", "messages", "en");
t.add("map.PR", "Puerto Rico", "messages", "en");
t.add("map.PS", "Palestine", "messages", "en");
t.add("map.PT", "Portugal", "messages", "en");
t.add("map.PY", "Paraguay", "messages", "en");
t.add("map.PF", "French Polynesia", "messages", "en");
t.add("map.QA", "Qatar", "messages", "en");
t.add("map.CF", "Central African Republic", "messages", "en");
t.add("map.CG", "Republic of the Congo", "messages", "en");
t.add("map.HT", "Haiti", "messages", "en");
t.add("map.MR", "Mauritania", "messages", "en");
t.add("map.XK", "Kosovo", "messages", "en");
t.add("map.SE", "Sweden", "messages", "en");
t.add("map.SG", "Singapore", "messages", "en");
t.add("map.SK", "Slovakia", "messages", "en");
t.add("map.SL", "Sierra Leone", "messages", "en");
t.add("map.SI", "Slovenia", "messages", "en");
t.add("map.SM", "San Marino", "messages", "en");
t.add("map.SN", "Senegal", "messages", "en");
t.add("map.LC", "Saint Lucia", "messages", "en");
t.add("map.TT", "Trinidad and Tobago", "messages", "en");
t.add("map.TV", "Tuvalu", "messages", "en");
t.add("map.TZ", "Tanzania", "messages", "en");
t.add("map.UA", "Ukraine", "messages", "en");
t.add("map.AE", "United Arab Emirates", "messages", "en");
t.add("map.PK", "Pakistan", "messages", "en");
t.add("map.ST", "S\u00e3o Tom\u00e9 and Pr\u00edncipe", "messages", "en");
t.add("map.SY", "Syria", "messages", "en");
t.add("map.SZ", "Swaziland", "messages", "en");
t.add("map.TW", "Taiwan", "messages", "en");
t.add("map.TC", "Turks And Caicos Islands", "messages", "en");
t.add("map.TD", "Chad", "messages", "en");
t.add("map.YE", "Yemen", "messages", "en");
t.add("map.YU", "Yugoslavia", "messages", "en");
t.add("map.ZM", "Zambia", "messages", "en");
t.add("map.ZA", "South Africa", "messages", "en");
t.add("map.ZW", "Zimbabwe", "messages", "en");
t.add("map.TG", "Togo", "messages", "en");
t.add("map.TH", "Thailand", "messages", "en");
t.add("map.TJ", "Tajikistan", "messages", "en");
t.add("map.TL", "East Timor", "messages", "en");
t.add("map.TM", "Turkmenistan", "messages", "en");
t.add("map.CA", "Canada", "messages", "en");
t.add("map.AT", "Austria", "messages", "en");
t.add("map.EG", "Egypt", "messages", "en");
t.add("map.AF", "Afghanistan", "messages", "en");
t.add("map.AL", "Albania", "messages", "en");
t.add("map.AG", "Antigua and Barbuda", "messages", "en");
t.add("map.AI", "Anguilla", "messages", "en");
t.add("map.AM", "Armenia", "messages", "en");
t.add("map.AN", "Netherlands Antilles", "messages", "en");
t.add("map.AD", "Andorra", "messages", "en");
t.add("map.AO", "Angola", "messages", "en");
t.add("map.AR", "Argentina", "messages", "en");
t.add("map.BG", "Bulgaria", "messages", "en");
t.add("map.AS", "American Samoa", "messages", "en");
t.add("map.AU", "Australia", "messages", "en");
t.add("map.AW", "Aruba", "messages", "en");
t.add("map.AZ", "Azerbaijan", "messages", "en");
t.add("map.BE", "Belgium", "messages", "en");
t.add("map.BD", "Bangladesh", "messages", "en");
t.add("map.BB", "Barbados", "messages", "en");
t.add("map.BF", "Burkina Faso", "messages", "en");
t.add("map.BT", "Bhutan", "messages", "en");
t.add("map.BA", "Bosnia and Herzegovina", "messages", "en");
t.add("map.BJ", "Benin", "messages", "en");
t.add("map.BM", "Bermuda", "messages", "en");
t.add("map.BO", "Bolivia", "messages", "en");
t.add("map.BR", "Brazil", "messages", "en");
t.add("map.BH", "Bahrain", "messages", "en");
t.add("map.BN", "Brunei", "messages", "en");
t.add("map.BS", "The Bahamas", "messages", "en");
t.add("map.BI", "Burundi", "messages", "en");
t.add("map.BW", "Botswana", "messages", "en");
t.add("map.BY", "Belarus", "messages", "en");
t.add("map.BZ", "Belize", "messages", "en");
t.add("map.CU", "Cuba", "messages", "en");
t.add("map.LB", "Lebanon", "messages", "en");
t.add("map.RO", "Romania", "messages", "en");
})(Translator);
